import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik, FormikProvider } from 'formik';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import './index.css';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import SpinnerLoader from '../SpinnerLoader';
import client from 'api/apiAuth/guestClient';

import {
  Grid,
  Button,
  DialogContent,
  Dialog,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import {
  enableSubmitButton,
  saveDraft,
  startEngine,
  clearSubmission,
  updateSubmission,
  setSubmission,
  saveValues,
  setBenificiaryId
} from '../../reducers/form/formActions';
import * as Yup from 'yup';
import _ from 'lodash';
import { sanatizeObject } from '../../utils/SanatizaObject';
import { isOnlineInFormStep, setSections, ConstructFormErrors } from './utils';
// import FurnitureConditionInfo from '../FormComponents/FurnitureConditionInfo';
import { handleError } from '../../api/utilities';
import { ClipLoader } from 'react-spinners';
import IndexedDB from 'utils/indexedDB';
import { GetStepContent } from './getStepContent';
import { RenderStepper } from 'components/Stepper';
// import { RenderStepper } from 'components/Stepper';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },

  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  step: {
    '&:hover': {
      cursor: 'pointer !important',
      color: 'yellow !important',
      '& .MuiStepLabel-iconContainer div': {
        backgroundColor: '#196938 !important'
      }
    }
  },
  stepper: {
    '& .MuiStep-root .MuiStepLabel-root': {
      '&:hover': {
        cursor: 'pointer !important'
      }
    }
  }
}));

// Change steper icon to be rendered in arabic form "Component"
function StepIcon(props) {
  const { active, completed } = props;
  return (
    <div
      className={clsx(
        'd-40 transition-base d-flex align-items-center bg-gray-400 justify-content-center rounded-circle',
        {
          'd-40 bg-primary text-white shadow-primary-sm': active || completed
        }
      )}>
      {String(props.icon.toLocaleString('ar-EG'))}
    </div>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};
// Check input key

export const setProtoTypeInitialValues = function (
  obj,
  initialValues,
  sections
) {
  sections.forEach((section, i) => {
    const isSubSections = [section.group, section.step].some((e) =>
      Array.isArray(e)
    );
    Object.entries(initialValues).forEach(([key, value]) => {
      if (typeof value === 'object') {
        if (section.key === key) {
          if (Array.isArray(value)) {
            obj[section.name] = Array.from(
              { length: value.length },
              () => ({})
            );
            if (isSubSections) {
              obj[section.name].forEach((ele, i) => {
                setProtoTypeInitialValues(obj[section.name][i], value[i], [
                  ...(section.group || []),
                  ...(section.step || [])
                ]);
              });
            }
          } else {
            obj[section.name] = {};
            setProtoTypeInitialValues(obj[section.name], value, [
              ...(section.group || []),
              ...(section.step || [])
            ]);
          }
        } else if (section.key === 'self') {
          obj[section.name] = {};
          setProtoTypeInitialValues(obj[section.name], initialValues, [
            ...(section.group || []),
            ...(section.step || [])
          ]);
        } else {
          obj[section.name] = section.isMany ? [{}] : {};
          setProtoTypeInitialValues(obj[section.name], initialValues, [
            ...(section.group || []),
            ...(section.step || [])
          ]);
        }
      }
    });
  });
};

const FormSteps = React.forwardRef((props, ref) => {
  let {
    form,
    formId,
    validationSchema,
    submission,
    enableSubmitButton,
    submit,
    saveDraft,
    draftId,
    draftValues,
    history,
    initialValues,
    setInitialValues,
    submissionId,
    updateSubmission,
    actionType,
    setBenificiaryId,
    isUploadingFile,
    connectionStatus,
    saveVal,
    setSaveFlag,
    saveFlag,
    submissionValues,
    setSubmissionValues,
    currentUser,
    clearSubmission,
    currentForm,
    requestsNo,
    savingDraftLoading
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [subActiveStep, setSubActiveStep] = useState(0);
  const [sendSubmission, setSendSubmission] = useState(false);
  const [savedDraftData, setSavedDraftData] = useState({});
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  // const matches = useMediaQuery('(max-width:600px)');
  const [childStep, setChildStep] = useState(false);
  const [sectionNameFlags, setSectionNameFlags] = useState({});
  const [numbering, setNumbering] = useState({
    sectionIndex: 1,
    childSectionIndex: 1
  });
  const [removing, setRemoving] = useState(false);
  const [yupSchema, setYupSchema] = useState(
    Yup.object().shape(validationSchema)
  );
  const [elementsProtoType, setElementsProtoType] = useState({});
  const [manyEntities, setManyEntities] = useState({});
  const _isMounted = useRef(true);
  let formik = useFormik({
    validationSchema: yupSchema,
    validateOnChange: false,
    validationOnBlur: true,
    enableReinitialize: true,
    initialValues
  });
  useEffect(() => {
    setYupSchema(Yup.object().shape(validationSchema));
  }, [validationSchema]);
  const [openDialog, setOpenDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState();

  const setObjInArr = (obj, sections, randomId, initialObjValue) => {
    sections.forEach((section) => {
      const isSubSections = [section.group, section.child_sections].some((e) =>
        Array.isArray(e)
      );
      if (section.isMany) {
        const addedObj = {};
        if (section.key === 'submissions') addedObj.randomId = randomId;
        obj[section.name] = [addedObj];
        if (initialObjValue) {
          initialObjValue[section.key] = [addedObj];
        }
        if (isSubSections)
          obj[section.name].forEach((ele) =>
            setObjInArr(ele, [
              ...(section.group || []),
              ...(section.child_sections || [])
            ])
          );
      } else {
        const addedObj = {};
        if (section.key === 'submissions') addedObj.randomId = randomId;
        obj[section.name] = addedObj;
        isSubSections &&
          setObjInArr(
            obj[section.name],
            [...(section.group || []), ...(section.child_sections || [])],
            randomId
          );
      }
    });
  };
  let protoTypeObj = {};
  const setProtoType = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (key !== 'randomId') {
        protoTypeObj[key] = Array.isArray(obj[key]) ? obj[key][0] : obj[key];
        setProtoType(protoTypeObj[key]);
      }
    });
    setElementsProtoType(protoTypeObj);
  };
  const handleClose = () => {
    setOpenDialog(false);
    if (sendSubmission) {
      Swal.fire({
        title: 'هل انت متأكد؟',
        text: 'سوف يتم أرسال الطلب',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'نعم',
        cancelButtonText: 'لا',
        dangerMode: true
      }).then(function (dismissObject) {
        if (!dismissObject.isDismissed) {
          let { attachments, ...values } = formik.values;
          let SubmissionData;
          if (submission.attachments) {
            attachments = attachments
              ? [...submission.attachments, ...attachments]
              : [...submission.attachments];

            SubmissionData = sanatizeObject({ ...values, attachments });
          } else {
            SubmissionData = sanatizeObject({ ...values });
          }
          if (actionType == 'edit') {
            updateSubmission({
              ...SubmissionData,
              form_id: parseInt(formId),
              id: submissionId
            }).then((res) => {
              history.push('/worklist');
              setBenificiaryId(null);
            });
          } else {
            setLoading(true);
            saveVal({});
            if (!_.isEmpty(savedDraftData)) {
              SubmissionData.id = savedDraftData.id;
              SubmissionData.file_code = savedDraftData.file_code;
            }
            submit({
              ...SubmissionData,
              form_id: parseInt(formId)
            })
              .then((res) => {
                submitInOtherProgram(formik, history);
              })
              .catch((error) => {
                setLoading(false);
                handleError(error);
              });
          }

          setActiveStep(0);
          setSubActiveStep(0);
        }
      });
    }
  };
  useEffect(() => {
    if (steps.length && !_.isEmpty(initialValues)) {
      const newSteps = _.cloneDeep(steps);
      for (const step of newSteps) {
        const currentStepName = step?.key;
        if (initialValues?.hasOwnProperty(currentStepName)) {
          step.number = initialValues[currentStepName].length;
        }
      }
      setSteps(newSteps);
    }
  }, [initialValues]);
  useEffect(() => {
    if (alertMessage) {
      setOpenDialog(true);
    }
  }, [alertMessage]);

  //
  useEffect(() => {
    if (saveFlag) {
      saveVal(formik.values);
      setSaveFlag(false);
      history.push('/programs');
    }
  }, [saveFlag]);

  useEffect(() => {
    if (draftValues && draftId) setInitialValues(draftValues);
  }, [draftValues]);

  useEffect(() => {
    if (submissionValues) setInitialValues(submissionValues);
  }, [submissionValues]);
  const classes = useStyles();

  useEffect(() => {
    if (form) {
      setSections(form, _isMounted, setSteps);
    }

    let sections = _.cloneDeep(form),
      obj = {},
      initialObjValue = {};
    if (_.isEmpty(initialValues) || submissionId) {
      setObjInArr(obj, sections, null, initialObjValue);
      if (!submissionId) {
        Object.keys(initialObjValue).forEach((key) => {
          formik.setFieldValue(key, initialObjValue[key]);
        });
      }
      setProtoType(obj);
    } else {
      setProtoTypeInitialValues(obj, initialValues, form);
      setProtoType(obj);
    }
    sections.forEach((section) => {
      if (!obj[section.name] && section.isMany) {
        obj[section.name] = [{}];
      }
    });

    if (initialValues) {
      const mapper = {
        relatives: 'التكوين الاسرى',
        health_program_properties: 'خصائص البرنامج',
        debtData: 'خصائص البرنامج',
        projectProperties: 'خصائص المشروع',
        disability_program_properties: 'خصائص البرنامج',
        education_program_properties: 'خصائص البرنامج'
      };
      if (!draftId) {
        Object.keys(initialValues).forEach((key) => {
          if (Array.isArray(initialValues[key])) {
            if (initialValues[key].length >= 2) {
              for (let i = 1; i < initialValues[key].length; i++) {
                if (key in mapper) {
                  obj[mapper[key]].push(obj[mapper[key]][i - 1]);
                }
              }
            }
          }
        });
      }
    }
    setManyEntities(obj);
    return () => {
      _isMounted.current = false;
    };
  }, [form, initialValues]);

  const saveDraftsOnStepChange = () => {
    if (!_.isEmpty(formik.values)) {
      //delete null and undefined values and save attachments if there's any
      const draftValues = sanatizeObject(formik.values);
      let { attachments, ...values } = draftValues;
      if (submission?.attachments) {
        attachments = attachments
          ? [...submission.attachments, ...attachments]
          : [...submission.attachments];
      }
      let user_id = currentUser.id;
      let SubmissionData = { ...values, attachments, user_id };
      //check if there's a previous draft before this transition , if there is then update it else create a new one and save it's id and file_code in a state
      if (!_.isEmpty(savedDraftData)) {
        SubmissionData.id = savedDraftData.id;
        SubmissionData.file_code = savedDraftData.file_code;
        saveDraft({
          ...SubmissionData,
          form_id: parseInt(formId)
        });
      } else {
        const newDraft = saveDraft({
          ...SubmissionData,
          form_id: parseInt(formId)
        });
        newDraft.then((draft) =>
          setSavedDraftData(() => {
            return {
              id: draft.value.data.submission.id,
              file_code: draft.value.data.submission.file_code
            };
          })
        );
      }
    }
  };
  const handleNext = async (e) => {
    window.scrollTo(0, 0);
    e.stopPropagation();
    if (activeStep >= steps.length - 1) {
      if (formik.values.no_family) {
        let tempValues = _.cloneDeep(formik.values);
        delete tempValues.relatives;
        formik.values = _.cloneDeep(tempValues);
        if (!_.isEmpty(submission.attachments)) {
          submission = {
            attachments: submission.attachments.filter(
              (attachment) =>
                attachment.sectionName != 'relatives' &&
                attachment.name.split('.')[0] != 'relatives'
            )
          };
        }
      }
      let errorsObject = false;
      let res;
      try {
        res = await formik.validateForm();
        yupSchema.validateSync(formik.values, {
          context: formik.values,
          abortEarly: false
        });
      } catch (err) {
        errorsObject = err;
        console.log(errorsObject);
      }

      if (errorsObject) {
        enableSubmitButton();
        Swal.fire({
          title: 'لقد تم ادخال بيانات غير صحيحة',
          html: `
          <h5>برجاء التأكد من البيانات الاتية</h5>
          <br></br>
          ${ConstructFormErrors(errorsObject.errors).join('<br><br>')}`,
          icon: 'error',
          confirmButtonColor: '#d36467',
          confirmButtonText: 'Try Again',
          dangerMode: true
        });
      } else if (!_.isEmpty(res)) {
        enableSubmitButton();
        Swal.fire({
          title: 'لقد تم ادخال بيانات غير صحيحة',
          text: 'يرجى مراجعة بيانتك و اعادة الارسال',
          icon: 'error',
          timer: 3000,
          dangerMode: true
        });
      } else {
        handleSubmit(actionType);
      }
      return;
    }
    //save values as a draft before rendering next step
    actionType == 'submit' && saveDraftsOnStepChange();

    if (steps[activeStep].child_sections.length > 0) {
      if (subActiveStep === steps[activeStep].child_sections.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setChildStep(false);
      } else {
        setChildStep(true);
        setSubActiveStep((prevSubActiveStep) => prevSubActiveStep + 1);
      }
    } else {
      if (
        steps[activeStep + 1].inputs.length == 0 &&
        steps[activeStep + 1].child_sections.length > 0
      ) {
        setChildStep(true);
      } else {
        setChildStep(false);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    window.scrollTo(0, 0);

    if (steps[activeStep]?.child_sections.length > 0) {
      if (subActiveStep === 0) {
        handelActiveStep(activeStep - 1, 0, false);
      } else {
        setChildStep(true);
        setSubActiveStep((prevSubActiveStep) => prevSubActiveStep - 1);
      }
    } else {
      if (steps[activeStep - 1].child_sections.length > 0) {
        handelActiveStep(
          activeStep - 1,
          steps[activeStep - 1].child_sections.length - 1,
          true
        );
      } else {
        handelActiveStep(activeStep - 1, 0, false);
      }
    }
  };
  const submitInOtherProgram = (formik, history) => {
    let national_id = formik.values['primaryFields'].national_id;
    setLoading(false);
    if(initialValues?.is_website == false) {
      if (actionType != 'edit') {
        Swal.fire({
          text: 'تم إرسال الطلب بنجاح ، هل تريد تسجيل الطلب فى برنامج اخر',
          icon: 'question',
          showDenyButton: true,
          confirmButtonText: `نعم`,
          denyButtonText: `لا`,
          confirmButtonColor: '#253053',
          denyButtonColor: '#25305338'
        }).then(function (result) {
          if (result.isConfirmed) {
            history.push('/programs');
            setBenificiaryId(national_id);
          } else {
            history.push('/worklist');
            setBenificiaryId(null);
          }
        });
      }
    } else {
        history.push('/worklist');
        setBenificiaryId(null);
    }
  };
  const removeEmptyKeys = (SubmissionData) => {
    if(SubmissionData.housing_program_properties){
      Object.entries(SubmissionData.housing_program_properties).forEach(([key,value])=>{
        if(Array.isArray(value) && value.length == 0) delete SubmissionData.housing_program_properties[key];
      })
    }
    return SubmissionData;
  }
  //TODO:Refactor
  const handleSubmit = async (actionType) => {
    if (requestsNo > 0) {
      Swal.fire({
        title: 'Please wait ',
        text: `برجاء  انتظار تحميل الملفات`,
        icon: 'info',
        confirmButtonColor: '#d36467',
        dangerMode: true
      });
      return;
    }
    if (!isOnlineInFormStep(connectionStatus)) {
      Swal.fire({
        title: 'الجهاز غير متصل بالشبكة',
        text:
          'سوف يتم حفظ الطلب فى المخزن. برجاء رفع الملف إلى قاعدة البيانات فى صفحة "مخزن الطلبات" عند عودة الشبكة.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'نعم',
        cancelButtonText: 'لا',
        dangerMode: true
      }).then(function (dismissObject) {
        if (dismissObject.isDismissed) return;
        const asyncSubmit = async () => {
          const attachments = await IndexedDB.getAllAttachemnts();
          await IndexedDB.addSubmission({
            ...sanatizeObject({
              is_offline: 1,
              ...formik.values,
              form_id: parseInt(formId)
            }),
            attachments
          });
          history.push('/worklist');
        };
        asyncSubmit();
      });
      return;
    }

    if (
      alertMessage?.submissionData ||
      (alertMessage == undefined && actionType == 'submit')
    ) {
      setSendSubmission(true);
    } else {
      Swal.fire({
        title: 'هل انت متأكد؟',
        text: 'سوف يتم أرسال الطلب',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'نعم',
        cancelButtonText: 'لا',
        dangerMode: true
      }).then(function (dismissObject) {
        if (!dismissObject.isDismissed) {
          let { attachments, ...values } = formik.values;
          let SubmissionData;
          if (submission.attachments) {
            attachments = attachments
              ? [...submission.attachments, ...attachments]
              : [...submission.attachments];

            SubmissionData = sanatizeObject({ ...values, attachments });
          } else {
            SubmissionData = sanatizeObject({ ...values });
          }
          if (actionType == 'edit') {
            setLoading(true);
            SubmissionData = removeEmptyKeys(SubmissionData);
            updateSubmission({
              ...SubmissionData,
              form_id: parseInt(formId),
              id: submissionId
            }).then((res) => {
              setLoading(false);
              history.push('/worklist');
              setBenificiaryId(null);
            });
          } else {
            setLoading(true);
            saveVal({});
            if (!_.isEmpty(savedDraftData)) {
              SubmissionData.id = savedDraftData.id;
              SubmissionData.file_code = savedDraftData.file_code;
            }
            submit({
              ...SubmissionData,
              form_id: parseInt(formId)
            })
              .then((res) => {
                submitInOtherProgram(formik, history);
              })
              .catch((error) => {
                setLoading(false);
                handleError(error);
              });
          }

          setActiveStep(0);
          setSubActiveStep(0);
        }
      });
    }
    if (!['reinvestigate', 'edit'].includes(actionType)) {
      let national_id =
        formik.values['primaryFields'].national_id != undefined
          ? formik.values['primaryFields'].national_id
          : formik.values['primaryFields'].passport_id;
      let stepId = 3;
      let primaryFieldsSectionId = currentForm.sections.find(
        (x) => x.key == 'primaryFields'
      ).id;
      let result = await client.get('/submission/getOldSubmission', {
        params: {
          national_id,
          stepId,
          formName: currentForm.name,
          sectionId: primaryFieldsSectionId
        }
      });
      if (result.data.message) {
        setAlertMessage({
          message: result.data.message,
          submissionData: result.data.allSubmissions
        });
      }
    }
  };

  const validateAndSaveDraft = async () => {
    try {
      const { isConfirmed } = await Swal.fire({
        title: 'هل انت متأكد؟',
        text: 'سوف يتم حفظ الملف لحين الرجوع اليه مجددا',
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: `بالطبع`,
        denyButtonText: `لا`,
        confirmButtonColor: '#253053',
        denyButtonColor: '#25305338'
      });
      if (isConfirmed) {
        saveDraftsOnStepChange();
        //reset submission in the store after saving the draft
        clearSubmission();
        await Swal.fire({
          text: 'حسنا',
          title: `تم حفظ الطلب بنجاح`,
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false
        });
        history.push('/worklist');
        // }
      }
    } catch (err) {
      console.log(err);
      Swal.fire('Submit Form Error', 'فشل حفظ البيانات. تأكد من صحة البيانات');
    }
  };
  const handelActiveStep = (stepValue, subStepValue, type) => {
    if (
      steps[stepValue].inputs.length == 0 &&
      steps[stepValue].child_sections.length > 0
    ) {
      setChildStep(true);
    } else {
      setChildStep(type);
    }
    setActiveStep(stepValue);
    setSubActiveStep(subStepValue);
    setNumbering({
      sectionIndex: stepValue + 1
    });
  };
  const evaluateNextButton = () => {
    if (!isUploadingFile && !savingDraftLoading) {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={`${classes.button} w-100`}
          inputlayout={{ float: 'left' }}>
          {activeStep === steps.length - 1 &&
          steps[activeStep].child_sections.length == 0
            ? 'انهاء'
            : 'التالى و حفظ في مسودة'}
          <ArrowBackIosIcon />
        </Button>
      );
    } else {
      return (
        <Button
          disabled
          variant="contained"
          color="primary"
          className={classes.button}
          inputlayout={{ float: 'left' }}>
          <ClipLoader />
        </Button>
      );
    }
  };
  return (
    <>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <Grid container className="root bg-color-gray">
          <button
            ref={ref}
            className="d-none"
            onClick={validateAndSaveDraft}></button>
          <FormikProvider value={formik}>
            <Grid item sm={2} md={2} className="stepper">
              <RenderStepper
                steps={steps}
                activeStep={activeStep}
                subActiveStep={subActiveStep}
                handelActiveStep={handelActiveStep}></RenderStepper>
            </Grid>
            {steps.length > 0 && (
              <Grid
                item
                sm={10}
                md={10}
                container
                className="form-style pt-0 pr-4 pl-4">
                <Grid item xs={12}>
                  {GetStepContent({
                    formik,
                    activeStep,
                    steps,
                    subActiveStep,
                    setInitialValues,
                    childStep,
                    numbering,
                    manyEntities,
                    setManyEntities,
                    elementsProtoType,
                    draftId,
                    submissionId,
                    sectionNameFlags,
                    setSectionNameFlags,
                    actionType,
                    extraData: { connectionStatus },
                    setAlertMessage,
                    submission,
                    setSubmissionValues,
                    isUploadingFile,
                    removing,
                    setRemoving
                  })}
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.actionsContainer}>
                    <Grid container className="m-2" spacing={1}>
                      {activeStep !== 0 && (
                        <Grid item xs={2} md={2} lg={2}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleBack(steps)}
                            className={`${classes.button} float-right`}>
                            <div className="mr-1">
                              <b>السابق</b>
                            </div>
                          </Button>
                          {/* <Grid item xs={6} style={{ textAlign: 'left' }}>
                        {evaluateNextButton()}
                      </Grid> */}
                        </Grid>
                      )}
                      <Grid item xs={2} md={2} lg={2} className="text-left">
                        {evaluateNextButton()}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}
          </FormikProvider>

          {alertMessage?.submissionData && (
            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <h1 style={{ textAlign: 'center' }} className="text-danger">
                    تحذير
                  </h1>
                  <h5 style={{ textAlign: 'center' }}>
                    {alertMessage?.message}
                  </h5>
                  <ol style={{ padding: 20 }}>
                    {alertMessage?.submissionData?.map((submission) => {
                      return (
                        <li style={{ marginBottom: 8 }} key={submission.id}>
                          <a
                            href={`${window.location.origin}/details/${submission.id}`}>
                            {` كود الطلب : ${submission.file_code} / ${submission.type}`}
                          </a>
                        </li>
                      );
                    })}
                  </ol>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  حسنا
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Grid>
      )}
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    submission: state.form.submission,
    draftValues: state.form.draftValues,
    enableSubmit: state.form.enableSubmit,
    isUploadingFile: state.form.isUploadingFile,
    connectionStatus: state.status.connectionStatus,
    savedValues: state.form.savedValues,
    currentUser: state.auth.user,
    currentForm: state.form.form,
    requestsNo: state.general.requestsNo,
    savingDraftLoading: state.form.savingDraftLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    submit: (submission) => dispatch(startEngine(submission)),
    saveDraft: (submission) => dispatch(saveDraft(submission)),
    saveVal: (values) => dispatch(saveValues(values)),
    enableSubmitButton: () => dispatch(enableSubmitButton()),
    updateSubmission: (submission) => dispatch(updateSubmission(submission)),
    setSubmissionValues: (submissionDetails) =>
      dispatch(setSubmission(submissionDetails)),
    setBenificiaryId: (national_id) => dispatch(setBenificiaryId(national_id)),
    clearSubmission: () => {
      dispatch(clearSubmission());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(FormSteps);
