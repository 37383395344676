import _ from 'lodash';

export function disableInputs(
  ev,
  mainInput,
  option,
  step,
  subActiveStepIndex,
  formik,
  SetInputKey,
  setSubmission,
  submission
) {
  const isChildSection = step.child_sections.length > 0;
  const [changedInput, changedInputIndex] = findChangedInput(
    step,
    mainInput.id,
    subActiveStepIndex,
    isChildSection
  );
  if (!changedInput) return [null, null];
  if (
    !changedInput.validatedInputs.length &&
    !changedInput.validatedDocuments.length
  )
    return [null, null];
  clearValues(
    changedInput,
    formik,
    SetInputKey,
    isChildSection,
    setSubmission,
    option,
    step,
    submission,
    ev
  );
  /*return evaluateDisabled(
    changedInput,
    ev,
    step,
    subActiveStepIndex,
    isChildSection,
    changedInputIndex
  );*/
}
export function findBySectionId(id, steps) {
  for (const step of steps) {
    if (step.id === id) return step;
    if (step.group) {
      const result = findBySectionId(id, step.group);
      if (result) return result;
    }
  }
  return null;
}

function findChangedInput(step, inputId, subActiveStepIndex, isChildSection) {
  if (isChildSection) {
    for (
      let i = 0;
      i < step.child_sections[subActiveStepIndex].inputs.length;
      i++
    ) {
      const input = step.child_sections[subActiveStepIndex].inputs[i];
      if (input.id === inputId) return [input, i];
    }
  } else {
    for (let i = 0; i < step.inputs.length; i++) {
      const input = step.inputs[i];
      if (input.id === inputId) return [input, i];
    }
  }
  return [null, null];
}

function clearPaymentPeriod(formik, input, ev) {
  // if (input.key === 'exchange_repeat') {
  // const serviceIndex = parseInt(ev.target.name.match(/(\d+)/)[0]);
  // if (
  //   'requestPurpose' in formik.values &&
  //   formik.values.requestPurpose[serviceIndex].payment_period
  // ) {
  //   formik.setFieldValue(
  //     `requestPurpose[${serviceIndex}].payment_period`,
  //     undefined
  //   );
  // }
  // }
}

function clearValues(
  input,
  formik,
  SetInputKey,
  isChildSection,
  setSubmission,
  option,
  step,
  submission,
  ev
) {
  clearPaymentPeriod(formik, input, ev);
  if (input.validatedInputs.length) {
    input.validatedInputs.forEach((validatedInput) => {
      if (
        input.input_layout.type == 'checkbox' &&
        option.data_value !=
          validatedInput.InputValidation.condition?.value.replace(
            /[('')]/g,
            ''
          ) &&
        isChildSection
      ) {
        return;
      }
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, '');
    });
  }

  if (input.validatedDocuments.length) {
    input.validatedDocuments.forEach((validatedInput) => {
      if (
        input.input_layout.type == 'checkbox' &&
        option.data_value !=
          validatedInput.DocumentValidation.condition?.value.replace(
            /[('')]/g,
            ''
          ) &&
        isChildSection
      ) {
        return;
      }
      let inputKey = SetInputKey(validatedInput, step.objectName);
      formik.setFieldValue(inputKey, '');
      setSubmission({
        ...submission,
        attachments: submission.attachments.filter(
          (attachment) => attachment.name != inputKey
        )
      });
    });
  }
}
export function setInputKey(input, numParts) {
  let key = input.key;
  if (typeof numParts != 'undefined') {
    key = input.multi_key;
    numParts.forEach((element) => {
      key = key.replace('[i]', element);
    });
  }
  return key;
}
const updateInputs = async (inputs, eventInputs) => {
  return await Promise.all(
    inputs.map(async (input) => {
      return input;
    })
  );
};

export const setSections = async (form, mount, setSteps, eventInputs) => {
  let sections = _.cloneDeep(form);

  sections = await Promise.all(
    sections.map(async (section) => {
      if (section?.group) {
        section.group = await Promise.all(
          section.group.map(async (group) => {
            group.inputs = await updateInputs(group.inputs, eventInputs);
            return group;
          })
        );
      }
      if (section?.child_sections) {
        section.child_sections = await Promise.all(
          section.child_sections.map(async (step) => {
            step.inputs = await updateInputs(step.inputs, eventInputs);
            return step;
          })
        );
      }
      // section.inputs = await updateInputs(section.inputs, eventInputs);
      return section;
    })
  );
  mount.current && setSteps(sections);
};

export function flattenObj(object) {
  const getEntries = (o, prefix = "") => {
    return Object.entries(o).flatMap(([k, v]) => {
      return Object(v) === v
        ? //FIXME:replace static key "values" with dynamic given keys
        _.isEmpty(v)
          ? [[`${prefix}${k}`, v]]
          : getEntries(v, `${prefix}${k}.`)
        : [[`${prefix}${k}`, v]];
    });
  };
  return Object.fromEntries(getEntries(object));
};

export function ConstructFormErrors(errorsObject) {
  errorsObject = flattenObj(errorsObject);
  const submissionsKeyRE = /submissions\[\d+\]/;
  const indexKey = /\d+/;
  const errors = [];
  for (const [key, value] of Object.entries(errorsObject)) {
    const result = key.match(submissionsKeyRE);
    if (result) {
      const index = Number(result[0].match(indexKey));
      errors.push(`${value} in Submission ${index + 1}`);
    } else errors.push(value);
  }
  return errors;
}
export function addSiblingChild(section, manyEntities, steps, randomId) {
  function findSiblingSection(mainSection) {
    if (mainSection.group) {
      for (const childSection of mainSection.group) {
        if (childSection.main_section_id) return childSection;
        if (childSection.group) {
          const result = findSiblingSection(childSection);
          if (result) return result;
        }
      }
    }
    return null;
  }

  const siblingSection = findSiblingSection(section);

  if (!siblingSection) return;
  const otherSiblingSection = findBySectionId(
    siblingSection.main_section_id,
    steps
  );
  if (
    otherSiblingSection.name in manyEntities &&
    Array.isArray(manyEntities[otherSiblingSection.name])
  ) {
    const arrayLen = manyEntities[section.name].length;

    manyEntities[section.name][arrayLen - 1][siblingSection.name] = _.cloneDeep(
      manyEntities[section.name][0][siblingSection.name]
    );
  }
}
// TODO: This function is not working as intended. Either remove it for fix it
function evaluateDisabled(
  input,
  ev,
  step,
  subActiveStepIndex,
  isChildSection,
  inputIndex
) {
  let toBeValidated = null;
  let validationKey = null;
  let validatedItemsName = null;
  let disabled = false;
  if (input.validatedInputs.length) {
    toBeValidated = input.validatedInputs;
    validationKey = 'InputValidation';
    validatedItemsName = 'validatedInputs';
  } else {
    toBeValidated = input.validatedDocuments;
    validationKey = 'DocumentValidation';
    validatedItemsName = 'validatedDocuments';
  }
  const operator = toBeValidated[0][validationKey].condition.operator;
  if (operator === '==') {
    if (input.itype === 'enum') {
      // Values sometimes have character: "'" in them, these cause inequality between two similar values.
      // I am not going to bother and remove both characters
      if (
        ev.target.value.replace("'", '').replace("'", '') ===
        toBeValidated[0][validationKey].condition.value
          .replace("'", '')
          .replace("'", '')
      ) {
        disabled = false;
      } else {
        disabled = true;
      }
    } else if (input.itype === 'boolean') {
      disabled = ev.target.value === 'false';
    }
  }
  const newStep = _.cloneDeep(step);
  if (isChildSection) {
    for (let j = 0; j < input[validatedItemsName].length; j++) {
      newStep.child_sections[subActiveStepIndex].inputs[inputIndex][
        validatedItemsName
      ][j] = {
        ...newStep.child_sections[subActiveStepIndex].inputs[inputIndex][
          validatedItemsName
        ][j],
        disabled
      };
    }
  } else {
    for (let j = 0; j < input[validatedItemsName].length; j++) {
      newStep.inputs[inputIndex][validatedItemsName][j] = {
        ...newStep.inputs[inputIndex][validatedItemsName][j],
        disabled
      };
    }
  }
  return newStep;
}

export function isGeneralSecForm(user, path) {
  return (
    path.includes('/programs/12') &&
    user.roles.some((role) => role.name === 'general secertary')
  );
}

export function isGeneralSecAssistantForm(user, path) {
  return (
    path.includes('/programs/13') &&
    user.roles.some((role) => role.name === 'general secertary assistant')
  );
}

export function isOnline(extraData) {
  return extraData && extraData.connectionStatus;
}

export function isOnlineInFormStep(connection) {
  return connection;
}
