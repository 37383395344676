/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './index.scss';
import InputWidget from './InputWidget';
import { Grid, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { ClipLoader } from 'react-spinners';
import { findBySectionId, setInputKey, addSiblingChild } from './utils';
import { getValue } from 'utils/GetObjectValues';

function scanForSectionByName(name, cloneManyEntities, result) {
  if (!Array.isArray(cloneManyEntities)) {
    for (const key of Object.keys(cloneManyEntities)) {
      if (key !== 'randomId') {
        if (key === name) result.push(cloneManyEntities[key]);
        scanForSectionByName(name, cloneManyEntities[key], result);
      }
    }
  } else {
    for (const entity of cloneManyEntities) {
      scanForSectionByName(name, entity, result);
    }
  }
}

export const displayInputWidget = ({
  setInitialValues,
  input,
  formik,
  step,
  sectionIndex,
  groupIdx,
  index,
  isTransition,
  submission,
  sectionNameFlags,
  setSectionNameFlags,
  arrayIndexes,
  actionType,
  extraData,
  setAlertMessage
}) => {
  return (
    <>
      {!input.input_layout.hidden && (
        <InputWidget
          actionType={actionType}
          // onInputChange={onInputChange}
          extraData={extraData}
          setAlertMessage={setAlertMessage}
          key={input.key}
          input={{ ...input, section: step?.objectName }}
          formik={formik}
          setInitialValues={setInitialValues}
          sectionIndex={sectionIndex}
          groupIdx={groupIdx}
          index={index}
          isTransition={isTransition}
          submissionValue={submission}
          sectionNameFlags={sectionNameFlags}
          setSectionNameFlags={setSectionNameFlags}
          stepId={step.id}
          isMany={step.isMany}
          arrayIndexes={arrayIndexes}></InputWidget>
      )}
    </>
  );
};

export function GetStepContent({
  formik,
  activeStep,
  steps,
  subActiveStep,
  setInitialValues,
  childStep,
  numbering,
  isTransition,
  manyEntities,
  setManyEntities,
  elementsProtoType,
  submission,
  submissionId,
  sectionNameFlags,
  setSectionNameFlags,
  actionType,
  extraData,
  setAlertMessage,
  setSubmissionValues,
  isUploadingFile,
  removing,
  setRemoving
}) {
  const cloneManyEntities = _.cloneDeep(manyEntities);

  const renderContent = (section, element, groupIdx) => {
    const displaySection = sectionNameFlags
      ? (Object.keys(sectionNameFlags).includes(`${section.id}`) &&
          Object.values(sectionNameFlags[`${section.id}`]).includes(true)) ||
        !Object.keys(sectionNameFlags).includes(`${section.id}`)
      : true;
    let useNumbering = true;
    let headerStyle = {
      fontSize: 28,
      fontWeight: 'bold',
      lineHeight: '34px',
      color: 'black'
    };
    if ('style' in section && section.style) {
      if ('numbering' in section.style) useNumbering = section.style.numbering;
      if ('fontSize' in section.style)
        headerStyle.fontSize = section.style.fontSize;
    }

    return (
      <>
        <Grid
          container
          spacing={3}
          style={{ padding: '16px', display: !displaySection && 'none' }}
          className={`${isTransition ? 'center-items' : ''} m-0`}>
          {/* display subsection name */}
          {(!isTransition || section.name) && (
            <Grid item xs={12} className=" pl-0">
              {/* FIXME: */}
              <h3 className="display-inline-block mb-0" style={headerStyle}>
                {numbering !== undefined &&
                useNumbering &&
                numbering?.sectionIndex
                  ? numbering?.sectionIndex + '.'
                  : ''}
                {useNumbering && groupIdx !== undefined ? groupIdx + 1 : ''}{' '}
                {section.name}
              </h3>
            </Grid>
          )}

          {section.isMany
            ? renderManySteps(
                formik,
                section,
                groupIdx,
                setInitialValues,
                numbering?.sectionIndex,
                isTransition,
                element,
                sectionNameFlags,
                setSectionNameFlags,
                actionType,
                extraData,
                setAlertMessage,
                submission,
                setSubmissionValues
              )
            : // display inputs
              renderInputs({
                setInitialValues,
                formik,
                step: section,
                sectionIndex: numbering?.sectionIndex,
                groupIdx: groupIdx + 1,
                isTransition,
                element,
                submission,
                sectionNameFlags,
                setSectionNameFlags,
                actionType,
                extraData,
                setAlertMessage
              })}
        </Grid>
      </>
    );
  };
  const renderManySteps = (
    formik,
    section,
    groupIdx,
    setInitialValues,
    sectionIndex,
    isTransition,
    element,
    sectionNameFlags,
    setSectionNameFlags,
    actionType,
    extraData,
    setAlertMessage,
    submission,
    setSubmissionValues
  ) => {
    const handleRemoveInstanceClick = (e, index) => {
      e.stopPropagation();
      element[section.name].splice(index, 1);
      setManyEntities(cloneManyEntities);
      let sibling_sections = section.sibling_sections;
      if (sibling_sections) {
        changeSiblingSectionsNumber(sibling_sections, false, index);
      }
      let sectionValues = getValue(formik.values, section.key);
      if (typeof sectionValues === 'object')
        sectionValues = _.cloneDeep(sectionValues);
      sectionValues?.forEach((value) => {
        if (typeof value == 'object') {
          Object.keys(value).forEach((key) => {
            if (typeof value[key] == 'object') {
              let arr = [];
              value[key].forEach((x) => {
                x?.preview && arr.push(x.preview);
              });
              setSubmissionValues({
                attachments: submission.attachments.filter(
                  (attachment) => !arr.includes(attachment.metadata?.preview)
                )
              });
            }
          });
        }
      });
      sectionValues?.splice(index, 1);
      formik.setFieldValue(section.key, sectionValues);
      setRemoving(false);
      // onSectionRemove(section, cloneManyEntities, setManyEntities);
      // decrease number property of specific section or group
    };
    const changeSiblingSectionsNumber = (sibling_sections, increase, index) => {
      sibling_sections.forEach((siblingSection) => {
        const sectionCopy = findBySectionId(siblingSection.id, steps);
        if (!sectionCopy) throw new Error('Failed to find section!!');
        if (increase) {
          const result = [];
          scanForSectionByName(sectionCopy.name, cloneManyEntities, result);
          for (const item of result) {
            const objAdded = elementsProtoType[sectionCopy.name];
            item.push(objAdded);
          }
        } else {
          const result = [];
          scanForSectionByName(sectionCopy.name, cloneManyEntities, result);
          for (const item of result) {
            item.splice(index, 1);
          }
        }
        setManyEntities(cloneManyEntities);
      });
    };
    const handleAddInstanceClick = (e) => {
      e.stopPropagation();
      // onSectionAdd(section, cloneManyEntities, setManyEntities);
      const objectAdded = element[section.name];
      addSiblingChild(section, element, steps);
      // if (randomId) objectAdded.randomId = randomId;
      if (objectAdded) element[section.name].push(objectAdded);
      setManyEntities(cloneManyEntities);
      let sibling_sections = section.sibling_sections;
      if (sibling_sections) {
        changeSiblingSectionsNumber(sibling_sections, true, null);
      }
    };

    let useSeperator = true;
    let useNumbering = true;
    let headerStyle = {
      fontSize: 28,
      fontWeight: 'bold',
      lineHeight: '34px',
      color: 'black',
      width: '100%'
    };
    if ('style' in section && section.style) {
      if ('separators' in section.style)
        useSeperator = section.style.separators;
      if ('numbering' in section.style) useNumbering = section.style.numbering;
      if ('fontSize' in section.style)
        headerStyle.fontSize = section.style.fontSize;
    }
    return (
      <>
        {element[section.name]?.map((ele, index) => {
          ele.arrayIndexes = element.arrayIndexes
            ? [...element.arrayIndexes, index]
            : [index];
          return (
            <React.Fragment key={index}>
              {index !== 0 && (
                <>
                  {useSeperator && <hr />}
                  <p style={headerStyle}>
                    {useNumbering
                      ? section.name + ` (${index + 1})`
                      : section.name}
                  </p>
                </>
              )}

              {renderInputs({
                setInitialValues,
                formik,
                step: section,
                arrIndex: index,
                sectionIndex,
                groupIdx: groupIdx + 1,
                isTransition,
                element: ele,
                sectionNameFlags,
                setSectionNameFlags,
                actionType,
                extraData,
                setAlertMessage
              })}
              {index !== 0 && !section.main_section_id && (
                <div className="w-100">
                  {!isUploadingFile && !removing ? (
                    <Button
                      varient="text"
                      className="action-button"
                      onClick={(e) => {
                        setRemoving(true);

                        handleRemoveInstanceClick(e, index);
                      }}>
                      <span style={{ marginLeft: '10px' }}>{' إزالة '}</span>
                      <FontAwesomeIcon
                        icon={faMinus}
                        style={{ marginLeft: '32px', padding: '2px' }}
                        className="bg-color-primary"
                      />
                    </Button>
                  ) : (
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      inputlayout={{ float: 'left' }}>
                      <ClipLoader />
                    </Button>
                  )}
                </div>
              )}
            </React.Fragment>
          );
        })}

        <div className="w-100">
          {!isUploadingFile ? (
            !(submissionId && section.key === 'submissions') &&
            section.isMany &&
            !section.main_section_id && (
              <Button
                variant="text"
                className="action-button"
                onClick={(e) => handleAddInstanceClick(e)}>
                <span style={{ marginLeft: '10px' }}>
                  {/* TODO: need localization */}
                  {'إضافة'}
                </span>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ marginLeft: '32px', padding: '2px' }}
                  className="bg-color-primary"
                />
              </Button>
            )
          ) : (
            <Button
              disabled
              variant="contained"
              color="primary"
              inputlayout={{ float: 'left' }}>
              <ClipLoader />
            </Button>
          )}
        </div>
      </>
    );
  };

  function renderInputs({
    setInitialValues,
    formik,
    step,
    sectionIndex,
    groupIndex,
    isTransition,
    element,
    submission,
    sectionNameFlags,
    setSectionNameFlags,
    actionType,
    extraData,
    setAlertMessage
  }) {
    return (
      <>
        {step.inputs?.map((input, index) => (
          <React.Fragment key={input.key + index}>
            {displayInputWidget({
              setInitialValues,
              input: {
                ...input,
                key: setInputKey(input, element?.arrayIndexes)
              },
              formik,
              step,
              sectionIndex,
              groupIdx: groupIndex,
              index,
              isTransition,
              submission,
              sectionNameFlags,
              setSectionNameFlags,
              arrayIndexes: element?.arrayIndexes,
              actionType,
              extraData,
              setAlertMessage
            })}
          </React.Fragment>
        ))}
        {step.group && (
          <>
            {step.group.map((section, index) => (
              <React.Fragment key={section.id}>
                {renderContent(
                  section,
                  step.isMany ? element : element[step.name],
                  index
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  }
  // check wether the active step is a sub step or not {productInformation:[{ingredients:[{}]}]}
  if (!childStep) return renderContent(steps[activeStep], cloneManyEntities);
  else
    return renderContent(
      steps[activeStep].child_sections[subActiveStep],
      cloneManyEntities[steps[activeStep].name],
      subActiveStep
    );
}
