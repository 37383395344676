import { openDB } from 'idb';
import {
  DB_NAME,
  DB_VERSION,
  DB_SUBMISSION_STORE,
  DB_TEMP_STORE
} from './constants';

export default class IndexedDB {
  static _instance;

  static async initDB() {
    this._instance = await openDB(DB_NAME, DB_VERSION, {
      upgrade(db, oldVersion, newVersion) {
        const tempStore = db.createObjectStore(DB_TEMP_STORE, {
          autoIncrement: true
        });
        const submissionStore = db.createObjectStore(DB_SUBMISSION_STORE, {
          autoIncrement: true
        });
      }
    });
    return true;
  }

  static async addTemporaryAttachment(attachment) {
    this.checkAttachmentHasName(attachment);
    const key = await this.getAttachmentKeyInStore(attachment);
    const tx = this._instance.transaction(DB_TEMP_STORE, 'readwrite');
    const store = tx.objectStore(DB_TEMP_STORE);
    if (key) {
      await store.put(attachment, key);
    } else {
      await store.put(attachment);
    }
    await tx.done;
  }

  static async clearTemporaryAttachments() {
    const tx = this._instance.transaction(DB_TEMP_STORE, 'readwrite');
    const store = tx.objectStore(DB_TEMP_STORE);
    await store.clear();
    await tx.done;
  }

  static async getAttachmentKeyInStore(attachment) {
    this.checkAttachmentHasName(attachment);
    let cursor = await this._instance
      .transaction(DB_TEMP_STORE)
      .store.openCursor();

    while (cursor) {
      if (cursor.value.name === attachment.name) return cursor.key;
      cursor = await cursor.continue();
    }

    return null;
  }

  static async getAllAttachemnts(getKeyValue = false) {
    const attachments = [];
    let cursor = await this._instance
      .transaction(DB_TEMP_STORE)
      .store.openCursor();

    while (cursor) {
      if (getKeyValue)
        attachments.push({ value: cursor.value, key: cursor.key });
      else attachments.push(cursor.value);
      cursor = await cursor.continue();
    }
    return attachments;
  }

  static checkAttachmentHasName(attachment) {
    if (!attachment.name) throw new Error('Attachment has no name');
  }

  static async addSubmission(submission) {
    const tx = this._instance.transaction(DB_SUBMISSION_STORE, 'readwrite');
    const store = tx.objectStore(DB_SUBMISSION_STORE);
    await store.put(submission);
    await tx.done;
  }

  static async getAllSubmissions(getKeyValue = false) {
    const submissions = [];
    let cursor = await this._instance
      .transaction(DB_SUBMISSION_STORE)
      .store.openCursor();

    while (cursor) {
      if (getKeyValue)
        submissions.push({ value: cursor.value, key: cursor.key });
      else submissions.push(cursor.value);
      cursor = await cursor.continue();
    }
    return submissions;
  }

  static async removeSubmission(key) {
    const tx = this._instance.transaction(DB_SUBMISSION_STORE, 'readwrite');
    const store = tx.objectStore(DB_SUBMISSION_STORE);
    await store.delete(key);
    await tx.done;
  }

  static async clearSubmissions() {
    const tx = this._instance.transaction(DB_SUBMISSION_STORE, 'readwrite');
    const store = tx.objectStore(DB_SUBMISSION_STORE);
    await store.clear();
    await tx.done;
  }
}
