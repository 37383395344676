import React, { useEffect, useState } from 'react';

import {
  Table,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import client from 'api/apiAuth/guestClient';

export default function LivePreviewExample({ cardsDetails }) {
  let [tableData, setTableData] = useState([]);
  let [allCards, setAllCards] = useState([]);
  let [filter, setFilter] = useState('');
  const months = [
    { id: 1, name: 'يناير' },
    { id: 2, name: 'فبراير' },
    { id: 3, name: 'مارس' },
    { id: 4, name: 'ابريل' },
    { id: 5, name: 'مايو' },
    { id: 6, name: 'يونيو' },
    { id: 7, name: 'يوليو' },
    { id: 8, name: 'أغسطس' },
    { id: 9, name: 'سبتمبر' },
    { id: 10, name: 'أكتوبر' },
    { id: 11, name: 'نوفمبر' },
    { id: 12, name: 'ديسمبر' }
  ];
  useEffect(() => {
    let data = [];
    allCards.forEach((element) => {
      element.count.forEach((item) => {
        if (data?.find((item2) => item2?.program === item.name)) {
          data = data.map((item3) => {
            if (item3.program === item.name) {
              return { ...item3, [element.name]: item.count };
            }
            return item3;
          });
        } else {
          data.push({ program: item.name, [element.name]: item.count });
        }
      });
    });
    setTableData(data);
  }, [allCards]);
  useEffect(() => {
    const filterResults = async () => {
      let filters;
      if (filter && filter != 'all') {
        filters = [
          {
            field: 'submissions.createdAt',
            checkDateOnly: true,
            func: 'MONTH',
            value: filter
          }
        ];
      }
      if (filter == 'all') filters = [];
      let { data } = await client.get('/dashboard/getDashboardInformation', {
        params: {
          type: 'Table',
          filters
        }
      });
      setAllCards(data.statistics[0].componentDetails);
    };
    if (filter) filterResults();
  }, [filter]);
  useEffect(() => {
    if (cardsDetails) setAllCards(cardsDetails);
  }, [cardsDetails]);
  return (
    <>
      <Card className="card-box  mb-spacing-6-x2 mt-4" style={{width:"100%"}}>
        <div className="card-header-alt p-4">
          <h6
            className="font-weight-bold font-size-lg mb-1 text-black"
            style={{ display: 'inline' }}>
            احصائيات البرامج
          </h6>
          <FormControl
            variant="outlined"
            size="small"
            // className="w-45"
            style={{ display: 'inline', float: 'left', width: '100px' }}>
            <InputLabel id="type-select-label">الشهور</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select-label-id"
              value={filter}
              fullWidth
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              label="Months">
              <MenuItem value={'all'}>{'جميع الشهور'}</MenuItem>
              {months.map((month) => {
                return <MenuItem value={month.id}>{month.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </div>
        <div className="divider" />
        <CardContent className="pt-3 px-4 pb-4">
          <Table className="table table-hover table-striped text-nowrap mb-0">
            <thead className="thead-light">
              <tr>
                <th >البرنامج</th>
                <th className="text-center">عدد الطلبات</th>
                <th className="text-center">عدد الطلبات المعلقة</th>
                <th className="text-center">عدد الطلبات المقبوله</th>
                <th className="text-center">عدد الطلبات المرفوضه</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => {
                return (
                  <tr>
                    <td className="text-right " >
                      <span className="font-weight-bold ">{row.program}</span>
                    </td>
                    <td className="text-center text-info">
                      <span>{row['All Submissions'] || 0}</span>
                    </td>
                    <td className="text-center text-warning">
                      <span>{row['Pending'] || 0} </span>
                    </td>
                    <td className="text-center text-success">
                      <span>{row['Approved'] || 0} </span>
                    </td>
                    <td className="text-center text-danger">
                      <span>{row['Rejected'] || 0} </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}
