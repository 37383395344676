import store from '../reducers/store';
import { setErrorPage, setErrorMessage } from '../reducers/error/errorActions';
import { logout } from '../reducers/auth';

export const apiConfig = {
  // TODO : change baseUrl
  baseUrl: process.env.REACT_APP_SERVER_URL
  // should end with a slash
};

export const getUrl = (config) => {
  if (config.baseURL) {
    return config.url.replace(config.baseURL, '');
  }
  return config.url;
};

export const getAccessToken = () => {
  try {
    const retrievedItem = localStorage.getItem('tokenData');
    if (retrievedItem !== null) {
      const authorization = `${retrievedItem}`;
      // We have data!!
      return authorization;
    }
    return null;
  } catch (error) {
    console.log(error);

    // Error retrieving data
  }
};

export const handleError = (error) => {
  if (
    error.message === 'Network Error' &&
    error.config.url === '/submission/getOldSubmission'
  )
    return;
  if (error.response) {
    if ([404, 505].includes(error.response.status)) {
      let errorPage = {
        pathname: `/PageError${error.response.status}`
      };
      store.dispatch(setErrorPage(errorPage));
      // Handle other errors (404 - 500 - ..etc) redirects to Error Component
      return errorPage;
    } else if (error.response.status === 401) {
      if (error.response.data && error.response.data.error) {
        if (error.response.data.error.name === 'TokenExpiredError') {
          store.dispatch(logout());
        }
        if (
          ['TokenExpiredError', 'No auth token'].includes(
            error.response.data.error.name
          ) ||
          ['TokenExpiredError', 'No auth token'].includes(
            error.response.data.error.message
          )
        ) {
          localStorage.removeItem('user');
          store.dispatch({ type: 'RESET_STORE', payload: null });
          window.location.url = '/login';
          return;
        }
      }
      let errorPage;
      if (localStorage.getItem('user')) {
        errorPage = {
          pathname: '/worklist'
        };
      } else {
        errorPage = {
          pathname: '/login'
        };
      }
      store.dispatch(setErrorPage(errorPage));
      // Handle other errors (404 - 500 - ..etc) redirects to Error Component
      return errorPage;
    }
    // Generic Internal Server Error
    else if (error.response.status === 500) {
      store.dispatch(setErrorMessage('Something went wrong! Error Code: 500'));
      return 'Something went wrong! Error Code: 500';
    } else {
      store.dispatch(setErrorMessage(error.response.data.message));
      // Handle other errors (404 - 500 - ..etc) redirects to Error Component
      return error.response.message;
    }
  } else if (error.request) {
    store.dispatch(setErrorMessage('Problem with request'));
    return 'Problem with request';
  } else {
    store.dispatch(setErrorMessage(error.message));
    return error.message;
  }
};
