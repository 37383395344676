import React, { useEffect, useState } from 'react';

import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TextField
} from '@material-ui/core';

import client from 'api/apiAuth/guestClient';

export default function UserTrackingTable({ cardsDetails }) {
  let [tableData, setTableData] = useState([]);
  let [users, setUsers] = useState([]);
  let [filters, setFilters] = useState([]);
  let [allData, setAllData] = useState([]);
  let [disableField, setDisableField] = useState(true);
  const getArrayOfDates = (initialTime, endTime) => {
    const date = new Date(initialTime.getTime());

    const dates = [];

    while (date <= endTime) {
      dates.push({
        date: new Date(date).toLocaleDateString()
      });
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };
  let covertSecondsToTime = (given_seconds) => {
    let hours = Math.floor(given_seconds / 3600);
    let minutes = Math.floor((given_seconds - hours * 3600) / 60);
    let seconds = given_seconds - hours * 3600 - minutes * 60;
    let timeString =
      hours.toString().padStart(2, '0') +
      ':' +
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0');
    return timeString;
  };
  const getTableData = (startDate, endDate, data) => {
    let dates = getArrayOfDates(startDate, endDate);
    data?.forEach((cardDetails) => {
      cardDetails.count.forEach((element) => {
        let timeSpent = 0;
        if (
          (new Date(element.onDate).getFullYear() == startDate.getFullYear() ||
            new Date(element.onDate).getFullYear() == endDate.getFullYear()) &&
          (new Date(element.onDate).getMonth() == startDate.getMonth() ||
            new Date(element.onDate).getMonth() == endDate.getMonth())
        ) {
          timeSpent = element.timeSpent;
        }
        dates = dates.map((dateObj) => {
          if (dateObj.date == new Date(element.onDate).toLocaleDateString()) {
            return {
              ...dateObj,
              timeSpent: covertSecondsToTime(timeSpent)
              // user_name: element['user.username']
            };
          } else return dateObj;
        });
      });
    });
    return dates;
  };
  useEffect(() => {
    setAllData(cardsDetails);
  }, [cardsDetails]);
  useEffect(() => {
    let startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    let endDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );
    if (allData) {
      let dateFilter = filters?.find((filter) => filter.field == 'date');
      if (dateFilter) {
        startDate = new Date(dateFilter.value[0]);
        endDate = new Date(dateFilter.value[1]);
      }
      let dates = getTableData(startDate, endDate, allData);
      setTableData(dates);
    }
  }, [allData]);
  useEffect(() => {
    fetchAllUsers();
  }, []);
  useEffect(() => {
    const getFilterResult = async () => {
      let { data } = await client.get('/dashboard/getDashboardInformation', {
        params: {
          type: 'UserTrackingTable',
          filters
        }
      });
      setAllData(data.statistics[0].componentDetails);
    };
    if (filters.length > 0) getFilterResult();
  }, [filters]);
  const fetchAllUsers = async () => {
    let { data } = await client.get('user/getusers');
    setUsers(data.results.rows);
  };
  return (
    <>
      <Card className="card-box  mb-spacing-6-x2 mt-4" style={{width:"100%"}}>
        <div className="card-header-alt p-4">
          <h6
            className="font-weight-bold font-size-lg mb-1 text-black"
            style={{ display: 'inline' }}>
            احصائيات المستخدمين
          </h6>
          <FormControl
            variant="outlined"
            size="small"
            className="mr-4"
            style={{ display: 'inline', float: 'left', width: '130px' }}>
            <InputLabel id="type-select-label">المستخدمين</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select-label-id"
              fullWidth
              onChange={(e) => {
                setFilters((previousValue) => {
                  let previousFilter = previousValue.find(
                    (filter) => filter.field == 'user_id'
                  );
                  if (previousFilter)
                    return previousValue.map((filter) => {
                      if (filter.field == 'user_id') {
                        filter.value = e.target.value;
                      }
                      return filter;
                    });
                  else {
                    return [
                      ...previousValue,
                      {
                        field: 'user_id',
                        value: e.target.value
                      }
                    ];
                  }
                });
              }}
              label="Users">
              {users.map((user) => {
                return <MenuItem value={user.id}>{user.username}</MenuItem>;
              })}
            </Select>
          </FormControl>

          <FormControl
            className="mb-2 ml-4"
            variant="outlined"
            size="small"
            style={{ display: 'inline', float: 'left', width: '100px' }}>
            <TextField
              id="date"
              type="date"
              style={{ display: 'inline' }}
              name="to"
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => {
                setFilters((previousValue) => {
                  let previousFilter = previousValue.find(
                    (filter) => filter.field == 'date'
                  );
                  if (previousFilter)
                    return previousValue.map((filter) => {
                      if (filter.field == 'date') {
                        filter.value[1] = e.target.value;
                      }
                      return filter;
                    });
                  else {
                    return [
                      ...previousValue,
                      {
                        field: 'date',
                        operator: 'between',
                        value: [null, e.target.value]
                      }
                    ];
                  }
                });
              }}
            />
          </FormControl>
          <span style={{ display: 'inline', float: 'left' }} className="mr-1">
           إلى:
          </span>
          <FormControl
            className="mr-4 ml-4 mb-2"
            variant="outlined"
            size="small"
            style={{ display: 'inline', float: 'left', width: '100px' }}>
            <TextField
              id="date"
              type="date"
              style={{ display: 'inline' }}
              name="from"
              InputLabelProps={{
                shrink: true
              }}
              onChange={(e) => {
                setFilters((previousValue) => {
                  e.target.value ? setDisableField(false) : setDisableField(true);
                  let previousFilter = previousValue.find(
                    (filter) => filter.field == 'date'
                  );
                  if (previousFilter)
                    return previousValue.map((filter) => {
                      if (filter.field == 'date') {
                        filter.value[0] = e.target.value;
                      }
                      return filter;
                    });
                  else {
                    return [
                      ...previousValue,
                      {
                        field: 'date',
                        operator: 'between',
                        value: [e.target.value]
                      }
                    ];
                  }
                });
              }}
            />
          </FormControl> 
          <span style={{ display: 'inline', float: 'left' }} className="mr-1">
          من :
          </span>
        </div>
        <div className="divider" />
        <CardContent className="pt-3 px-4 pb-4">
          <Table className="table table-hover table-striped text-nowrap mb-0">
            <thead className="thead-light">
              <tr>
                <th>التاريخ</th>
                <th className="text-center">الوقت الذى تم قضائه ع الموقع</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => {
                return (
                  <tr>
                    <td className="text-right ">
                      <span className="font-weight-bold ">
                        {new Date(row.date).toDateString()}
                      </span>
                    </td>
                    <td className="text-center text-info">
                      <span>{row.timeSpent || 0}</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}
