// Sidebar
export const SET_SIDEBAR_TOGGLE_MOBILE = 'THEME/SET_SIDEBAR_TOGGLE_MOBILE';

export const SET_SIDEBAR_SHADOW = 'THEME/SET_SIDEBAR_SHADOW';
export const SET_SIDEBAR_STYLE = 'THEME/SET_SIDEBAR_STYLE';
export const SET_SIDEBAR_FOOTER = 'THEME/SET_SIDEBAR_FOOTER';
export const SET_SIDEBAR_TOGGLE = 'THEME/SET_SIDEBAR_TOGGLE';
export const SET_SIDEBAR_FIXED = 'THEME/SET_SIDEBAR_FIXED';
export const SET_SIDEBAR_USERBOX = 'THEME/SET_SIDEBAR_USERBOX';
export const SET_JSS_RTL = 'THEME/SET_JSS_RTL';

// Header

export const SET_HEADER_FIXED = 'THEME/SET_HEADER_FIXED';
export const SET_HEADER_SHADOW = 'THEME/SET_HEADER_SHADOW';
export const SET_HEADER_BG_TRANSPARENT = 'THEME/SET_HEADER_BG_TRANSPARENT';
export const SET_HEADER_SEARCH_HOVER = 'THEME/SET_HEADER_SEARCH_HOVER';
export const SET_HEADER_DRAWER_TOGGLE = 'THEME/SET_HEADER_DRAWER_TOGGLE';

// Main content

export const SET_CONTENT_BACKGROUND = 'THEME/SET_CONTENT_BACKGROUND';
export const SET_THEME_CONFIGURATOR_TOGGLE =
  'THEME/SET_THEME_CONFIGURATOR_TOGGLE';

// Footer

export const SET_FOOTER_FIXED = 'THEME/SET_FOOTER_FIXED';
export const SET_FOOTER_SHADOW = 'THEME/SET_FOOTER_SHADOW';
export const SET_FOOTER_BG_TRANSPARENT = 'THEME/SET_FOOTER_BG_TRANSPARENT';

// Page title

export const SET_PAGE_TITLE_STYLE = 'THEME/SET_PAGE_TITLE_STYLE';
export const SET_PAGE_TITLE_BACKGROUND = 'THEME/SET_PAGE_TITLE_BACKGROUND';
export const SET_PAGE_TITLE_SHADOW = 'THEME/SET_PAGE_TITLE_SHADOW';
export const SET_PAGE_TITLE_ICON_BOX = 'THEME/SET_PAGE_TITLE_ICON_BOX';
export const SET_PAGE_TITLE_DESCRIPTION = 'THEME/SET_PAGE_TITLE_DESCRIPTION';
