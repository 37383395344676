import React, { useEffect } from 'react';

import Program from '../../components/Program';
import fontawesome from '@fortawesome/fontawesome';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';

import {
  faHandHoldingHeart,
  faHandsHelping,
  faGrinBeam,
  faLifeRing,
  faDollarSign,
  faPencilAlt,
  faChild,
  faFemale,
  faWheelchair,
  faHouseUser,
  faHouseDamage
} from '@fortawesome/free-solid-svg-icons';
import { fetchForms } from '../../reducers/list';

fontawesome.library.add(
  faHandHoldingHeart,
  faHandsHelping,
  faGrinBeam,
  faLifeRing,
  faDollarSign,
  faPencilAlt,
  faChild,
  faFemale,
  faWheelchair,
  faHouseUser,
  faHouseDamage
);

const BLACKLIST_FORMS = [12, 13, 14];
// TODO: Fix this by introducing multi role architecture
const removeFastTrackForms = (forms) => {
  if (!forms) return [];
  const newForms = [];
  for (const form of forms) {
    if (!BLACKLIST_FORMS.includes(form.id)) {
      newForms.push(form);
    }
  }
  return newForms;
};

function Programs({ forms, fetchForms }) {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '50vh' }}>
        <Grid item xl={10} className="p-5">
          <h2 style={{ textAlign: 'center', color: '#3b3e66 ' }}>
            {'من فضلك اختر نوع الطلب'}
          </h2>
        </Grid>
        <Grid
          item
          xl={10}
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="center">
          {forms &&
            removeFastTrackForms(forms).map((form) => (
              <Program form={form} key={form.id} />
            ))}
        </Grid>
      </Grid>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    forms: state.formList.forms
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchForms: (params) => dispatch(fetchForms(params))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Programs);
