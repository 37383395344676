import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { logout } from '../reducers/auth';

const PrivateRoute = ({ auth, user, component: Component, ...rest }) => {
  let { loading } = auth;

  const availableRoutes = {
    shared: [
      '/workList',
      '/details/:id',
      '/inquiry',
      '/drafts',
      '/datagrid/:currentTable',
      '/archivedSubmissions'
    ],
    requester: [
      '/programs/:formId/:id?/',
      '/programs/:formId/submissions/:submissionId/edit',
      '/programs',
      '/storage'
    ],
    'administration office employee': [
      '/programs/:formId/:id?/',
      '/programs/:formId/submissions/:submissionId/edit'
    ],
    'administration office manager': [
      '/programs/:formId/:id?/',
      '/programs/:formId/submissions/:submissionId/edit'
    ],
    manager: ['/programs/:formId/:id?/'],
    'research manager': [
      '/programs/:formId/:id?/',
      '/programs/:formId/submissions/:submissionId/edit'
    ],
    'general secertary': ['/dashboard', '/programs/:formId/:id?/'],
    'excutive director': ['/dashboard', '/programs/:formId/:id?/'],
    archive: [],
    researcher: [
      '/programs/:formId/:id?/',
      '/programs/:formId/submissions/:submissionId/edit'
    ],

    'research supervisor': [
      '/programs/:formId/:id?/',
      '/programs/:formId/submissions/:submissionId/edit'
    ],

    'research general manager': [
      '/programs/:formId/:id?/',
      '/programs/:formId/submissions/:submissionId/edit'
    ],

    finance: [],
    'finance manager': [],
    admin: ['/users'],
    'super admin': ['/dashboard', '/programs/:formId/:id?/', '/users'],
    'general secertary assistant': ['/programs/:formId/:id?/'],
    'legal manager': [],
    'web entry': [
      '/programs/:formId/:id?/',
      '/programs/:formId/submissions/:submissionId/edit'
    ]
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!loading) {
          if (!user) {
            // not logged in so redirect to login page with the return url
            return (
              <Redirect
                to={{ pathname: '/login', state: { from: props.location } }}
              />
            );
          }
          // Authorised so return component
          if (
            availableRoutes['shared'].includes(rest.path) ||
            user.roles.some((role) =>
              availableRoutes[role.name]?.includes(rest.path)
            )
          ) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{ pathname: '/worklist', state: { from: props.location } }}
              />
            );
          }
        } else {
          return '';
        }
      }}
    />
  );
};
function mapStateToProps(state) {
  return { auth: state.auth, user: state.auth.user };
}
const composedHOC = compose(connect(mapStateToProps, null))(PrivateRoute);
export default composedHOC;
