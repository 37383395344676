import React, { useEffect } from 'react';
import { getValue } from '../../../utils/GetObjectValues';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox
} from '@material-ui/core';

const BooleanCheckbox = ({ input, formik, value }) => {
  function checkHouseholdHead() {
    const key = input.key.split(/\d/);
    const sectionName = key[0].slice(0, key[0].length - 1);
    let inp = input.key.split('.');
    if (inp.length == 2 && inp[1] == 'household_head') {
      if (
        Array.isArray(formik.values['relatives'])
      ) {
        formik.values['relatives'].forEach((item, index) => {
          formik.values['relatives'][`${index}`]['household_head'] = false;
        });
      }
    } else if (inp.length == 3) {
      formik.setFieldValue('primaryFields.household_head', false);
    }
    if (
      sectionName in formik.values &&
      Array.isArray(formik.values[sectionName])
    ) {
      formik.values[sectionName].forEach((section, index) => {
        formik.setFieldValue(
          `${sectionName}.[${index}].${key[1].slice(1, key[1].length)}`,
          false
        );
      });
    }
  }
  useEffect(() => {
    if ('initialValue' in input.input_layout) {
      if (!value) {
        formik.setFieldValue(input.key, input.input_layout.initialValue);
      }
    }
  }, []);
  return (
    <FormControl
      component="fieldset"
      className="formControl"
      style={input.input_layout?.labelStyle}>
      <FormGroup>
        <FormControlLabel
          className={`mb-3 ${value ? 'checkBox-checked' : ''}`}
          key={Math.random()}
          control={
            <Checkbox
              style={
                value
                  ? {
                      color: '#C7A058'
                    }
                  : null
              }
              name={input.key}
              data-section={input.section}
              onChange={(e) => {
                checkHouseholdHead();
                formik.handleChange(e);
              }}
              checked={getValue(formik.values, input.key)}
              disabled={input.disable}
            />
          }
          label={input.name}
        />
      </FormGroup>
    </FormControl>
  );
};

export default BooleanCheckbox;
