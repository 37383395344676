import ValidationSchema from 'utils/ValidationSchema/validationSchema';

import _ from 'lodash';
import store from '../reducers/store';

const getStepInput = (section) => {
  let updatedInputs = [];
  section.inputs.forEach((input) => {
    if (input.inputs?.length > 0) {
      input.inputs.forEach((inp) => {
        updatedInputs.push({
          ...inp,
          key: section.isMany ? inp.multi_key : inp.key,
          isManySec: section.isMany
        });
      });
    } else
      updatedInputs.push({
        ...input,
        key: section.isMany ? input.multi_key : input.key,
        isManySec: section.isMany
      });
  });
  return updatedInputs;
};

const getSectionsInputs = (sections) => {
  if (sections) {
    const allInputs = sections.flatMap((section) => {
      if (!_.isEmpty(section.inputs)) {
        if (!_.isEmpty(section.group)) {
          let mainInputs = getStepInput(section);
          let groupInputs = getSectionsInputs(
            section.group || section.child_sections
          );

          return [...mainInputs, ...groupInputs];
        } else {
          return getStepInput(section);
        }
      } else {
        return getSectionsInputs(section.group || section.child_sections);
      }
    });
    return allInputs;
  }
};

export const setSchema = function (inputs, isDraft) {
  const validationSchema = new ValidationSchema(inputs, 'Yup', isDraft);
  validationSchema.extractFormSchema();
  return validationSchema.schema;
};

const getSchema = function (isDraft) {
  const sections = store.getState().form.form.sections;
  const inputs = getSectionsInputs(sections);
  return setSchema(inputs, isDraft);
};

export const getModificationSchema = function (form, isDraft) {
  const inputs = getSectionsInputs(form.sections);
  return setSchema(inputs, isDraft);
};

export const getSettingValidationSchema = function (inputs, isDraft) {
  return setSchema(inputs, null);
};

export default getSchema;
