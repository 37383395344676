import React, { useEffect, useState } from 'react';
import { Dialog, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ErrorModal = ({ error }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (error) {
      setModal(true);
    }
  }, [error]);
  return (
    <Dialog
      id="erroModal"
      open={modal}
      onClose={toggle}
      classes={{ paper: 'shadow-lg rounded' }}>
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
            <FontAwesomeIcon
              icon={faTimes}
              className="d-flex align-self-center display-3"
            />
          </div>
        </div>
        <h4 className="font-weight-bold mt-4">There is an Error </h4>
        <p className="mb-0 font-size-lg ">{error?.message}</p>
        <div className="pt-4">
          <Button onClick={toggle} className="btn-danger btn-pill mx-1">
            <span className="btn-wrapper--label">OK</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ErrorModal;
