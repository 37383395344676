import React, { useEffect, useState } from 'react';

import Tables13 from '../../Table';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import client from 'api/apiAuth/guestClient';
import { fetchWorklistLayout } from 'reducers/list';

const PreviousSubmissions = ({ submission, columns, getLayout }) => {
  const [data, setData] = useState([]);
  let [layoutStructure, setLayoutStructure] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  let currentTab = 29;
  const getSubmissions = async (submission) => {
    let { data } = await client.get('tasks/inquiry', {
      params: {
        name: 'submission',
        value: submission?.contact_id,
        limit,
        page,
        filters: [
          { field: 'submission_id', value: submission.id, operator: 'ne' }
        ]
      }
    });
    setData(data.inquiry || []);
    setTotalCount(data.count);
  };
  useEffect(() => {
    if (submission) getSubmissions(submission);
  }, [submission, page]);
  useEffect(() => {
    setLayoutStructure(columns);
  }, [columns]);
  useEffect(() => {
    getLayout({ model: 'Task' }).catch((err) => {});
  }, []);
  return (
    <div className="accordion mb-spacing-4-x2 w-100">
      <Accordion style={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography variant="h6" style={{ direction: 'rtl' }}>
            <span style={{ float: 'right' }}>طلبات سابقة خاصة بالمستفيد</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: 'd-block' }}>
          <Tables13
            className="mt-4"
            layoutStructure={layoutStructure}
            setLayoutStructure={setLayoutStructure}
            layoutData={data}
            currentTab={currentTab}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            tasksCount={totalCount}
            page={page}
            applyFilter={false}
            activeTab={currentTab}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    columns: state.layout.columns
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(fetchWorklistLayout(params))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviousSubmissions);
