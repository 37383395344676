import React from 'react';
import { SyncLoader } from 'react-spinners';
const spinnerLoader = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: '300px' }}>
      <SyncLoader color="#3c44b1c4" loading={true} size={20} />
    </div>
  );
};

export default spinnerLoader;
