import {
  UPLOAD_FILE,
  SAVE_VALUES,
  START_ENGINE,
  ENABLE_FORM_SUBMIT_BUTTON,
  SAVE_FILES,
  GET_FORM_LAYOUT,
  SET_REQUIRED_DOCUMENTS,
  SET_NATIONALIDS,
  SAVE_DRAFT,
  SET_SUBMISSION,
  GET_DRAFT,
  UPDATE_SUBMISSION,
  SET_FORM_NAME,
  HANDLE_DRAFT_ASSIGNMENT,
  SET_BENEFICIARY_ID,
  UPLOAD_Stamp_FILE,
  CLEAR_SUBMISSION
} from './formActionTypes';
import client from '../../api/apiAuth/guestClient';

export const uploadFile = function (file) {
  let formData = new FormData();
  formData.append('avatar', file.data);
  return {
    type: UPLOAD_FILE,
    payload: client.post('attachment/uploadFile', formData),
    meta: {
      input_id: file.input_id,
      name: file.name,
      contact_id: file.contact_id,
      original_file_name: file.original_file_name,
      section: file.section,
      sectionName: file.sectionName,
      type: file.type,
      preview: file.preview
    }
  };
};

export const uploadStampFile = function (file) {
  let formData = new FormData();
  formData.append('avatar', file.data);
  return {
    type: UPLOAD_Stamp_FILE,
    payload: client.post('attachment/uploadStampFile', formData, {
      params: {
        meta: file
      }
    })
  };
};

export const setSubmission = function (submissionDetails) {
  return {
    type: SET_SUBMISSION,
    payload: submissionDetails
  };
};

export const saveValues = function (values) {
  return {
    type: SAVE_VALUES,
    payload: values
  };
};

export const startEngine = function (submission) {
  return {
    type: START_ENGINE,
    payload: client.post('workflow/startEngine', submission)
  };
};

export const enableSubmitButton = function () {
  return {
    type: ENABLE_FORM_SUBMIT_BUTTON
  };
};

export const saveFiles = function (name, file) {
  return {
    type: SAVE_FILES,
    payload: { name, file }
  };
};

export const getFormLayout = function (params) {
  return {
    type: GET_FORM_LAYOUT,
    payload: client.get('app/FormLayout', { params })
  };
};

export const setRequiredDocuments = function (input_id) {
  return {
    type: SET_REQUIRED_DOCUMENTS,
    payload: input_id
  };
};

export const saveDraft = function (submission) {
  return {
    type: SAVE_DRAFT,
    payload: client.post('submission/savedraft', submission)
  };
};
export const clearSubmission = function () {
  return {
    type: CLEAR_SUBMISSION,
  };
};

export const getDraft = function (params) {
  return {
    type: GET_DRAFT,
    payload: client.get('submission/getdraft', { params })
  };
};

export const updateSubmission = function (submission) {
  return {
    type: UPDATE_SUBMISSION,
    payload: client.put(`/submission/updateSubmission`, submission)
  };
};
export const setFormName = function (name) {
  return {
    type: SET_FORM_NAME,
    payload: name
  };
};

export const handleDraftAssignment = function (params, user_id) {
  return {
    type: HANDLE_DRAFT_ASSIGNMENT,
    payload: client.post(`/submission/handleDraftAssignment`, user_id, {
      params
    })
  };
};

export const setNationalIds = function (national_id, InputName) {
  return {
    type: SET_NATIONALIDS,
    payload: { InputName, national_id }
  };
};

export const setBenificiaryId = function (national_id) {
  return {
    type: SET_BENEFICIARY_ID,
    payload: national_id
  };
};
