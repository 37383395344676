module.exports = {
  getValue: (obj, path) => {
    if (path) {
      path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexepaths to propertis
      path = path.replace(/^\./, ''); // strip a leading dot
      return path.split('.').reduce((o, i) => {
        if (o) {
          return o[i];
        }
      }, obj);
    }
  }
};
