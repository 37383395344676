import {
  SET_ONLINE_STATUS,
  SET_IS_LISTENING,
  SET_TOAST_LAST_DISPLAY
} from './statusActionTypes';

export const setConnectionStatus = function (status) {
  return {
    type: SET_ONLINE_STATUS,
    payload: status
  };
};

export const setIsListening = function (listening) {
  return {
    type: SET_IS_LISTENING,
    payload: listening
  };
};

export const setToastLastDisplayDate = function (newDate) {
  return {
    type: SET_TOAST_LAST_DISPLAY,
    payload: newDate
  };
};
