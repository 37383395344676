export function evaluateAttachmentName(attachment, submission) {
  let name = null;
  if (attachment && attachment.data && attachment.data.document) {
    if (attachment.data.document.name == "صورة البطاقة" && attachment.data.contact_id) {
      let contactName = ""
      let contact = {}
      attachment.data.contact_id == submission.contact_id ? contactName = submission.contacts.name : contact = submission.contacts.contacts.relatives?.find(person => person.contact_id == attachment.data.contact_id)
      if (!_.isEmpty(contact)) contactName = contact.first_name + contact.family_name;
      name = `صورة بطاقة - ${contactName}`
    } else name = attachment.data.document.name;

  }
  if (
    attachment &&
    attachment.data &&
    attachment.data.document &&
    attachment.data.document.key === 'mediacal_insurance_doc'
  ) {
    // Medical Insurance name is empty in backend
    name = 'التأمين الطبى';
  }
  if (!name) {
    // Transfer letter document also has no name
    name = 'خطاب التحويل';
  }
  return name;
}
