import { getUrl, handleError } from '../utilities';
import reduxStore from '../../reducers/store';
import {
  DecreaseRequestsNo,
  IncreaseRequestsNo
} from 'reducers/general/generalActions';
const { dispatch } = reduxStore;

export const reqInterceptor = {
  resolve: (config) => {
    if (getUrl(config).includes('uploadFile')) {
      dispatch(IncreaseRequestsNo());
    }
    return config;
  },
  reject: (error) => {
    handleError(error);
    Promise.reject(error);
  }
};
export const resInterceptor = {
  resolve: async (response) => {
    if (getUrl(response.config).includes('uploadFile')) {
      dispatch(DecreaseRequestsNo());
    }
    return response;
  },
  reject: (error) => {
    handleError(error);
    return Promise.reject(error);
  }
};
