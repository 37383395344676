import React from 'react';
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './index.css';
import FILE_ACCORDION_TYPE from './types';
import { connect } from 'react-redux';
import { saveCardAttachment } from '../../reducers/details';
import AttachmentDetails from 'components/DetailsComponents/AttachmentDetails';

const useStyles = makeStyles({
  rootSummary: {
    backgroundColor: '#3447AD',
    color: 'white'
  },
  rootDetails: {
    backgroundColor: '#E4E9FF'
  },
  bottomBorderRedius: {
    borderRadius: '0 0 5px 5px'
  }
});

const FilesAccordion = ({
  attachments,
  type,
  setPdfModals,
  pdfModals,
  onAttachmentReviewed,
  attachmentsKey,
  submission
}) => {
  const classes = useStyles();

  let isRejected = false;

  if (type == FILE_ACCORDION_TYPE.DEFAULT || !type) {
    // getReviewedAttachments(cardAttachments);
    const attachmentReviewed = attachments.filter((attachment) => {
      return attachment?.data?.is_reviewed;
    });
    if (attachmentReviewed.length == attachments.length) {
      isRejected = false;
    } else {
      isRejected = true;
    }
  } else if (type == FILE_ACCORDION_TYPE.RELATIVE) {
    // getReviewedAttachments(cardAttachments[0]['items']);

    const attachmentReviewed = attachments[0]?.items.filter((attachment) => {
      return attachment?.data?.is_reviewed;
    });
    if (attachmentReviewed?.length == attachments[0]?.items?.length) {
      isRejected = false;
    } else {
      isRejected = true;
    }
  }
  let content;
  let len;
  if (type === FILE_ACCORDION_TYPE.DEFAULT) {
    len = attachments.length;
    content = (
      <Grid container className="align-content-right mb-3">
        {attachments.map((attachment, i) => {
          return (
            <React.Fragment key={attachment.data.id}>
              <AttachmentDetails
                item={attachment}
                setPdfModals={setPdfModals}
                pdfModals={pdfModals}
                onAttachmentReviewed={onAttachmentReviewed}
                attachmentsKey={attachmentsKey}
                submission={submission}
              />
            </React.Fragment>
          );
        })}
      </Grid>
    );
  } else if (type === FILE_ACCORDION_TYPE.RELATIVE) {
    len = 0;
    for (const attachmentGroup of attachments) {
      len += attachmentGroup.items.length;
    }
    content = (
      <Grid container className=" align-content-right mb-3">
        {attachments.map((items, idx) => (
          <React.Fragment key={`${items.contact_name}-6-${idx}`}>
            <Grid item sm={12} className="mb-3 mt-3">
              <h4 className="expand-content-text">{items.contact_name}</h4>
            </Grid>
            {items.items.map((item, id) => (
              <React.Fragment key={`${id}`}>
                <AttachmentDetails
                  item={item}
                  setPdfModals={setPdfModals}
                  pdfModals={pdfModals}
                  onAttachmentReviewed={onAttachmentReviewed}
                  attachmentsKey={attachmentsKey}
                  submission={submission}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    );
  } else {
    len = attachments.length;
    // Default case
    content = (
      <Grid container className="align-content-right mb-3">
        {attachments.map((attachment, i) => {
          return (
            <React.Fragment key={attachment.data.id}>
              <AttachmentDetails
                item={attachment}
                setPdfModals={setPdfModals}
                pdfModals={pdfModals}
                onAttachmentReviewed={onAttachmentReviewed}
                attachmentsKey={attachmentsKey}
                submission={submission}
              />
            </React.Fragment>
          );
        })}
      </Grid>
    );
  }

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon className="expand-icon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: `${classes.rootSummary} ${
            classes.bottomBorderRedius
          } btn-transition-none ${isRejected ? 'bg-red' : ''}`
        }}>
        <Typography>الملفات ({len})</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: classes.rootDetails
        }}>
        {content}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const mapStateToProps = (store) => {
  return {
    task: store.details.task,
    user: store.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCardAttachment: (attachment) => dispatch(saveCardAttachment(attachment))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilesAccordion);
