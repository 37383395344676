import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, FormControl, Select } from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchForms, fetchReqStage } from '../../reducers/list';
// import { setFiltersArray } from 'reducers/filter';

const InquiryFilters = ({
  filtersFillInp,
  filters,
  handleFilter,
  setFilters,
  getForms,
  forms,
  getReqStage,
  reqStage,
  isDraft
}) => {
  useEffect(() => {
    // getForms({ model: 'Form' }).catch((err) => {});
    getReqStage({ model: 'Step' }).catch((err) => { });
  }, []);
  let [selectedFilter, setSelectedFilter] = useState({});
  const handleSelectedProgram = (event) => {
    const newObj = { ...selectedFilter };
    newObj[event.target.name] = event.target.value;
    setSelectedFilter(newObj);

    // addToFilterArray('submissionFilter', event);
  };
  useEffect(() => {
    setSelectedFilter({});
  }, [filtersFillInp]);

  const tasksStatus = [
    { name: 'Stopped' },
    { name: 'Pending' },
    { name: 'Completed' },
    { name: 'Canceled' },
    { name: 'Deserved' },
    { name: 'Rejected' }
  ];

  useEffect(() => {
    if (Object.keys(selectedFilter).length) {
      handleFilter(selectedFilter);
    }
  }, [selectedFilter]);
  //search handling
  const handleChange = (filterType, event, having = false) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      addToFilterArray(filterType, event, true, having);
    }
    if (event.key === 'Backspace' && event.target.value === '') {
      removeFromFilterArray(event);
    }
  };
  const removeFromFilterArray = (event) => {
    let filterIndex = filters.findIndex(
      (filter) => filter.field === event.target.name
    );
    let newFilters = [...filters];
    if (filterIndex > -1) {
      newFilters.splice(filterIndex, 1);
      setFilters([...newFilters]);
    }
  };
  const addToFilterArray = (filterType, event, like = false, having) => {
    let filterObj = like
      ? {
        type: filterType,
        field: event.target.name,
        value: event.target.value + '%',
        operator: 'like',
        having
      }
      : {
        type: filterType,
        field: event.target.name,
        value: event.target.value,
        having
      };
    let newFilters = [...filters];
    let filterIndex = filters.findIndex(
      (filter) => filter.field === event.target.name
    );
    if (filterIndex > 0) {
      newFilters[filterIndex] = filterObj;
    } else {
      newFilters.push(filterObj);
    }
    setFilters([...newFilters]);
  };
  return (
    <>
      <div className="card-header d-flex align-items-center justify-content-between card-header-alt pr-4 pt-4 pb-4">
        <Grid container spacing={0}>
          <Grid item container xs spacing={0}>
            <Grid item xs={12}>
              <span style={{ textAlign: 'center' }}>البرنامج</span>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Select
                  labelId="demo-simple-select-standard"
                  id="demo-simple-select-standard"
                  value={selectedFilter.form_id ? selectedFilter.form_id : null}
                  name="form_id"
                  onChange={handleSelectedProgram}
                  style={{
                    height: '30px',
                    width: '75%'
                  }}>
                  {forms &&
                    forms.map((program) => (
                      <MenuItem value={program.id} key={program.id}>
                        {program.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* <Grid item xs container spacing={0}>
            <Grid item xs={12}>
              <span className="ml-1">المرحله</span>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Select
                  labelId="demo-simple-select-standard"
                  id="demo-simple-select-standard"
                  value={selectedFilter.step_id ? selectedFilter.step_id : null}
                  name="step_id"
                  onChange={handleSelectedProgram}
                  style={{
                    height: '30px',
                    width: '75%'
                  }}>
                  {reqStage &&
                    reqStage.map((step) => (
                      <MenuItem value={step.id} key={step.id}>
                        {step.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid> */}
          <Grid item xs container spacing={0}>


          </Grid>
        </Grid>
      </div>
      <div className="divider" />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    forms: state.formList.forms,
    reqStage: state.reqStageList.reqStage
    // filters: state.filter.filters
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getForms: (params) => dispatch(fetchForms(params)),
    getReqStage: (params) => dispatch(fetchReqStage(params))
    // setFilters: (data) => dispatch(setFiltersArray(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InquiryFilters);
// export default Filters;
