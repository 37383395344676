import React, { useEffect,useState } from 'react';
import './index.css';
import { Grid, Card, Button, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

export default function Program({ form }) {
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    cardStyle: {
      minHeight: '5vh',
      justifyContent: 'center',
      '&:hover': {
        color: 'white !important',
        background: form.style?.hoverColor
      }
    }
  }));
  const classes = useStyles();
  useEffect(() => {
    return () => {
      if (
        history.action === 'POP' &&
        history.location.pathname.includes('reinvestigate')
      ) {
        history.push('/worklist');
      }
    };
  },  [history.location, history.action] );
  return (
    <React.Fragment>
      <Grid item xs={12} md={4} lg={4} sm={6} className="gridStyle">
        <Card className={classes.cardStyle + ' align-items-center'}>
          <Link to={`/programs/${form.id}`}>
            <Button className="d-block  border-0 py-3 buttonStyle">
              <span className="d-block iconSize">
                <FontAwesomeIcon icon={['fa', form.style?.icon]} />
              </span>
              <span
                className={`text-uppercase font-weight-bold d-block h5 ${
                  form.name.length > 11 && 'textSize'
                }`}>
                {form.name}
              </span>
              <p className="discFontSize">{form.description}</p>
            </Button>
          </Link>
        </Card>
      </Grid>
    </React.Fragment>
  );
}
