const assginmentUsers = [
    {
        userRoles: ['research manager', 'research general manager'],
        taskUsers: ['research supervisor', 'researcher']
    },
    {
        userRoles: ['research supervisor', 'research manager'],
        taskUsers: ['researcher']
    },
    {
        userRoles: ['medical division manager'],
        taskUsers: ['medical division user']
    },
    {
        userRoles: ['social division manager'],
        taskUsers: ['social division user']
    },
    {
        userRoles: ['legal manager'],
        taskUsers: ['employee']
    },
    {
        userRoles: ['financial manager'],
        taskUsers: ['checker']
    },
    {
        userRoles: ['admin'],
        taskUsers: ['general secertary', 'excutive director']
    },
    {
        userRoles: ['administration office manager'],
        taskUsers: ['administration office employee']
    }
]
export function reAssignUsers(userRoles, taskRoles) {
    let enableAssignment = false;
    assginmentUsers.find(config => {
        if (userRoles.find(role => config.userRoles.includes(role.name))
            && taskRoles.find(user => config.taskUsers.includes(user))) {
            enableAssignment = true;
            return true;

        } 

    })
    return enableAssignment;
}