import client from '../../api/apiAuth/guestClient';
import {
  ASSIGN_TASK,
  GET_TASK_DETAILS,
  GET_TIMELINE,
  SAVE_CARD_ATTACHMENTS,
  GET_TASK_USERS,
  HANDLE_TASK_ASSIGNMENT
} from './detailsActionTypes';

export const getTaskDetails = function (params) {
  return {
    type: GET_TASK_DETAILS,
    payload: client.get('tasks/taskdetails', {
      params
    })
  };
};

export const assignTask = function (body) {
  return {
    type: ASSIGN_TASK,
    payload: client.post('workflow/handleDistrubution', body)
  };
};

// export const submitTask = function (body) {
//   return {
//     type: SUBMIT_TASK,
//     payload: client.post('workflow/submittask', body)
//   };
// };

export const getTimeline = function (params) {
  return {
    type: GET_TIMELINE,
    payload: client.get('tasks/getTimeline', { params })
  };
};

export const saveCardAttachment = function (attachment) {
  return {
    type: SAVE_CARD_ATTACHMENTS,
    payload: { attachment }
  };
};

export const getUsers = function (params) {
  return {
    type: GET_TASK_USERS,
    payload: client.get('user/getDistributedUsers', { params })
  };
};

export const handleTaskAssignment = function (params) {
  return {
    type: HANDLE_TASK_ASSIGNMENT,
    payload: client.get('tasks/handleTaskAssignment', { params })
  };
};
