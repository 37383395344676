import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  TextareaAutosize,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import DownloadButton from '../DownloadButton';
import { apiConfig } from '../../../api/utilities';
import { connect } from 'react-redux';
import { uploadStampFile } from '../../../reducers/form/formActions';
import { taskNotInStepId } from '../../../utils/helper';
import _ from 'lodash';
import { checkForEditButton } from './utils';
import ArchivingModal from '../ArchivingModal';
import client from 'api/apiAuth/guestClient';
import Swal from 'sweetalert2';
import ArchitectModal from '../ArchitectModal';
import MedicalSupplyModal from '../MedicalSupplyModal';

function DetailsButtons({
  setModalInfo,
  setConfirmationModal,
  history,
  setFormModal,
  setStepInputModal,
  task,
  submissionId,
  user,
  uploadStampFile,
  loading
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openArchitectDialog, setOpenArchitectDialog] = useState(false);
  const [openMedicalSupplyDialog, setOpenMedicalSupplyDialog] = useState(false);
  const [comment, setComment] = useState('');
  const [file, setFile] = useState({});
  const fileInput = useRef(null);
  const handleAssignClick = (e) => {
    setModalInfo({
      question: 'هل تريد تعيين نفسك على هذا الطلب؟',
      message: 'اذا قمت بتعيين نفسك عليها فيجب عليك مراجعتها'
    });
    setConfirmationModal(true);
  };
  const handleClickFormModal = () => {
    setFormModal(true);
  };
  const handleSetComment = (e) => {
    setComment(e.target.value);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const openDialoge = () => {
    setOpenDialog(true);
  };
  const handleClickStepInputModal = () => {
    setStepInputModal(true);
  };
  const handleStepInputButton = (step) => {
    let buttonName;
    step?.groups.forEach((group) => {
      if (!_.isEmpty(group.rules)) {
        group.rules.map((rule) => {
          if (rule.function_name == 'getStepInputs') buttonName = group.name;
        });
      }
    });
    return buttonName;
  };
  // archive
  const handleِArchiveDialogClose = () => {
    setOpenArchiveDialog(false);
  };
  const handleOpendArchiveDialog = () => {
    setOpenArchiveDialog(true);
  };
  // architect
  const handleِArchitectDialogClose = () => {
    setOpenArchitectDialog(false);
  };
  const handleOpendArchitectDialog = () => {
    setOpenArchitectDialog(true);
  };
  // assistant secretary general for medical supply
  const handleMedicalSupplyDialogClose = () => {
    setOpenMedicalSupplyDialog(false);
  };
  const handleOpenMedicalSupplyDialog = () => {
    setOpenMedicalSupplyDialog(true);
  };
  const handleReterievingArchivedSubmission = async () => {
    await client.post(
      `submission/reterieveArchivedSubmission?submissionId=${task.submission.id}&archiveId=${task.submission.archivingDetails[0].id}`
    );
    await Swal.fire({
      text: 'حسنا',
      title: 'لقد تم ارجاع الطلب بنجاح',
      icon: 'success',
      timer: 2000,
      showCancelButton: false,
      showConfirmButton: false
    });
    window.location.reload();
  };
  const fileDownload = useRef(null);
  const excelDownload = useRef(null);
  const downloadTransfer = useRef(null);
  const handleDocumentChange = (event) => {
    event.persist();
    // let documentName = event.target.name,
    let file = event.target.files[0],
      input_id = parseInt(event.target.dataset.document);

    let document = {
      data: file,
      submission_id: task?.submission.id,
      original_file_name: file?.name,
      input_id,
      type: file?.type
    };
    setFile(document);
  };

  useEffect(() => {
    if (file?.data) {
      uploadStampFile(file).catch((error) => {
        alert('من فضلك ارفع الملف مرة اخري');
      });
    }
  }, [file]);

  const checkStampFlag = task?.submission.attachments.some(
    (att) => att.input_id === 21
  );
  return (
    <>
      {task?.submission.attachments.some((e) => !e.input_id) && (
        <>
          {user?.roles?.some((role) =>
            [
              'excutive director'
            ].includes(role.name)
          ) &&
            [
              'Approved',
            ].includes(task?.submission.status) && (
              <DownloadButton
                buttonLabel={' تحميل نموذج الموافقة'}
                btnHref={`${apiConfig.baseUrl}attachment/downloadtransfer/?submissionId=${submissionId}`}
                btnRef={fileDownload}
                btnClasses={'btn-transition-none mb-4 btn-lg'}
                btnStyle={{
                  backgroundColor: '#276e21',
                  color: 'white'
                }}
              />
            )}

          {checkStampFlag && (
            <Button
              disabled={checkStampFlag}
              className={`btn btn-primary btn-transition-none d-inline`}
              disableFocusRipple
              disableRipple
              onClick={() => fileInput.current.click()}>
              {'تم رفع الموافقة بالختم'}
            </Button>
          )}
        </>
      )}
      {task?.submission.haveAccessToSubmission && (
        <DownloadButton
          buttonLabel={'طباعة الملف'}
          btnHref={`${apiConfig.baseUrl}attachment/generateTaskPdf?submissionId=${submissionId}`}
          btnRef={excelDownload}
          btnClasses={'mb-4 btn-lg float-right ml-2'}
          btnStyle={{ backgroundColor: '#3c44b1', color: 'white' }}
        />
      )}

      {checkForEditButton(user.roles, task?.step_id) &&
        task?.submission.status == 'Pending' &&
        task.is_assigned &&
        task?.users[0].username == user.username && (
          <Button
            className="btn-transition-none mb-4 btn-lg edit-btn float-right ml-2"
            variant="contained"
            color="primary"
            onClick={(e) => {
              let redirectedUrl = `/programs/${task.submission.form_id}/submissions/${task.submission_id}`;
              redirectedUrl += '/edit';
              history.push(redirectedUrl);
            }}>
            {' تعديل ملف'}
          </Button>
        )}

      {task?.submission?.status != 'Archived' &&
        !task?.viewOnly &&
        taskNotInStepId(task, [3]) && (
          <>
            {!task?.is_assigned || !task?.user.id === user?.id ? (
              <Button
                variant="contained"
                className="btn-transition-none mb-4 btn-lg float-right ml-2"
                disabled={task?.is_assigned}
                onClick={handleAssignClick}>
                {'تعيين نفسى'}
              </Button>
            ) : (
              <Button
                variant="contained"
                className="btn-transition-none mb-4 btn-lg  float-right ml-2"
                onClick={handleClickFormModal}
                color="primary">
                {'أتخاذ اجراء'}
              </Button>
            )}
          </>
        )}

      {((task?.is_assigned &&
        task?.step.groups.filter((group) => {
          return group.rules.some((rule) => rule.section_id);
        }).length > 0 &&
        !_.isEmpty(
          task?.step.assignees.filter((assignee) =>
            user.roles.some((role) => role.name == assignee.role)
          )
        )) ||
        (user?.roles?.some((role) => role.name == 'general secertary') &&
          ['Pending'].includes(task?.submission.status) &&
          !_.isEmpty(
            task?.step.groups.filter((group) => {
              if (!_.isEmpty(group.rules)) {
                return (
                  group.rules.filter((rule) => rule.section_id) &&
                  group.group_step.step_id == 28
                );
              } else {
                return '';
              }
            })
          ))) &&
        task?.submission?.status != 'Archived' && (
          <Button
            className="btn-transition-none btn-lg float-right mr-2"
            variant="contained"
            color="primary"
            // disabled={task?.is_assigned}
            onClick={handleClickStepInputModal}>
            {handleStepInputButton(task?.step)}
          </Button>
        )}

      {(
        (task?.step.groups.find((group) => group.id == 16) &&
          user?.roles?.some((role) =>
            [
              'general secertary assistant medical',
              'medical division manager',
              'medical division user'
            ].includes(role.name)) && task?.is_assigned)
        ||
        (task?.submission.status == 'Approved' &&
          task?.submission.form_id == 1 &&
          user?.roles?.some((role) =>
            [
              'general secertary assistant medical',
              'medical division manager',
              'medical division user'
            ].includes(role.name)
          )))
        &&
        !_.isEmpty(task?.submission.values.requestPurpose) && (
          <Button
            className="btn-transition-none  btn-lg "
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#fcae31' }}
            onClick={openDialoge}>
            {'اصدار خطاب تحويل'}
          </Button>
        )}

      {((task?.step.groups.find((group) => group.id == 27) &&
         task?.submission.status == 'Approved' &&
         user?.roles?.some((role) => [ 'super admin' ].includes(role.name) ))) &&
        !_.isEmpty(task?.submission.values.requestPurpose) 
        && (
          <Button
            className="btn-transition-none btn-lg float-right mr-2"
            variant="contained"
            color="primary"
            // disabled={task?.is_assigned}
            onClick={handleClickStepInputModal}>
            {handleStepInputButton(task?.step)}
         </Button>
        )}

      {task?.is_assigned &&
        task?.user?.id === user?.id &&
        (task?.submission?.archivingDetails?.length == 0 ? (
          <div>
            <Button
              className="btn-transition-none mb-4 btn-lg danger-btn float-right ml-2"
              variant="contained"
              color="primary"
              onClick={handleOpendArchiveDialog}>
              وقف الطلب لبعض الوقت
            </Button>
          </div>
        ) : (
          <div>
            <Button
              className="btn-transition-none mb-4 btn-lg green-btn float-right ml-2"
              variant="contained"
              color="primary"
              onClick={handleReterievingArchivedSubmission}>
              ارجاع الطلب من اﻻرشيف
            </Button>
          </div>
        ))}
      <ArchivingModal
        openArchiveDialog={openArchiveDialog}
        handleِArchiveDialogClose={handleِArchiveDialogClose}
        submissionId={task?.submission.id}
        stepId={task?.step_id}
      />

      {(['Approved','Pending'].includes(task?.submission.status) &&
          user?.roles?.some((role) => [ 'architect' ].includes(role.name) &&
          task?.submission.form_id === 9
        )) 
          && (
            <div>
            <Button
              className="btn-transition-none btn-lg float-right mr-2"
              variant="contained"
              color="primary"
              onClick={handleOpendArchitectDialog}
            >
                اضافة تعليقات / مرفقات المهندس المعماري
            </Button>
          </div>
      )}
      <ArchitectModal
        openArchitectDialog={openArchitectDialog}
        handleِArchitectDialogClose={handleِArchitectDialogClose}
        stepId={task?.step_id}
        task={task}
        submission={task?.submission}
        user={user}
      />

      {task?.step.groups.find((group) => group.id == 27) &&
       task?.step_id == 36 &&
       task?.submission.status == 'Pending' &&
       user?.roles?.some((role) => ['assistant secretary general for medical supply'].includes(role.name)) &&
       !_.isEmpty(task?.submission.values.requestPurpose) && (
          <Button
            className="btn-transition-none btn-lg float-right mr-2"
            variant="contained"
            color="primary"
            // disabled={task?.is_assigned}
            onClick={handleClickStepInputModal}>
            {handleStepInputButton(task?.step)}
          </Button>
      )}
      {['Pending'].includes(task?.submission.status) &&
        user?.roles?.some(
          (role) =>
            ['assistant secretary general for medical supply'].includes(
              role.name
            ) &&
            task?.submission.form_id === 1 &&
            task?.step_id == 36
        ) && (
          <div>
            <Button
              className="btn-transition-none btn-lg float-right mr-2"
              variant="contained"
              color="primary"
              onClick={handleOpenMedicalSupplyDialog}>
              اضافة تعليقات مساعد امين عام للإمداد الطبي
            </Button>
          </div>
        )}
      <MedicalSupplyModal
        openMedicalSupplyDialog={openMedicalSupplyDialog}
        handleِMedicalSupplyDialogClose={handleMedicalSupplyDialogClose}
        stepId={task?.step_id}
        task={task}
        submission={task?.submission}
        user={user}
      />

      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ملاحظات
          </DialogContentText>
          <TextareaAutosize
            value={comment}
            onChange={handleSetComment}
            rowsMin={'5'}
            cols={'125'}
            style={{ width: '100%', border: ' 1px solid #e4e5eb ' }}
          />
          <DownloadButton
            buttonLabel={'اصدار و تحميل'}
            btnHref={
              comment
                ? `${apiConfig.baseUrl}attachment/downloadtransfer/?submissionId=${submissionId}&comment="${comment}"&service=true`
                : `${apiConfig.baseUrl}attachment/downloadtransfer/?submissionId=${submissionId}&service=true`
            }
            btnRef={downloadTransfer}
            btnClasses={'btn-transition-none  btn-lg '}
            btnStyle={{ backgroundColor: '#fcae31' }}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loading: state.form.loading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadStampFile: (file) => dispatch(uploadStampFile(file))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailsButtons);
