import { Card, CardHeader, Divider, Grid } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';
import './index.css';
import Timeline from '../Timeline';


export default function TaskTimeline({ timeline }) {
  const timelineRef = useRef(null);
  const [slicedTasks, setSlicedTasks] = useState([]);
  const updateWindowDimensions = () => {
    setTimeout(() => {
      let cardWidth = timelineRef.current?.clientWidth;
      let sliceWidth = Math.floor(cardWidth / 300);
      let slice = 4;
      let arr = [];
      sliceWidth ? (slice = sliceWidth) : slice;
      let index = 0;
      for (let i = 0; i < timeline.length; i += slice) {
        arr[index] = timeline.slice(i, i + slice);
        index++;
      };
      setSlicedTasks(arr);
    }, 550);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, [timeline]);

  return (
    <Grid container spacing={0} className="mb-5" ref={timelineRef}>
      <Grid item md={12}>
        <Card className="card-box">
          <Grid item sm={12}>
            <span className="tab-card-title">{'تاريخ الطلب'}</span>
          </Grid>
          <Divider />
          <div className="timeline-list timeline-list-horizontal timeline-list--primary mt-5">
            <div className="d-flex w-100 px-5 py-0 flex-wrap">
              {slicedTasks.map((time, index) => (
                <Timeline item={time} index={index} key={index}></Timeline>
              ))}
            </div>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
