import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextareaAutosize,
  DialogTitle
} from '@material-ui/core';
import { getValue } from 'utils/GetObjectValues';
import client from 'api/apiAuth/guestClient';
import Swal from 'sweetalert2';
import InputWidget from 'components/FormSteps/InputWidget';

function MedicalSupplyModal({
  openMedicalSupplyDialog,
  handleِMedicalSupplyDialogClose,
  stepId,
  task,
  submission,
  user,
  requestsNo
}) {

  const [initialValues, setInitialValues] = useState({});

  const formik = useFormik({
    validationSchema: Yup.object({
      comments: Yup.string().required('هذا الحقل مطلوب'),
      // docs: Yup.string().required('هذا الحقل مطلوب')
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmission();
    }
  });

  const handleSubmission = async () => {
    if (requestsNo > 0) {
      Swal.fire({
        title: 'Please wait ',
        text: `Please wait while some files are uploaded`,
        icon: 'info',
        confirmButtonColor: '#d36467',
        dangerMode: true
      });
      return;
    } else {

    let values = { ...formik.values };

    const newCommentObj = values.comments ? {
      comment: values.comments,
      author: user.username,
      user_id: user.id,
      date: new Date(),
      step: task.step.name,
    } : {};

    if (task.submission.values.medicalSupplyAssistant?.comments && !_.isEmpty(newCommentObj)) {
      values.comments = [...task.submission.values.medicalSupplyAssistant.comments, newCommentObj]
    } else if (task.submission.values.medicalSupplyAssistant?.comments) {
      values.comments = [...task.submission.values.medicalSupplyAssistant.comments]
    } else if (!_.isEmpty(newCommentObj)) {
      values.comments = [newCommentObj]
    }


    let { data } = await client.post('submission/updateSubmissionValues', {
      submission_id: submission.id,
      section_id: 48,
      task_id: task.id,
      submission_edit: { 'medicalSupplyAssistant': values },
    //   attachmentReviewed
    });

    handleِMedicalSupplyDialogClose(false);
    if (data.info == 'fail') {
      Swal.fire({
        title: 'ﻻ يمكن تنفيذ هذا الاجراء ',
        text: data.message,
        icon: 'error',
        timer: 3000,
        dangerMode: true
      });
    } else {
      await Swal.fire({
        text: 'حسنا',
        title: data.message,
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false
      });
      window.location.reload();
    }
  }
  };


  return (
    <Dialog
      open={openMedicalSupplyDialog}
      onClose={handleِMedicalSupplyDialogClose}
      aria-labelledby="medical-supply-dialog"
      // disableEnforceFocus
      aria-describedby="medical-supply-dialog"
      maxWidth="md"
    >
      <DialogContent >
        <DialogTitle style={{ textAlign: 'center', borderBottom: 'none' }}>
          تعليقات مساعد الامين العام للإمداد الطبي  
        </DialogTitle>

        {/* <DialogContentText id="medical-supply-dialog-comments" className='mt-5' style={{ textAlign: 'center' }}>
          تعليقات
        </DialogContentText> */}
        <TextareaAutosize
          value={formik.values.comments}
          name="comments"
          onChange={(ev, value) => {
            formik.handleChange(ev, value);
          }}
          onBlur={(e) => {
            formik.handleBlur(e);
          }}
          rowsMin={'5'}
          cols={'125'}
          style={{ width: '100%', border: ' 1px solid #e4e5eb ' }}
        />

        <div className="text-danger">
          {getValue(formik.errors, 'comments')}
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          className="btn-transition-none mb-4 btn-lg float-right ml-2"
          variant="contained"
          color="primary"
          type="submit"
          onClick={(e) => formik.handleSubmit(e)}>
          تم
        </Button>
      </DialogActions>
    </Dialog>
  );

}
const mapStateToProps = (state) => {
  return {
    requestsNo: state.general.requestsNo
  };
}
export default connect(mapStateToProps)(MedicalSupplyModal);