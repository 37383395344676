import React, { useState, useEffect, useRef } from 'react';
import { Button, FormControl, OutlinedInput, Grid, Select, MenuItem, InputLabel, FormHelperText } from '@material-ui/core';
import './style.css';
import client from '../../api/apiAuth/guestClient';
import InquiryModal from '../../components/InquiryModal';
import Tables13 from '../../components/InquiryTable';
import { fetchWorklistLayout } from '../../reducers/list';
import { connect } from 'react-redux';
import Paragraph from 'antd/lib/typography/Paragraph';

function Inquiry(props) {
  let [open, setOpen] = useState(false);
  let [inquiryValue, setInquiryValue] = useState('');
  let [errorMessage, setErrorMessage] = useState('');
  let [reqPurpose, setReqPurpose] = useState({});
  let [filtersFillInp, setFiltersFillInp] = useState(false);

  let { tasks, getLayout, columns, user } = props;
  const [layoutStructure, setLayoutStructure] = useState([]);
  const [layoutData, setLayoutData] = useState();
  const [filters, setFilters] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [currentTab, setCurrentTab] = useState(17);
  const [totalPurposesCount, setTotalPurposesCount] = useState(1);
  const [filtersObj, setFiltersObj] = useState({});
  const [submitTaskLoader, setSubmitTaskLoader] = useState(false);
  const [sort, setSort] = useState({ type: '', order: 'none', orderBy: '' });
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const excelDownload = useRef(null);
  const [inquiryType, setInquiryType] = useState("");
  const [displayTable, setDisplayTable] = useState(false)
  let param = {};
  useEffect(() => {
    getLayout({ model: 'Task' }).catch((err) => { });
  }, []);

  useEffect(() => {
    setLayoutStructure(columns);
  }, [tasks, columns]);

  useEffect(() => {
    setPage(1);
  }, [filters, limit, sort]);

  useEffect(() => {
    if (inquiryValue) {
      handleApi({ ...filtersObj, page });
    }
  }, [filters, page, limit, sort]);

  useEffect(() => {
    setDisplayTable(false)
  }, [inquiryType])

  const handleClick = (e) => {
    switch (inquiryType) {
      case "submission": {
        param.name = "submission"
        param.value = inquiryValue;
        handleApi(param, false, { page: 1 });
        setFiltersFillInp(!filtersFillInp);
        setDisplayTable(true)
        setError(false);
        break;
      }
      case "nationalId": {
        param.name = "nationalId"
        param.value = inquiryValue
        handleApi(param, false, { page: 1 });
        setFiltersFillInp(!filtersFillInp);
        setDisplayTable(true)
        setError(false);
        break;
      }
      default: {
        setHelperText('من فضلك قم باختيار نوع الاستعلام');
        setError(true);
        break;
      }
    }
  };
  const handleChange = (e) => {
    setInquiryValue(e.target.value);
    setReqPurpose();
  };

  const handleSelectedInquiry = e => {
    setInquiryType(e.target.value)
    setReqPurpose([]);

  }

  const handleApi = async (param, obj) => {
    if (inquiryValue && !obj) {
      const result = await client.get('/tasks/inquiry', {
        params: {
          ...param,
          value: inquiryValue,
          name: inquiryType,
          page,
          limit
        }
      });
      if (result.data.success) {
        setReqPurpose(result.data.inquiry);
        setTotalPurposesCount(result.data.count);
      } else {
        setErrorMessage(result.data.message);
        setReqPurpose([]);
        setTotalPurposesCount(1);
      }
    } else if (obj && (param.form_id || param.step_id)) {
      const result = await client.get('/tasks/inquiry', {
        params: {
          ...param,
          value: inquiryValue,
          name: "submission",
          page,
          limit
        }
      });
      if (result.data.success) {
        setReqPurpose(result.data.inquiry);
        setTotalPurposesCount(result.data.count);
      } else {
        setErrorMessage(result.data.message);
        setReqPurpose([]);
        setTotalPurposesCount(1);
      }
    }
  };

  const handleFilter = (ObjFilter) => {
    setFiltersObj(ObjFilter);
    handleApi(ObjFilter, true);
  };

  return (
    <div style={{ flexGrow: '1' }}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        style={{ minHeight: '50vh' }}>
        <OutlinedInput
          type='text'
          placeholder=" من فضلك قم بادخال الرقم القومي/رقم الباسبور  "
          aria-describedby="outlined-weight-helper-text"
          name="inquiryValue"
          value={inquiryValue}
          onChange={handleChange}
          labelWidth={0}
          // fullWidth
          style={{ height: '40px', width: '40%', margin: '20px 10px 0' }}
        />
        <FormControl required labelWidth={0} style={{ height: '40px', width: '20%', margin: '10px 10px 0' }} error={error}>
          <InputLabel>نوع الاستعلام</InputLabel>
          <Select
            labelId="demo-simple-select-standard"
            id="demo-simple-select-standard"
            name="inquiry_type"
            onChange={handleSelectedInquiry}
          >
            <MenuItem value={"nationalId"}>
              ملف شخصي
            </MenuItem>
            <MenuItem value={"submission"}>
              طلب
            </MenuItem>
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
        <Button
          style={{ height: '40px', margin: '20px 10px 0' }}
          className="btn-transition-none inquiry-btn"
          variant="contained"
          color="primary"
          onClick={handleClick}>
          {'استعلام جديد'}
        </Button>
        <Grid className="mt-4" style={{ width: '90%' }}>
          <Tables13
            className="mt-4"
            layoutStructure={layoutStructure}
            setLayoutStructure={setLayoutStructure}
            layoutData={reqPurpose}
            filtersFillInp={filtersFillInp}
            currentTab={currentTab}
            setPage={setPage}
            limit={limit}
            totalPurposesCount={totalPurposesCount}
            handleFilter={handleFilter}
            page={page}
            inquiryType={inquiryType}
          />
        </Grid>
      </Grid>
      <InquiryModal
        open={open}
        setOpen={setOpen}
        submission={reqPurpose}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setSubmission={setReqPurpose}
        setNationalId={setInquiryValue}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    tasks: state.list?.tasks,
    tasksTotalCount: state.list?.tasksCount,
    user: state.auth.user,
    currentUser: state.auth.user,
    columns: state.layout.columns
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(fetchWorklistLayout(params))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Inquiry);
