import { createReducer } from '@reduxjs/toolkit';
import {
  SET_ONLINE_STATUS,
  SET_IS_LISTENING,
  SET_TOAST_LAST_DISPLAY
} from './statusActionTypes';

const initialState = {
  connectionStatus: null,
  lastToastDisplayDate: null,
  isListening: false
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(SET_ONLINE_STATUS, (state, action) => {
    state.connectionStatus = action.payload;
  });
  builder.addCase(SET_IS_LISTENING, (state, action) => {
    state.isListening = action.payload;
  });
  builder.addCase(SET_TOAST_LAST_DISPLAY, (state, action) => {
    state.lastToastDisplayDate = action.payload;
  });
});

export default reducer;
