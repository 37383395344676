import {
  FETCH_FORMS_PENDING,
  FETCH_FORMS_FULFILLED,
  FETCH_FORMS_REJECTED
} from './listActionTypes';

const initialState = {
  error: null,
  forms: [],
  loading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FORMS_PENDING: {
      return {
        ...state,
        loading: true
      };
    }
    case FETCH_FORMS_FULFILLED: {
      return {
        ...state,
        loading: false,
        forms: action.payload.data.results?.rows
      };
    }
    case FETCH_FORMS_REJECTED: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
}
