class Schema {
  constructor() {}
  setInteger() {}
  setString() {}
  setBoolean() {}
  setDate() {}
  setEnum() {}
  setObject() {}
  setArray() {}
  setRequired() {}
  extendObject() {}
  setMinDate() {}
  getArrayItemSchema() {}
  setConditionalSchema() {}
  getAttachmentSchema() {}
}
export default Schema;
