import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { Grid, Card, List, ListItem } from '@material-ui/core';
import FilesAccordion from '../FilesAccordion';
import GridCards from '../GridCard';
import _ from 'lodash';
import styles from './index.module.css';

export default function Tabs({ tabs, setPdfModals, pdfModals, serverUrl, submission }) {

  const [activeTab, setActiveTab] = useState(0);
  const [cardTabs, setCardTabs] = useState([]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const allFilesReviewed = (tab) => {
    if (tab.attachments && tab.attachments.length > 0) {
      for (const attachment of tab.attachments) {
        if (!attachment.data.is_reviewed) return false;
      }
    }
    return true;
  };
  useEffect(() => {
    let filteredTabs = [];
    tabs.forEach((tab) => {
      let tabCards = tab.cards.filter((card) => {
        return (
          card.attrs?.filter((attr) =>
            Array.isArray(card.form)
              ? card.form.filter(
                  (value) => !_.isEmpty(value) && attr.key in value
                ).length > 0
              : !_.isEmpty(card.form) && attr.key in card.form
          )?.length > 0
        );
      });
      tab.cards = tabCards;
      if (tabCards.length > 0) filteredTabs.push(tab);
    });
    // let popoverTabs = tabs.filter(tab => tab.popoverContent)
    setCardTabs(filteredTabs);
  }, [tabs]);
  return (
    <Grid container spacing={0} className="mb-5 rounded">
      <Grid item xs={12}>
        <List className="nav-tabs nav-tabs-primary d-flex">
          {cardTabs.map((tab, i) => (
            <ListItem
              button
              disableRipple
              key={i}
              selected={activeTab === i}
              onClick={() => {
                toggle(i);
              }}>
              <span
                className={`${
                  allFilesReviewed(tab) ? null : styles['tab--errored']
                }`}>
                {tab?.title}
              </span>
              <div className="divider" />
            </ListItem>
          ))}
        </List>
        <Card className="card-box" style={{ boxShadow: 'none' }}>
          {cardTabs?.map((tab, i) => (
            <div
              key={i}
              className={
                'mb-0 ' +
                clsx('tab-item-wrapper', {
                  active: activeTab === i
                })
              }
              index={i}>
              <Grid
                container
                spacing={0}
                style={{ alignItems: 'center', justifyContent: 'center' }}
                className="text-center">
                <React.Fragment>
                  <GridCards
                    cards={tab?.cards}
                    style={{ borderRadius: 0, boxShadow: 'none' }}
                  />
                </React.Fragment>
              </Grid>
              {tab.attachments && tab.attachments.length > 0 && (
                <FilesAccordion
                  attachments={tab.attachments}
                  type={tab.accordionType}
                  serverUrl={serverUrl}
                  pdfModals={pdfModals}
                  setPdfModals={setPdfModals}
                  attachmentsKey={tab.attachmentsKey}
                  onAttachmentReviewed={tab.onAttachmentReviewed}
                  submission={submission}
                />
              )}
            </div>
          ))}
        </Card>
      </Grid>
    </Grid>
  );
}
