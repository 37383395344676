import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Button } from '@material-ui/core';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';

export default function ConfirmModal({ modal, setModal, info, callBack }) {
  const toggle = () => setModal(!modal);
  return (
    <Dialog
      open={modal}
      onClose={toggle}
      classes={{ paper: 'shadow-sm-dark rounded-sm' }}>
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
            <FontAwesomeIcon
              icon={faKeyboard}
              className="d-flex align-self-center display-3"
            />
          </div>
        </div>
        <h4 className="font-weight-bold mt-4">{info.question}</h4>
        <p className="mb-0 text-black-50">{info.message}</p>
        <div className="pt-4">
          <Button
            onClick={toggle}
            className="btn-neutral-secondary btn-pill text-danger mx-1">
            <span className="btn-wrapper--label">لا</span>
          </Button>
          <Button
            onClick={() => {
              callBack();
              toggle();
            }}
            className="btn-first btn-pill mx-1">
            نعم
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
