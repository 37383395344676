import React, { useState, useEffect } from 'react';

import Tables13 from 'components/Table';

import {
  fetchWorklist,
  fetchWorklistLayout,
  setLastTab
} from '../../reducers/list';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import './style.css';
import TableTabs from 'components/TableTabs';

function ArchivedSubmissions(props) {
  let { getWorklist, tasks, tasksTotalCount, getLayout, columns, user } = props;
  const [layoutStructure, setLayoutStructure] = useState([]);
  const [layoutData, setLayoutData] = useState();
  const [filters, setFilters] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [sort, setSort] = useState({ type: '', order: 'none', orderBy: '' });
  const [currentTab, setCurrentTab] = useState(30);
  const [activeTab, setActiveTab] = useState(currentTab);
  let currentRole = localStorage.getItem('userRole');
  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  useEffect(() => {
    setLayoutStructure(columns);
    setLayoutData(tasks);
  }, [tasks, columns]);

  useEffect(() => {
    setPage(1);
  }, [filters, limit, sort]);

  useEffect(() => {
    getWorklist({
      filters,
      page,
      limit,
      sort,
      currentTab: activeTab,
      currentRole
    });
  }, [filters, page, limit, sort, activeTab]);

  useEffect(() => {
    getLayout({ model: 'Task' }).catch((err) => {});
  }, []);

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} xs={12} className="mt-1">
          <>
            <TableTabs
              setFilters={setFilters}
              setSort={setSort}
              setCurrentTab={setCurrentTab}
              setLayoutData={setLayoutData}
              lastTab={activeTab}
              setLastTab={setLastTab}
              currentTab={currentTab}
              user={user}
              currentRole={currentRole}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tableName={'archivedSubmissions'}
            />
            <div
              className={clsx('tab-item-wrapper', {
                active: true
              })}
              style={{ overflow: 'hidden' }}
              index={0}>
              <Tables13
                className="mt-4"
                layoutStructure={layoutStructure}
                setLayoutStructure={setLayoutStructure}
                layoutData={layoutData}
                currentTab={currentTab}
                setPage={setPage}
                setLimit={setLimit}
                limit={limit}
                setSort={setSort}
                tasksCount={tasksTotalCount}
                filters={filters}
                setFilters={setFilters}
                page={page}
                applyFilter={true}
                currentRole={currentRole}
                activeTab={activeTab}
              />
            </div>
          </>
        </Grid>
      </Grid>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    tasks: state.list?.tasks,
    tasksTotalCount: state.list?.tasksCount,
    user: state.auth.user,
    columns: state.layout.columns,
    lastTab: state.layout.lastTab
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWorklist: (params) => dispatch(fetchWorklist(params)),
    getLayout: (params) => dispatch(fetchWorklistLayout(params)),
    setLastTab: (params) => dispatch(setLastTab(params))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArchivedSubmissions);
