import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
const initialState = {
  auth: { user: JSON.parse(localStorage.getItem('user')) }
};
const store = configureStore({
  reducer: rootReducer,
  middleware: [promise, thunk],
  devTools: true,
  preloadedState: initialState
});
store.subscribe(() => {
  const {
    auth: { user }
  } = store.getState();
  let currentUser = JSON.stringify(user);
  currentUser != 'null' &&
    currentUser !== undefined &&
    localStorage.setItem('user', currentUser);
});
export default store;
