import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';

import { ThemeProvider } from '@material-ui/styles';

// Layout Blueprints

import { LeftSidebar, MinimalLayout } from './layout-blueprints';

// Example Pages

import PageError404 from './example-pages/PageError404';
import PageError500 from './example-pages/PageError500';
import PageError505 from './example-pages/PageError505';

// project pages
import WorkList from './pages/WorkList';
import Login from './pages/Login';

import PrivateRoute from './utils/PrivateRoute';
import CreateSubmission from './pages/CreateSubmission';
import Inquiry from './pages/Inquiry';
import Programs from './pages/Programs';
import Dashboard from './pages/Dashboard';
import UserControlPanel from './pages/UserControlPanel';
import SubmissionStorage from './pages/SubmissionStorage';
import { connect } from 'react-redux';
import { setIsLoggedIn, logout } from './reducers/auth';

//rtl
import MuiTheme from './theme';
import Details from './pages/Details';
import Grid from './pages/Grid';
import client from 'api/apiAuth/guestClient';
import ArchivedSubmissions from 'pages/ArchivedSubmissions';

// example pages

const Test = lazy(() => import('./components/test/Test'));

const Routes = ({ auth, logout, isLoggedin, clearStore }) => {
  const location = useLocation();
  const [userIsActive, setUserIsActive] = useState(true);
  const [timerInterval, setTimerInterval] = useState('');
  function getTimeSpentOnSite() {
    let timeSpentOnSite = parseInt(localStorage.getItem('timeSpentOnSite'));
    timeSpentOnSite = isNaN(timeSpentOnSite) ? 0 : timeSpentOnSite;
    return timeSpentOnSite;
  }
  const saveTimeSpent = async () => {
   // await client.post('user/saveUsersLog', {
   //   timeSpent: parseInt(timeSpentOnSite / 1000)
   // });
  };
  let idleTime;
  let timer;
  let timerStart;
  let timeSpentOnSite = getTimeSpentOnSite();
  const { user } = auth;
  useEffect(() => {
    if (isLoggedin && (!user || user == 'undefined' || user == 'null')) {
      logout();
    }
    if (!isLoggedin) {
      localStorage.removeItem('user');
      clearStore();
    }
  }, [isLoggedin]);
  function startCounting() {
    timerStart = Date.now();
    timer = setInterval(function () {
      timeSpentOnSite = getTimeSpentOnSite() + (Date.now() - timerStart);
      localStorage.setItem('timeSpentOnSite', timeSpentOnSite);
      timerStart = parseInt(Date.now());
    }, 1000);
    setTimerInterval(timer);
  }
  useEffect(() => {
    let idleInterval;
    const onFocus = () => {
      idleTime = 0;
      setUserIsActive(true);
      clearTimeout(idleInterval);
      idleInterval = setTimeout(function () {
        idleTime = idleTime + 1;
        if (idleTime >= 1) {
          setUserIsActive(false);
        }
      }, 60000);
    };
    const onBlur = () => {
      setUserIsActive(false);
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    window.addEventListener('mousemove', () => {
      idleTime = 0;
      onFocus();
    });
    window.addEventListener('visibilitychange', () => {
      onBlur();
    });
    if (user) onFocus();
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [user]);
  useEffect(() => {
    if (!userIsActive) {
      idleTime = 0;
      clearInterval(timerInterval);
      localStorage.removeItem('timeSpentOnSite');
      saveTimeSpent();
    } else {
      startCounting();
    }
  }, [userIsActive]);
  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);

      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
              style={{ height: '100%' }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the live preview examples
                  <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/inquiry" />

            <Route
              path={[
                '/WorkList',
                '/inquiry',
                '/programs',
                '/details/:id',
                '/datagrid/:currentTable',
                '/drafts',
                '/dashboard',
                '/users',
                '/storage',
                '/archivedSubmissions'
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    {/* our routes */}
                    <Route path="/Test" component={Test} />
                    <PrivateRoute path="/workList" component={WorkList} />
                    <PrivateRoute path="/details/:id" component={Details} />
                    <PrivateRoute
                      path="/datagrid/:currentTable"
                      component={Grid}
                    />
                    <PrivateRoute path="/inquiry" component={Inquiry} />
                    <PrivateRoute
                      exact
                      path="/programs/:formId/:id?/"
                      component={CreateSubmission}
                    />
                    <PrivateRoute
                      exact
                      path="/programs/:formId/submissions/:submissionId/edit"
                      component={CreateSubmission}
                    />
                    <PrivateRoute
                      exact
                      path="/programs/:formId/submissions/:submissionId/reinvestigate"
                      component={CreateSubmission}
                    />

                    <PrivateRoute exact path="/programs" component={Programs} />
                    <PrivateRoute exact path="/drafts" component={WorkList} />
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute
                      exact
                      path="/users"
                      component={UserControlPanel}
                    />
                    <PrivateRoute
                      exact
                      path="/storage"
                      component={SubmissionStorage}
                    />
                    <PrivateRoute
                      path="/archivedSubmissions"
                      component={ArchivedSubmissions}
                    />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
            <Route
              path={[
                '/PageError404',
                '/PageError500',
                '/PageError505',
                '/login'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                    style={{ height: '100%' }}>
                    <Route path="/PageError404" component={PageError404} />
                    <Route path="/PageError500" component={PageError500} />
                    <Route path="/PageError505" component={PageError505} />
                    {!auth.isLoggedin ? (
                      <Route path="/login" component={Login} />
                    ) : (
                      <Redirect to="/worklist" />
                    )}
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};
function mapStateToProps(state) {
  return { auth: state.auth, isLoggedin: state.auth.isLoggedin };
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    clearStore: () => dispatch({ type: 'RESET_STORE', payload: null }),
    setIsLoggedIn: () => dispatch(setIsLoggedIn())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
