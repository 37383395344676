import {
  INCREASE_REQUESTS_NO,
  DECREASE_REQUESTS_NO,
  SET_REQUESTS_TOKEN
} from './generalActionTypes';

export const IncreaseRequestsNo = function () {
  return {
    type: INCREASE_REQUESTS_NO
  };
};
export const DecreaseRequestsNo = function () {
  return {
    type: DECREASE_REQUESTS_NO
  };
};

export const SetRequestsTokens = (params) => {
  return {
    type: SET_REQUESTS_TOKEN,
    payload: params
  };
};
