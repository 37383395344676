import React, { useState, useEffect } from 'react';

import {
  Button,
  createMuiTheme,
  Dialog,
  DialogContent,
  Grid,
  ThemeProvider
} from '@material-ui/core';
import { connect } from 'react-redux';
import fontawesome from '@fortawesome/fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessModal from '../SucessModal';
import TransitionForm from './TransitionForm';
import SetYupValidationSchema from '../../utils/SetYupValidationSchema';
import { red } from '@material-ui/core/colors';
import './style.css';
import _ from 'lodash';

fontawesome.library.add(faCaretLeft, faCaretRight);

function FormModal(props) {
  const {
    open,
    setOpen,
    transitions,
    task_id,
    submission,
    submissionAttachments,
    assignTasksBeforeSubmit,
    lastComment,
    taskStepId,
    modalSize
  } = props;
  const [successModal, setSuccessModal] = useState(false);
  const [selectedTransition, setSelectedTransition] = useState({});
  const [unHiddenTransitions, setNonHiddenTransitions] = useState([]);
  const [submissionInitalValues, setSubmissionInitalValues] = useState({});
  const [validationSchema, setvalidationSchema] = useState({});
  const theme = createMuiTheme({
    palette: {
      secondary: red
    }
  });
  useEffect(() => {
    if (selectedTransition.section)
      setvalidationSchema(SetYupValidationSchema([selectedTransition.section]));
  }, [selectedTransition]);

  const handleChoose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let transition = unHiddenTransitions[parseInt(e.currentTarget.dataset.i)];
    // transition = disableInputs(transition);
    setSelectedTransition(transition);
  };
  const addDocumentsValuesToSection = (documents, section) => {
    let initialvalues = _.cloneDeep(submission);
    documents.forEach((document) => {
      let attachments = submission.attachments.filter(
        (attachment) => attachment.input_id == document.id
      );
      attachments.forEach((attachment) => {
        let attachmentIndex = attachment.name.match(/(\d+)/)[0];
        initialvalues[section.objectName][attachmentIndex][document.key] = {
          ...attachment,
          type: attachment.metadata.type
        };
      });
    });
    setSubmissionInitalValues(initialvalues);
    console.log({ initialvalues });
  };
  useEffect(() => {
    if(transitions.length > 0){
      const availableTransitions = transitions?.filter((transition) => {
        if (transition.section?.documents?.length > 0)
        addDocumentsValuesToSection(
          transition.section.documents,
          transition.section
          );
          else {
            setSubmissionInitalValues(submission);
          }
          return !transition.hidden;
        });
        setNonHiddenTransitions(availableTransitions);
        if (availableTransitions?.length == 1) {
          setSelectedTransition(availableTransitions[0]);
        }
    }
  }, [transitions]);
  return (
    <>
      <Dialog
        classes={{ paper: 'modal-content' }}
        fullWidth
        maxWidth={modalSize || 'md'}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title2">
        <DialogContent className="p-0">
          <div>
            <div className="text-center p-5">
              {unHiddenTransitions?.length >= 1 && (
                <div className="card-header d-block bg-white pt-4 pb-3">
                  <div className="text-primary text-center h3 mb-4">
                    <p>
                      <strong> اختر الاجراء التالى </strong>
                    </p>
                  </div>
                  <Grid container alignItems="center" justify="center">
                    {unHiddenTransitions?.map((t, i) => (
                      <Grid item xs={6} key={i}>
                        <ThemeProvider theme={theme}>
                          <Button
                            className="mr-2 mb-2"
                            size="large"
                            variant={'contained'}
                            style={{
                              backgroundColor:
                                selectedTransition.id == t.id
                                  ? '#3347ad'
                                  : t?.style?.color,
                              color: selectedTransition.id == t.id && 'white'
                            }}
                            key={t.id}
                            data-i={i}
                            onClick={handleChoose}>
                            {t?.style?.icon?.position == 'right' && (
                              <FontAwesomeIcon
                                icon={['fa', t?.style?.icon?.name]}
                                className="ml-1"
                              />
                            )}
                            {t.name}
                            {t?.style?.icon?.position == 'left' && (
                              <FontAwesomeIcon
                                icon={['fa', t?.style?.icon?.name]}
                                className="mr-1"
                              />
                            )}
                          </Button>
                        </ThemeProvider>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              )}
              {lastComment && (
                <div className="comment mb-2">
                  <h6 style={{ color: '#fcae31' }}>{'التعليق السابق'}</h6>
                  <p className="m-0">{lastComment}</p>
                </div>
              )}
              <TransitionForm
                validationSchema={validationSchema}
                selectedTransition={selectedTransition}
                submissionAttachments={submissionAttachments}
                initialValues={
                  selectedTransition?.section
                    ? {
                        [selectedTransition?.section
                          ?.key]: submissionInitalValues?.[
                          selectedTransition?.section?.key
                        ]
                      }
                    : {}
                }
                setOpen={setOpen}
                assignTasksBeforeSubmit={assignTasksBeforeSubmit}
                taskStepId={taskStepId}
                task_id={task_id}></TransitionForm>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <SuccessModal
        modal={successModal}
        setModal={setSuccessModal}></SuccessModal>
    </>
  );
}
const mapStateToProps = (state) => {
  return { sucess: state.details.sucess };
};

export default connect(mapStateToProps)(FormModal);
