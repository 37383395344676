import React, { useState } from 'react';
import { Grid, Divider, Switch, Button } from '@material-ui/core';
import { GetApp, DesktopMac } from '@material-ui/icons/';

import { connect } from 'react-redux';
import { saveCardAttachment } from '../../../reducers/details';
import { apiConfig } from '../../../api/utilities';
import { evaluateAttachmentName } from '../../FilesAccordion/util';
import client from '../../../api/apiAuth/guestClient';
import { SyncLoader } from 'react-spinners';

const AttachmentDetails = ({
  item,
  setPdfModals,
  pdfModals,
  task,
  saveCardAttachment,
  user,
  onAttachmentReviewed,
  attachmentsKey,
  submission
}) => {
  const [loading, setLoading] = useState(false);
  const handleToggle = (e, attachment) => {
    // save changed attachmentt into redux
    saveCardAttachment({
      ...attachment.data,
      is_reviewed: !attachment.data.is_reviewed
    });
  };
  const previewPDF = async (attachment) => {
    let imageData;
    if (attachment.storage_type == 'externalWebsite') {
      imageData = await client.get(
        `${apiConfig.baseUrl}attachment/download/?id=${attachment.id}&storage_type=${attachment.storage_type}`,
        {
          responseType: 'blob',
          withCredentials: true
        }
      );
    } else {
      imageData = await client.get(attachment.url, {
        responseType: 'blob',
        withCredentials: true
      });
    }
    //Create a Blob from the PDF Stream
    const file = new Blob([imageData.data], { type: 'application/pdf' });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    window.open(fileURL, '_blank', 'toolbar=0,menubar=0');
  };

  const previewImgAttachment = async (attachment) => {
    let img = new Image();
    let url = '';

    //incase if the file is saved in file accordion in the details page
    if (attachment.data?.extension) {
      let extension = attachment.data.extension;
      let imageData;
      if (attachment.data.storage_type == 'externalWebsite') {
        imageData = await client.get(
          `${apiConfig.baseUrl}attachment/download/?id=${attachment.data.id}&storage_type=${attachment.data.storage_type}`,
          {
            responseType: 'blob',
            withCredentials: true
          }
        );
      } else {
        imageData = await client.get(attachment.data.url, {
          responseType: 'blob',
          withCredentials: true
        });
      }
      url = URL.createObjectURL(
        new Blob([imageData.data], { type: `image/${extension}` })
      );
      // incase if used in the edit submission without using more data
    } else if (attachment.pdfUrl) {
      let extension = attachment.name.split('.');
      extension = extension[extension.length - 1];
      const imageData = await client.get(attachment.pdfUrl, {
        responseType: 'blob',
        withCredentials: true
      });
      url = URL.createObjectURL(
        new Blob([imageData.data], { type: `image/${extension}` })
      );
    } else url = URL.createObjectURL(attachment);
    //display the image loaded in a new window
    img.onload = () => {
      window.open(
        url,
        '_blank',
        `height=${img.height},width=${img.width},left=100,top=50`
      );
    };
    img.src = url;
  };
  const previewFile = (attachment) => {
    if (attachment.data.extension === 'pdf') {
      previewPDF(attachment.data);
    } else if ((/^image/i).test(attachment.data.metadata.type)) {
      previewImgAttachment(attachment);
    }
    return null;
  };
  return (
    <React.Fragment>
      <Grid item sm={6} className="mt-3">
        <div className="text-right h5 mt-2 expand-content-text">
          {evaluateAttachmentName(item, submission)}
        </div>
        {
          item.data.name.split('.')[0] !== 'architect' && (
            <div className="text-right h5 mt-2 expand-content-text">
            <span className="date-font mb-2">
              {new Date(item.data.updatedAt).toLocaleDateString('ar-eg', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
              })}
            </span>
            <span className="date-font mr-3 mb-2">
              {new Date(item.data.updatedAt).toLocaleTimeString('ar-eg', {
                hour: '2-digit',
                minute: '2-digit'
              })}
            </span>
          </div>
          )
        }
      </Grid>
      <Grid item sm={6} className="expand-button mt-3">
        <Button
          className="btn-transition-none p-0 btn-md"
          onClick={() => {
            const newPdfModals = [];
            for (const boolVal of pdfModals) {
              newPdfModals.push(boolVal);
            }
            newPdfModals[item.idx] = true;
            setPdfModals(newPdfModals);
            previewFile(item);
          }}
          color="primary">
          <DesktopMac />
        </Button>
        <Button className="btn-transition-none  btn-lg " color="primary">
          <a
            href={`${apiConfig.baseUrl}attachment/download/?id=${item.data.id}&storage_type=${item.data.storage_type}`}
            download>
            <GetApp />
          </a>
        </Button>
        { user.roles[0].name !== 'architect' ? (
            <div className="m-3">
            <div>
              <Switch
                disabled={
                  !(task?.is_assigned && task?.user?.id === user?.id) ||
                  user?.roles.some((role) =>
                    ['general secertary'].includes(role.name)
                  )
                }
                checked={item.data.is_reviewed}
                onChange={(e) => {
                  handleToggle(e, item);
                  onAttachmentReviewed && onAttachmentReviewed(item, attachmentsKey, e, setLoading);
                }}
                className="switch-medium toggle-switch-line toggle-switch-success"
              />
              {loading && (
                <div className="mt-2">
                  <SyncLoader color="#3c44b1c4" loading={true} size={10} />
                </div>
              )}
            </div>
          </div>
          ) : null }
      </Grid>
      <Divider />
    </React.Fragment>
  );
};

const mapStateToProps = (store) => {
  return {
    task: store.details.task,
    user: store.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCardAttachment: (attachment) => dispatch(saveCardAttachment(attachment))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentDetails);
