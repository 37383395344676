import React from 'react';

import { Grid, Card, Divider } from '@material-ui/core';
import FilesAccordion from '../FilesAccordion';
import { GRIDCARD_TYPES, CARD_STYLES } from './types';
import { Fragment } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import WarningIcon from '@material-ui/icons/Warning';
import './index.css';
import _ from 'lodash';
import Comments from 'components/Comments';

const getValue = (obj, path) => {
  if (path) {
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexepaths to propertis
    path = path.replace(/^\./, ''); // strip a leading dot
    let value = path.split('.').reduce((o, i) => {
      if (o) return o[i];
    }, obj);
    if (value === true) value = 'نعم';
    else if (value === false) value = 'لا';
    return value;
  }
  return null;
};

const getValueAlternate = (obj, path) => {
  const keys = [];
  let currentKey = '';
  let continueKey = false;
  for (var i = 0; i < path.length; i++) {
    if (path.charAt(i) === '.') {
      if (i < path.length - 1 && path.charAt(i + 1) === '.') {
        continueKey = true;
        continue;
      }
      if (currentKey.length && !continueKey) {
        keys.push(currentKey);
        currentKey = '';
        continue;
      }
      if (continueKey) {
        continueKey = false;
      }
    }
    currentKey += path.charAt(i);
  }
  if (currentKey.length) {
    keys.push(currentKey);
  }

  let value = keys.reduce((o, i) => {
    if (o) return o[i];
  }, obj);
  if (value === true) value = 'نعم';
  else if (value === false) value = 'لا';
  return value;
};

const attrValue = (form, attr, subAttrs) => {
  let value = getValue(form, attr.key);
  if(attr.key == 'service_location' && value == "اخرى") value = getValue(form, 'other_service_location');
  // Try alternate method
  if (!value) {
    value = getValueAlternate(form, attr.key);
  }
  const valueRep = (attr, value) => {
    return (
      <>
        {value && (
          <span
            className="font-size-md text-black-70 pb-3"
            style={attr.style ? attr.style : {}}>
            {attr.title?.includes('تاريخ')
              ? new Date(value).toLocaleDateString('en', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric'
                })
              : `${value || '___'}  ${attr.postfix || ''}`}
          </span>
        )}
      </>
    );
  };
  return (
    <>
      {attr.key == 'researcher_report' ? (
        <ReactQuill value={value || ''} readOnly={true} theme={'bubble'} />
      ) : Array.isArray(value) ? (
        value.map((element, i) => {
          return (
            <Fragment key={i}>
              {subAttrs ?
                subAttrs.map((attr) => (
                <Fragment key={attr.key}>
                  {attr.isTitle ? (
                    <dt> {valueRep(attr, getValue(element, attr.key))}</dt>
                  ) : (
                    <dd>{valueRep(attr, getValue(element, attr.key))}</dd>
                  )}
                </Fragment>
              )) : valueRep(attr, element)}
            </Fragment>
          );
        })
      ) : (
        valueRep(attr, value)
      )}
    </>
  );
};

const getCardContent = (card, form, is_secondary) => {
  return (
    <>
      <Grid
        item
        md={12}
        className={`p-1 ${form?.service_status == 'stopped' ? 'bg-color-danger mr-2' : ''
          }`}>
        {form?.service_status == 'stopped' && (
          <div className="mb-2" style={{ display: 'block' }}>
            <WarningIcon className="txt-danger" />
            {'لقد تم وقف استحقاق الخدمة'}
          </div>
        )}
      </Grid>

      {
        card.popoverContent ?
          (
            <Comments section={card.role} />
          )
          :

          card[is_secondary ? 'secondaryAttrs' : 'attrs']?.map((attr, i) => (
            <React.Fragment key={i}>
              {getValue(form, attr.key) && (
                <>
                  <Grid
                    item
                    md={attr.gridSize || card.gridSize || 3}
                    className={`p-1 ${form?.service_status == 'stopped' && attr.key == 'comment'
                        ? 'bg-color-danger mr-2'
                        : ''
                      }`}
                    key={i}>
                    <div className="text-right mr-3 pb-2">
                      <div className="mt-1 line-height-sm">
                        {attr.title &&
                          (card.cardStyle == CARD_STYLES.INNER ? (
                            <span
                              className="tab-inner-grid-attr semi-bold "
                              style={attr.style ? attr.style : {}}>
                              {attr.title} : &ensp;
                            </span>
                          ) : (
                            <span
                              className="d-4 font-weight-bold d-block mb-3 yellow-color "
                              style={attr.style ? attr.style : {}}>
                              {attr.title}
                            </span>
                          ))}
                        {attrValue(form, attr, card.subAttrs)}
                      </div>
                    </div>
                  </Grid>
                  {attr.key == 'service_name' && (
                    <Grid
                      item
                      md={9}
                      container
                      spacing={2}
                      style={{ textAlign: 'right' }}>
                      {form.service_renewal_decessions.map((service, index) => {
                        return (
                          <React.Fragment key={index}>
                            {['renew', 'continue'].includes(service.renew) ? (
                              <Grid item md={6}>
                                <div className="m-1 text-success badge badge-neutral-success">
                                  <span>{'موافقة: '}</span>
                                  {service.group.map((step, index) => {
                                    return (
                                      <span>
                                        {`${step.step_name} ${service.group.length == index + 1
                                            ? ''
                                            : ' , '
                                          }`}
                                      </span>
                                    );
                                  })}
                                </div>
                              </Grid>
                            ) : (
                              <Grid item md={6}>
                                <div className="m-1 text-danger badge badge-neutral-danger">
                                  <span>{'رفض: '}</span>
                                  {service.group.map((step, index) => {
                                    return (
                                      <span>
                                        {`${step.step_name} ${service.group.length == index + 1
                                            ? ''
                                            : ' , '
                                          }`}
                                      </span>
                                    );
                                  })}
                                </div>
                              </Grid>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Grid>
                  )}
                </>
              )}
            </React.Fragment>
          ))

      }
    </>
  );
};

const CardTitle = ({ card }) => (
  <>
    {card.cardStyle === CARD_STYLES.INNER ? (
      <span className="tab-card-title yellow-color">{card.title}</span>
    ) : card.cardStyle === CARD_STYLES.HIGHLIGHTED ? (
      <span className="highlighted-title">{card.title}</span>
    ) : (
      <span className="tab-card-title">{card.title}</span>
    )}
  </>
);

export default function GridCards({
  cards,
  setPdfModals,
  pdfModals,
  serverUrl,
  submission
}) {
  return (
    <Grid container spacing={0}>
      {cards?.map((card, idx) => {
        // Check card type
        if (card.type === GRIDCARD_TYPES.DUAL) {
          return (
            <Grid
              item
              md={12}
              key={idx}
              className={`${
                [CARD_STYLES.HIGHLIGHTED, CARD_STYLES.INNER].includes(
                  card.cardStyle
                )
                  ? ''
                  : 'mb-4'
              }`}>
              <Card className="card-box" key={idx}>
                <Grid container>
                  <Grid item md={card.size}>
                    <Grid item sm={12}>
                      <CardTitle card={card} />
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={0}
                      sm={12}
                      style={{ 
                        "overflow-wrap" : "break-word"
                       }}
                      className={`${
                        card.cardStyle === CARD_STYLES.HIGHLIGHTED
                          ? 'highlighted-background mb-0'
                          : ''
                      }`}>
                      {Array.isArray(card.form)
                        ? card.form.map((element, i) => (
                            <React.Fragment key={i}>
                              {element && getCardContent(card, element)}
                            </React.Fragment>
                          ))
                        : card.form && getCardContent(card, card.form)}
                    </Grid>
                  </Grid>
                  <Grid item md={1}>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ width: '100%', height: '100%' }}>
                      <div
                        className='rounded'
                        style={{
                          backgroundColor: 'rgba(0, 0, 0, 0.12)',
                          width: '0.3rem',
                          height: '75%'
                        }}></div>
                    </div>
                  </Grid>
                  <Grid item md={card.secondarySize}>
                    <Grid item sm={12}>
                      <span className="tab-card-title">
                        {card.secondaryTitle}
                      </span>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      container
                      spacing={0}
                      sm={12}
                      style={{ 
                       "overflow-wrap" : "break-word"
                      }}
                      className={`${
                        card.cardStyle === CARD_STYLES.HIGHLIGHTED
                          ? 'highlighted-background'
                          : ''
                      }`}>
                      {Array.isArray(card.form)
                        ? card.form.map((element, i) => (
                            <React.Fragment key={i}>
                              {/*3rd args = true => to opply the same logic on secondary attributes  */}
                              {element && getCardContent(card, element, true)}
                            </React.Fragment>
                          ))
                        : card.form && getCardContent(card, card.form, true)}
                    </Grid>
                  </Grid>
                </Grid>
                {card.attachments && card.attachments.length > 0 && (
                  <FilesAccordion
                    attachments={card.attachments}
                    type={card.accordionType}
                    serverUrl={serverUrl}
                    pdfModals={pdfModals}
                    setPdfModals={setPdfModals}
                    onAttachmentReviewed={card.onAttachmentReviewed}
                    attachmentsKey={card.attachmentsKey}
                    submission={submission}
                  />
                )}
              </Card>
            </Grid>
          );
        } else if (card.type === GRIDCARD_TYPES.GENERALSEC) {
          return (
            <Grid
              item
              md={12}
              key={idx}
              className={`${
                card.cardStyle === CARD_STYLES.HIGHLIGHTED ? '' : 'mb-5'
              }`}>
              <Card className="card-box" key={idx}>
                <CardTitle card={card} />
                {card.divider && <Divider />}
                <Grid container spacing={0} className="p-2">
                  <Grid item xs={12} container justifyContent="flex-end">
                    <div className="tab-card-title yellow-color">القرار:</div>
                  </Grid>
                  {card?.form?.map((service, index) => (
                    <Grid item xs={12} container justfiyContent="flex-end" key={index}>
                      <div>
                        {index + 1}.{' '}
                        {`${service.service_name} - ${
                          service.exchange_repeat
                        } مبلغ و
              قدره ${service.actual_cost ? service.actual_cost : '...'}`}{' '}
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={12} container justifyContent="flex-end">
                    <div className="tab-card-title yellow-color mb-2">
                      جهة التنفيذ:
                    </div>
                    {card?.form?.map((service, index) => (
                      <Grid item xs={12} container justfiyContent="flex-end" key={index}>
                        <div>
                          {index + 1}. {service.agency_name}
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        } else if (card.type === GRIDCARD_TYPES.GENERALSECNOTES) {
          return (
            <Grid
              item
              md={card.size}
              key={idx}
              className={`${
                card.cardStyle === CARD_STYLES.HIGHLIGHTED ? '' : 'mb-5'
              }`}>
              <Card className="card-box" style={card.style}>
                <CardTitle card={card} />
                {card.divider && <Divider />}
                <Grid
                  item
                  container
                  spacing={0}
                  className={`${
                    card.cardStyle === CARD_STYLES.HIGHLIGHTED
                      ? 'highlighted-background'
                      : ''
                  } p-2`}>
                  {card.text}
                </Grid>
              </Card>
            </Grid>
          );
        } else {
          // No card type, fallback to default card
          return (
            card.form && (
              <Grid
                item
                md={card.size}
                key={idx}
                className={`${
                  card.cardStyle === CARD_STYLES.HIGHLIGHTED ? '' : 'mb-5'
                }`}>
                <Card className="card-box" style={card.style}>
                  <CardTitle card={card} />
                  {card.divider && <Divider />}
                  <Grid
                    item
                    container
                    spacing={0}
                    className={`${
                      card.cardStyle === CARD_STYLES.HIGHLIGHTED
                        ? 'highlighted-background'
                        : ''
                    }`}>
                    {Array.isArray(card.form)
                      ? card.form.map((element, i) => (
                          <React.Fragment key={i}>
                          {i > 0 && <hr style={{ height: ".4rem" }} className='rounded' />}
                            {element && getCardContent(card, element)}
                          </React.Fragment>
                        ))
                      : card.form && getCardContent(card, card.form)}
                  </Grid>
                  {card.attachments && card.attachments.length > 0 && (
                    <FilesAccordion
                      attachments={card.attachments}
                      type={card.accordionType}
                      serverUrl={serverUrl}
                      pdfModals={pdfModals}
                      setPdfModals={setPdfModals}
                      onAttachmentReviewed={card.onAttachmentReviewed}
                      attachmentsKey={card.attachmentsKey}
                      submission={submission}
                    />
                  )}
                </Card>
              </Grid>
            )
          );
        }
      })}
    </Grid>
  );
}
