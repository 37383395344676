import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { connect } from 'react-redux';
import styles from './index.module.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfViewer({ file, modal: open, setModal: setOpen }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const toggle = () => setOpen(!open);

  const isFileObject = (obj) => {
    return obj instanceof File;
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    // <div>
    <Dialog
      open={open}
      onClose={toggle}
      classes={{
        paper: `shadow-sm-dark rounded-sm ${styles.modal}`,
        root: styles.modal,
        container: styles.modal
      }}
      fullWidth
      maxWidth="md">
      <div className="text-center p-5">
        <Document
          file={
            isFileObject(file) ? file : { url: file, withCredentials: true }
          }
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          className={styles.modal}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </Dialog>

    // </div>
  );
}
const mapStatToProps = (state) => {
  return {
    attachments: state?.details?.task?.submission?.attachments
  };
};
export default connect(mapStatToProps)(PdfViewer);
