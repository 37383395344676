import {
  CONNECTION_STATUS_OFFLINE,
  CONNECTION_STATUS_ONLINE,
  CONNECTION_TOAST_INTERVAL
} from 'utils/constants';

export function isOnline(status) {
  return status === CONNECTION_STATUS_ONLINE;
}

export function isOffline(status) {
  return status === CONNECTION_STATUS_OFFLINE;
}

export function shouldShowToast(d1, d2) {
  return d2 - d1 >= CONNECTION_TOAST_INTERVAL;
}
