const addContactIdToDocument = (nationalId, document) => {
  if (nationalId) {
    document = { ...document, contact_id: nationalId };
  }
  return document;
};

export const handleDocument = (
  formik,
  keyParts,
  sectionName,
  documentName,
  file,
  allFiles,
  input_id,
  rejectedFileErrorMessage,
  setRejectedFileErrorMessage
) => {
  let section;
  if (/\d/.test(keyParts[1])) {
    section = keyParts[0] + '[' + keyParts[1] + ']';
  } else {
    section = keyParts[0];
  }
  let document = {
    data: file,
    name: documentName,
    original_file_name: file?.name,
    input_id,
    section,
    sectionName,
    type: file?.type,
    preview: file?.preview
  };
  let contactsSections = ['relatives', 'friends'];
  let nationalId;
  if (contactsSections.includes(sectionName)) {
    if (/\d/.test(keyParts[1])) {
      if (formik.values[sectionName]) {
        if (Array.isArray(formik.values[keyParts[0]])) {
          nationalId = formik.values[keyParts[0]].at(keyParts[1])?.national_id ?
            formik.values[keyParts[0]].at(keyParts[1])?.national_id
            : formik.values[keyParts[0]].at(keyParts[1])?.passport_id

        } else {
          nationalId = formik.values[keyParts[0]]?.national_id ?
          formik.values[keyParts[0]]?.national_id 
          : formik.values[keyParts[0]]?.passport_id
        }
      }
    } else {
      nationalId =
        formik.values[sectionName] && formik.values[keyParts[0]]?.national_id;
    }
  } else {
    nationalId = formik.values.primaryFields?.national_id ? formik.values.primaryFields?.national_id : formik.values.primaryFields?.passport_id;
  }

  document = addContactIdToDocument(nationalId, document);
  if (allFiles && allFiles.length > 0) {
    if(file.type == 'application/pdf') {
      formik.setFieldValue(documentName, file);
    } else {
      setRejectedFileErrorMessage([
        ...rejectedFileErrorMessage,
        { message: 'غير مسموح اﻻ برفع ملفات pdf ' }
      ]);
    }
    return document;
  }
};
