import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextareaAutosize,
  FormControl,
  MenuItem,
  TextField
} from '@material-ui/core';
import { getValue } from 'utils/GetObjectValues';
import client from 'api/apiAuth/guestClient';
import Swal from 'sweetalert2';
import { Select } from '@mui/material';

export default function ArchivingModal({
  openArchiveDialog,
  handleِArchiveDialogClose,
  submissionId,
  stepId
}) {
  const formik = useFormik({
    validationSchema: Yup.object({
      archiving_reason: Yup.string().required('هذا الحقل مطلوب'),
      holding_time: Yup.string().required('هذا الحقل مطلوب'),
      period: Yup.string()
        .required('هذا الحقل مطلوب')
        .oneOf(['أيام', 'ساعات', 'أسابيع'])
    }),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {},
    onSubmit: (values, { setSubmitting }) => {
      handleArchiveSubmission();
    }
  });
  const handleArchiveSubmission = async () => {
    console.log('archiving');
    let { data } = await client.post(
      `submission/archiveSubmission?submissionId=${submissionId}`,
      {
        archivingReason: formik.values.archiving_reason,
        holdingTime: formik.values.holding_time + formik.values.period,
        stepId: stepId
      }
    );
    handleِArchiveDialogClose(false);
    if (data.info == 'fail') {
      Swal.fire({
        title: 'ﻻ يمكن تنفيذ هذا الاجراء ',
        text: data.message,
        icon: 'error',
        timer: 3000,
        dangerMode: true
      });
    } else {
      await Swal.fire({
        text: 'حسنا',
        title: data.message,
        icon: 'success',
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: false
      });
      window.location.reload();
    }
  };


  return (
    <Dialog
      open={openArchiveDialog}
      onClose={handleِArchiveDialogClose}
      aria-labelledby="alert-dialog-title"
      // disableEnforceFocus
      aria-describedby="alert-dialog-description">
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          سبب وقف الطلب
        </DialogContentText>
        <TextareaAutosize
          value={formik.values.archiving_reason}
          name="archiving_reason"
          onChange={(ev, value) => {
            formik.handleChange(ev, value);
          }}
          onBlur={(e) => {
            formik.handleBlur(e);
          }}
          rowsMin={'5'}
          cols={'125'}
          style={{ width: '100%', border: ' 1px solid #e4e5eb ' }}
        />

        <div className="text-danger">
          {getValue(formik.errors, 'archiving_reason')}
        </div>

        <FormControl>
          <span className={'p-1 heading-4'} style={{ display: 'block' }}>
            مدة وقف الطلب
          </span>

          <TextField
            fullWidth
            name={'holding_time'}
            value={formik.values.holding_time || ''}
            style={{ border: '1px solid #dedddd' }}
            type="number"
            disableunderline
            onChange={(ev, value) => {
              formik.handleChange(ev, value);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
            }}
            InputProps={{
              endAdornment: (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="period"
                  displayEmpty={true}
                  renderValue={(value) =>
                    value?.length
                      ? Array.isArray(value)
                        ? value.join(', ')
                        : value
                      : 'المدة'
                  }
                  value={formik.values.period || ''}
                  MenuProps={{ disablePortal: true }}
                  onChange={(ev, value) => {
                    formik.handleChange(ev, value);
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}>
                  <MenuItem value={'أيام'}>أيام</MenuItem>
                  <MenuItem value={'ساعات'}>ساعات</MenuItem>
                  <MenuItem value={'أسابيع'}>أسابيع</MenuItem>
                </Select>
              ),
              min: 0,
              inputProps: {
                list: 'rfc'
              }
            }}
          />
          <div className="text-danger">
            {getValue(formik.errors, 'holding_time') ||
              getValue(formik.errors, 'period')}
          </div>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          className="btn-transition-none mb-4 btn-lg edit-btn float-right ml-2"
          variant="contained"
          color="primary"
          type="submit"
          onClick={(e) => formik.handleSubmit(e)}>
          وقف
        </Button>
      </DialogActions>
    </Dialog>
  );
}
