export default function getDOF(idNumber) {
  // Convert number to EN if it's in AR
  idNumber = convertToEN(idNumber);

  const century = idNumber.substring(0, 1);
  const year = idNumber.substring(1, 3);
  const month = idNumber.substring(3, 5);
  const day = idNumber.substring(5, 7);

  const calculatedCentury = 1800 + (century - 1) * 100;
  //   var cutoff = new Date().getFullYear() - 2000;

  const dob = new Date(
    calculatedCentury + parseInt(year) + '/' + month + '/' + day
  );

  return dob;
}

export function calculateAge(birthday) {
  // birthday is a date
  let ageDifMs = Date.now() - birthday.getTime();
  let ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function convertToEN(num) {
  const AR_EN = {
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9'
  };

  let isArabicNumber = false;

  for (const arNum of Object.keys(AR_EN)) {
    if (num.includes(arNum)) {
      isArabicNumber = true;
      break;
    }
  }

  if (isArabicNumber) {
    let newNumber = '';
    for (let i = 0; i < num.length; i++) {
      newNumber += AR_EN[num.charAt(i)];
    }
    return newNumber;
  }
  return num;
}

export function getGender(id) {
  if (id.length !== 14) return null;
  const num = Number(id.substring(9, 13));
  return num % 2 === 0 ? 'أنثى' : 'ذكر';
}
