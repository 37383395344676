// reducers
import themeReducer from './theme';
import errorReducer from './error';
// import listDataReducer from './list';
import { combineReducers } from 'redux';
import listDataReducer from './list/listDataReducer';
import ListReqStageReducer from './list/ListReqStageReducer';
import listLayoutReducer from './list/listLayoutReducer';
import listFormsReducer from './list/ListFormsReducer';
import formDataReducer from './form';
import authReducer from './auth';
import detailsReducer from './details';
import statusReducer from './status';
import generalReducer from './general';

// import formListReducer from './formlist';
/*export default combineReducers({
  theme: themeReducer,
  list: listDataReducer,
  layout: listLayoutReducer,
  error: errorReducer,
  form: formDataReducer,
  auth: authReducer,
  formList: listFormsReducer,
  details: detailsReducer
});*/
// version: (state = version) => state,

const appReducer = combineReducers({
  theme: themeReducer,
  list: listDataReducer,
  layout: listLayoutReducer,
  error: errorReducer,
  form: formDataReducer,
  auth: authReducer,
  formList: listFormsReducer,
  details: detailsReducer,
  reqStageList: ListReqStageReducer,
  status: statusReducer,
  general: generalReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    return appReducer(
      { auth: { isLoggedin: false, loginChecked: true } },
      action
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
