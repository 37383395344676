import React, { useState, useEffect } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  MenuItem,
  Input,
  FormControl,
  Select,
  Button
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Swal from 'sweetalert2';
import SpinnerLoader from '../../components/SpinnerLoader';

import client from '../../api/apiAuth/guestClient';
import UserModal from '../../components/UserModal';

const UserControlPanel = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [allGovernorates, setAllGovernorates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchUsers = async () => {
      try {
        const req1 = client.get('/user/getUsers', {
          params: {
            include: [
              {
                association: 'roles',
                attributes: ['id', 'name'],
                through: {
                  attributes: []
                }
              },
              {
                association: 'governorates',
                attributes: ['id', 'name'],
                through: {
                  attributes: []
                }
              },
              {
                association: 'department'
              }
            ]
          }
        });
        const req2 = client.get('/role/getRoles');
        const req3 = client.get('/governorate/getGovernorates');
        Promise.all([req1, req2, req3]).then((values) => {
          if (isMounted) {
            setUsers(values[0].data.results.rows);
            setAllRoles(values[1].data.roles);
            setAllGovernorates(values[2].data.governorates);
            setLoading(false);
          }
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchUsers();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, index) => {
    const newUsers = [...users];
    const newRoles = [];
    for (const role of allRoles) {
      if (event.target.value === role.name) {
        newRoles.push(role);
        break;
      }
    }
    newUsers[index] = { ...newUsers[index], roles: newRoles };
    setUsers(newUsers);
    client.put('/user/updateUser', newUsers[index]);
  };

  const handleAddNewUser = () => {
    setModalOpen(true);
  };

  const deleteUser = (index) => {
    Swal.fire({
      title: 'هل انت متأكد؟',
      text: 'سوف يتم مسح المستخدم',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا'
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        client.delete(`/user/${users[index].id}`);
        const newUsers = [...users];
        newUsers.splice(index, 1);
        setUsers(newUsers);
      }
    });
  };

  const handleEditUser = (index) => {
    setEditUser({ index, user: users[index] });
    setModalOpen(true);
  };

  const onUserCreated = (user) => {
    setUsers([...users, user]);
  };

  const onUserEdited = (index, user) => {
    const newUsers = [...users];
    newUsers[index] = { ...user };
    setUsers(newUsers);
    setEditUser(null);
  };

  if (loading) {
    return <SpinnerLoader />;
  }

  return (
    <Grid>
      <Button
        color="primary"
        onClick={handleAddNewUser}
        variant="contained"
        className="mb-2">
        إضافة مستخدم جديد
      </Button>
      <TableContainer component={Paper}>
        <Table style={{ direction: 'ltr' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>
                اسم المستخدم
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                البريد اﻹلكترونى
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>اﻻدوار</TableCell>
              <TableCell style={{ textAlign: 'center' }}> إجراء </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? users.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : users
            ).map((item, index) => (
              <TableRow style={{ direction: 'ltr' }} key={item.id}>
                <TableCell style={{ textAlign: 'center' }}>
                  {item.username}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {item.email}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {item.roles.map((role) => role.name).join(', ')}
                </TableCell>

                <TableCell style={{ textAlign: 'center' }}>
                  <Button
                    style={{ color: 'red' }}
                    onClick={() => {
                      deleteUser(index + page * rowsPerPage);
                    }}>
                    <DeleteForeverIcon />
                  </Button>
                  <Button
                    style={{ color: 'orange' }}
                    onClick={() => {
                      handleEditUser(index + page * rowsPerPage);
                    }}>
                    <EditIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage=""
          colSpan={3}
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ direction: 'ltr' }}
        />
      </TableContainer>
      <UserModal
        open={modalOpen}
        setModalOpen={setModalOpen}
        roles={allRoles}
        governorates={allGovernorates}
        onUserCreated={onUserCreated}
        onUserEdited={onUserEdited}
        isEdit={editUser ? true : false}
        user={editUser ? editUser.user : null}
        userIndex={editUser?.index}
        setEditUser={setEditUser}
      />
    </Grid>
  );
};

export default UserControlPanel;
