export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const GET_TASK_DETAILS_PENDING = 'GET_TASK_DETAILS_PENDING';
export const GET_TASK_DETAILS_FULFILLED = 'GET_TASK_DETAILS_FULFILLED';
export const GET_TASK_DETAILS_REJECTED = 'GET_TASK_DETAILS_REJECTED';

export const GET_TIMELINE = 'GET_TIMELINE';
export const GET_TIMELINE_PENDING = 'GET_TIMELINE_PENDING';
export const GET_TIMELINE_FULFILLED = 'GET_TIMELINE_FULFILLED';
export const GET_TIMELINE_REJECTED = 'GET_TIMELINE_REJECTED';

export const ASSIGN_TASK = 'ASSIGN_TASK';
export const ASSIGN_TASK_PENDING = 'ASSIGN_TASK_PENDING';
export const ASSIGN_TASK_FULFILLED = 'ASSIGN_TASK_FULFILLED';
export const ASSIGN_TASK_REJECTED = 'ASSIGN_TASK_REJECTED';
export const SUBMIT_TASK = 'SUBMIT_TASK';
export const SUBMIT_TASK_PENDING = 'SUBMIT_TASK_PENDING';
export const SUBMIT_TASK_FULFILLED = 'SUBMIT_TASK_FULFILLED';
export const SUBMIT_TASK_REJECTED = 'SUBMIT_TASK_REJECTED';
export const SAVE_CARD_ATTACHMENTS = 'SAVE_CARD_ATTACHMENTS';

export const GET_TASK_USERS = 'GET_TASK_USERS';
export const GET_TASK_USERS_PENDING = 'GET_TASK_USERS_PENDING';
export const GET_TASK_USERS_FULFILLED = 'GET_TASK_USERS_FULFILLED';
export const GET_TASK_USERS_REJECTED = 'GET_TASK_USERS_REJECTED';

export const HANDLE_TASK_ASSIGNMENT = 'HANDLE_TASK_ASSIGNMENT';
export const HANDLE_TASK_ASSIGNMENT_PENDING = 'HANDLE_TASK_ASSIGNMENT_PENDING';
export const HANDLE_TASK_ASSIGNMENT_FULFILLED =
  'HANDLE_TASK_ASSIGNMENT_FULFILLED';
export const HANDLE_TASK_ASSIGNMENT_REJECTED =
  'HANDLE_TASK_ASSIGNMENT_REJECTED';
