import React from 'react';

export default function ErrorMessage({
  isTouched,
  errorMessage,
  enableSubmit
}) {
  return (isTouched || enableSubmit) && errorMessage ? (
    <div className="text-danger">{errorMessage}</div>
  ) : null;
}
