import React from 'react';

import { connect } from 'react-redux';

import zakat from '../../assets/images/zakat2.png';

const HeaderLeftBox = ({ user }) => {
  return (
    <>
      <div className="d-block p-0 avatar-icon-wrapper ml-2">
        <div className="avatar-icon rounded">
          <img src={zakat} alt="..." />
        </div>
      </div>

      <div>
        <h5 className="mt-4 mb-0 primary" style={{ color: '#3347ad' }}>
          {user?.username}
        </h5>
        <p style={{ color: 'rgb(160 163 177)' }}>
          {user?.roles &&
            user.roles.map((role, index) =>
              index == user.roles.length - 1 ? role.name : role.name + ' | '
            )}
        </p>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};
export default connect(mapStateToProps, null)(HeaderLeftBox);
