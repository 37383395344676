import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { connect } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import ErrorModal from './components/error/ErrorModal';
import { StylesProvider, jssPreset } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import rtl from 'jss-rtl';
import SpinnerLoader from './components/SpinnerLoader';
import { create } from 'jss';
import { checkLogin } from './reducers/auth';
import IndexedDB from 'utils/indexedDB';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

let history = createBrowserHistory();
const App = ({ error, loginChecked, checkLogin }) => {
  const [appReady, setAppReady] = useState(false);
  useEffect(() => {
    const initDB = async () => {
      await IndexedDB.initDB();
      setAppReady(true);
    };
    checkLogin();
    initDB();
  }, []);
  useEffect(() => {
    history.push(error.page.pathname);
  }, [error]);

  return loginChecked && appReady ? (
    <StylesProvider jss={jss}>
      {/* Required for MUI datepickers */}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router history={history}>
          <ScrollToTop>
            <Routes />
            <ErrorModal error={error.message ? error : null} />
          </ScrollToTop>
        </Router>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  ) : (
    <SpinnerLoader />
  );
};
function mapStateToProps(state) {
  const { error } = state;
  return { error, loginChecked: state.auth.loginChecked };
}
const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: () => dispatch(checkLogin())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
