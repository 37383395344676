import React, { useEffect, useState } from 'react';

import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';

import Chart from 'react-apexcharts';
import client from 'api/apiAuth/guestClient';

export default function Bar({ cardsDetails }) {
  let [chartsData, setChartsData] = useState({});
  let [barData, setBarData] = useState([]);
  let [filter, setFilter] = useState({});
  let arrayLength = new Date().getFullYear() - 2022;

  let years = Array(arrayLength + 1)
    .fill(2022)
    .map((element, index) => {
      if (index != 0) {
        element = element + index;
      }
      return element;
    });
  const handleYearChange = async (value) => {
    setFilter(value);
    let { data } = await client.get('/dashboard/getDashboardInformation', {
      params: {
        type: 'Bar',
        filters: [
          {
            checkDateOnly: true,
            field: 'createdAt',
            value,
            func: 'YEAR'
          }
        ]
      }
    });
    setBarData(data.statistics[0].componentDetails);
  };
  useEffect(() => {
    if (cardsDetails) setBarData(cardsDetails);
  }, [cardsDetails]);
  useEffect(() => {
    const allData = [];
    if (barData) {
      barData.forEach((element) => {
        let data = new Array(12).fill(0);
        element.count.forEach((item) => {
          data[item.month - 1] = item.count;
        });
        allData.push({ name: element.name, data });
      });
      setChartsData(allData);
    }
  }, [barData]);
  const chartsLarge1Options = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: false
      },
      stacked: false
    },
    dataLabels: {
      enabled: false
    },

    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: '50%'
      }
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent']
    },
    colors: ['#3c44b1', 'rgba(60, 68, 177, 0.27)'],
    fill: {
      opacity: 1
    },
    grid: {
      strokeDashArray: '5',
      borderColor: 'rgba(125, 138, 156, 0.3)'
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        'يناير',
        'فبراير',
        'مارس',
        'ابريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أكتوبر',
        'نوفمبر',
        'ديسمبر'
      ]
    },
    yaxis: {
      min: 0
    }
  };

  return (
    <>
      <Card className="card-box p-4 text-center mt-4" style={{ width: '100%' }}>
        <Grid container>
          <Grid item md={10}>
            <h6 className="text-uppercase font-weight-bold mb-1 text-black">
              التقديمات شهريا
            </h6>
          </Grid>
          <Grid item md={2}>
            <div>
              <FormControl variant="outlined" size="small" className="w-50">
                <InputLabel id="demo-simple-select-outlined-label">
                  السنين
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="type-select-label-id"
                  className="mt-0"
                  value={filter}
                  onChange={(e) => {
                    handleYearChange(e.target.value);
                  }}
                  label="Years">
                  {years.map((year, index) => {
                    return (
                      <MenuItem value={year} key={index}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </Grid>
        </Grid>

        <div className="py-3">
          {chartsData.length > 0 && (
            <Chart
              options={chartsLarge1Options}
              series={chartsData}
              type="bar"
              height={359}
            />
          )}
        </div>
      </Card>
    </>
  );
}
