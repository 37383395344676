import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField
} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import client from '../../api/apiAuth/guestClient';
import Swal from 'sweetalert2';
import { handleError } from '../../api/utilities';

export default function SimpleDialog(props) {
  const { open, setOpen, selectedRows, setSelectedRows, taskId, transitions, history } = props;
  const [comment, setComment] = useState('');
  const [selectedTransition, setSelectedTransition] = useState({});

  const submitMultipleTasks = (transition, selectRows, responseMessage, responseIcon) => {
    let selectedTasks = {
      transition_id: transition.id,
      tasks: selectRows,
    }
    client.post('workflow/submittask', selectedTasks).then((res) => {
      Swal.fire({
        text: responseMessage,
        icon: responseIcon
      })
        .then(function (result) {
          window.location.reload();
          history.push('/worklist');
        })
        .catch((error) => {
          handleError(error);
        });
    });
  };
  const handleComment = (taskComment) => {
    setSelectedRows([
      ...selectedRows.map((row) => {
        if (row.taskId == taskId) {
          row.comment = taskComment;
        }
        return row;
      })
    ]);
    setOpen(false);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    handleComment('');
  };

  const handleSubmit = (e, transition) => {
    e.stopPropagation();
    submitMultipleTasks(transition, selectedRows, "success", "success")   
    handleComment(comment);
  };

  const handleChange = (e) => {
    e.stopPropagation();
    setComment(e.target.value);
  };
  return (
    <Dialog
      classes={{ paper: 'modal-content rounded-lg modal-dark' }}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="form-dialog-title">
      <DialogContent className="p-4">
        {
          transitions.map(transition =>
            <Button
              className="btn btn-transition-none mr-3 mb-2"
              variant="contained"
              style={{
                backgroundColor:
                  selectedTransition.id == transition.id
                    ? '#3347ad'
                    : transition?.style?.color,
                color: selectedTransition.id == transition.id && 'white'
              }
              }
              onClick={(e) => {
                setSelectedTransition(transition)
              }}>
              {transition.name}
            </Button>
          )
        }
        <DialogContentText style={{ color: 'black' }}>
          اكتب تعليقا
        </DialogContentText>
        {/* <DialogContentText className="mb-0"> */}
        <TextField
          variant="outlined"
          size="small"
          autoFocus
          margin="dense"
          id="input-with-icon-textfield134"
          label="التعليق"
          fullWidth
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => handleChange(e)}
        />
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions className="p-4">
        <Button
          onClick={(e) => handleClose(e)}
          variant="text"
          className="shadow-none  ml-auto">
          الغاء
        </Button>
        <Button
          onClick={(e) => handleSubmit(e, selectedTransition)}
          className="btn-primary shadow-none ">
          حفظ
        </Button>
      </DialogActions>
    </Dialog>
  );
}
