import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  Input,
  Checkbox,
  ListItemText
} from '@material-ui/core';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

import client from '../../api/apiAuth/guestClient';
import styles from './UserModal.module.css';

const UserModal = (props) => {
  const {
    isEdit,
    user,
    userIndex,
    open,
    setModalOpen,
    roles,
    governorates,
    onUserCreated,
    onUserEdited,
    setEditUser
  } = props;
  const [inputUser, setInputUser] = useState({
    email: '',
    password: '',
    username: '',
    dept_id: null,
    roles: [],
    governorates: []
  });
  const [allDepartments, setAllDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState({ name: '' });
  const [schema, setSchema] = useState();
  useEffect(()=>{
    setSchema(Yup.object().shape({
      email: Yup.string('الحقل مطلوب').email().required('الحقل مطلوب'),
      username: Yup.string('الحقل مطلوب').required('الحقل مطلوب'),
      password: !isEdit ? Yup.string('الحقل مطلوب').required('الحقل مطلوب') : Yup.string(),
      roles: Yup.array().required('الحقل مطلوب')
    })) 
  },[isEdit])

  const formik = useFormik({
    validationSchema: schema,
    validateOnChange: false,
    validationOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
      username: '',
      dept_id: null,
      roles: [],
      governorates: []
    }
  });
  useEffect(() => {
    let isMounted = true;
    const fetchDepartments = async () => {
      try {
        const response = await client.get('/department/getDepartments');
        if (isMounted) {
          setAllDepartments(response.data.departments);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchDepartments();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (user) {
      if (user.department) {
        let dept_id = null;
        for (const department of allDepartments) {
          if (department.id === user.department.id) {
            setSelectedDepartment(department);
            dept_id = department.id;
            break;
          }
        }
        setInputUser({ ...user, dept_id, password: '' });
        formik.setValues({ ...user, dept_id }, false);
      } else {
        setInputUser({ ...user, dept_id: allDepartments[0].id, password: '' });
        formik.setValues({ ...user, dept_id: allDepartments[0].id }, false);
      }
      if (user.governorates.length >= 1) {
        formik.setFieldValue(
          'governorates',
          user.governorates.map((gov) => gov.name)
        );
      }
      if (user.roles.length >= 1) {
        formik.setFieldValue(
          'roles',
          user.roles.map((role) => role.name)
        );
      }
    }
  }, [user]);

  const handleChange = (ev, key) => {
    const updatedUser = { ...inputUser };
    updatedUser[key] = ev.target.value;
    setInputUser(updatedUser);
  };

  const onDeparmentChange = (ev) => {
    for (const department of allDepartments) {
      if (department.name === ev.target.value) {
        setSelectedDepartment(department);
        const updatedUser = { ...inputUser };
        updatedUser.dept_id = department.id;
        setInputUser(updatedUser);
        formik.setFieldValue('dept_id', department.id);
        break;
      }
    }
  };

  const handleClose = () => {
    if (isEdit) {
      setEditUser(null);
    }
    setModalOpen(false);
    setInputUser({
      email: '',
      password: '',
      username: '',
      dept_id: null,
      roles: [],
      governorates: []
    });
    setSelectedDepartment({ name: '' });
    formik.resetForm();
  };

  const handleSubmit = () => {
    const saveOrCreateRequest = async () => {
      try {
        let response = null;
        if (isEdit) {
          response = await client.post('/user/updateUser', inputUser);
          if (response.status === 200) {
            onUserEdited(userIndex, {
              ...inputUser,
              id: response.data.user.id,
              department: { id: inputUser.dept_id }
            });
          }
        } else {
          delete inputUser.dept_id;
          response = await client.post('/user/createUser', inputUser);
          if (response.status === 201) {
            onUserCreated({
              ...inputUser,
              id: response.data.user.id
            });
          }
        }
        setInputUser({
          email: '',
          password: '',
          username: '',
          dept_id: null,
          roles: [],
          governorates: []
        });
        setSelectedDepartment({ name: '' });
        formik.resetForm();
        if (response.status === 201) {
          Swal.fire({
            customClass: {
              container: styles['alert__container']
            },
            title: 'نجحت العملية',
            text: 'تم حفظ المستخدم',
            icon: 'success',
            confirmButtonText: 'رجوع'
          }).then(function () {
            setModalOpen(false);
          });
        } else {
          setModalOpen(false);
        }
      } catch (err) {
        console.log(err);
      }
    };

    formik.validateForm().then((errs) => {
      if (!isEmpty(errs)) {
      } else {
        saveOrCreateRequest();
      }
    });
  };

  const onRoleChange = (ev) => {
    let newRoles = [];
    for (const role of roles) {
      if (ev.target.value.includes(role.name)) newRoles.push(role);
    }
    setInputUser({ ...inputUser, roles: newRoles });
    formik.setFieldValue('roles', ev.target.value);
  };
  const onGovernChange = (e) => {
    let newGoverns = [];
    for (const gov of governorates) {
      if (e.target.value.includes(gov.name)) newGoverns.push(gov);
    }
    setInputUser({ ...inputUser, governorates: newGoverns });
    formik.setFieldValue('governorates', e.target.value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className="text-center">
        {isEdit ? 'تعديل المستخدم' : 'إدخال مستخدم جديد'}
      </DialogTitle>
      <DialogContent className="p-5">
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <TextField
              error={'username' in formik.errors}
              helperText={'username' in formik.errors && formik.errors.username}
              name="username"
              style={{ width: '100%' }}
              variant="outlined"
              label="اسم المستخدم"
              value={inputUser.username}
              onChange={(ev) => {
                formik.handleChange(ev);
                handleChange(ev, 'username');
              }}></TextField>
          </Grid>
          <Grid item sm={12}>
            <TextField
              error={'password' in formik.errors}
              helperText={'password' in formik.errors && formik.errors.password}
              name="password"
              style={{ width: '100%' }}
              variant="outlined"
              label="كلمة السر"
              type="password"
              value={inputUser.password}
              onChange={(ev) => {
                formik.handleChange(ev);
                handleChange(ev, 'password');
              }}></TextField>
          </Grid>
          <Grid item sm={12}>
            <TextField
              error={'email' in formik.errors}
              helperText={'email' in formik.errors && formik.errors.email}
              name="email"
              style={{ width: '100%' }}
              variant="outlined"
              label="البريد الاكترونى"
              value={inputUser.email}
              onChange={(ev) => {
                formik.handleChange(ev);
                handleChange(ev, 'email');
              }}></TextField>
          </Grid>
          {/* <Grid item sm={6}>
            قسم المستخدم:
          </Grid>
          <Grid item sm={6}>
            <Select
              value={selectedDepartment.name}
              onChange={onDeparmentChange}
              style={{ width: '100%' }}>
              {allDepartments.map((department) => (
                <MenuItem value={department.name} key={department.name}>
                  {department.arabic_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {'dept_id' in formik.errors && (
            <Grid item sm={12}>
              <p style={{ color: 'red' }}>{formik.errors.dept_id}</p>
            </Grid>
          )} */}
          <Grid item sm={6}>
            ادوار المستخدم:
          </Grid>
          <Grid item sm={6}>
            <Select
              multiple
              //value={inputUser.roles.map((role) => role.name)}
              onChange={onRoleChange}
              input={<Input />}
              value={formik.values.roles}
              style={{
                width: '100%',
                overflow: 'hidden'
              }}>
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.name}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {'roles' in formik.errors && (
            <Grid item sm={12}>
              <p style={{ color: 'red' }}>{formik.errors.roles}</p>
            </Grid>
          )}
          <Grid item sm={6}>
            المحافظة:
          </Grid>
          <Grid item sm={6}>
            <Select
              multiple
              onChange={onGovernChange}
              input={<Input />}
              value={formik.values.governorates}
              style={{
                width: '100%',
                overflow: 'hidden'
              }}>
              {governorates.map((code) => (
                <MenuItem key={code.governorate_code} value={code.name}>
                  {code.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {'governorates' in formik.errors && (
            <Grid item sm={12}>
              <p style={{ color: 'red' }}>{formik.errors.governorates}</p>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div
          style={{ width: '100%' }}
          className="d-flex justify-content-center">
          <Button
            onClick={handleClose}
            color="secondary"
            size="medium"
            variant="contained"
            className="ml-4"
            style={{ backgroundColor: 'green' }}>
            رجوع
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            size="medium"
            variant="contained">
            {isEdit ? 'تعديل' : 'إدخال'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default UserModal;
