import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  setConnectionStatus,
  setIsListening,
  setToastLastDisplayDate
} from 'reducers/status/statusActions';
import { isOnline, shouldShowToast } from './util';
import {
  CONNECTION_STATUS_OFFLINE,
  CONNECTION_STATUS_ONLINE
} from 'utils/constants';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const ConnectionStatus = ({
  isListening,
  lastToastDisplayDate,
  connectionStatus,
  setConnectionStatusDispatch,
  setIsListeningDispatch,
  setToastLastDisplayDateDispatch
}) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const onConnectionChange = (status) => {
    setConnectionStatusDispatch(status);
  };

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  useEffect(() => {
    if (!isListening) {
      setIsListeningDispatch(true);
      setConnectionStatus(true);
    }
  }, []);

  useInterval(() => {
    const getConnectionStatus = async () => {
      try {
        // const response = await fetch(
        //   `${process.env.REACT_APP_SERVER_URL}app/version`,
        //   { method: 'HEAD' }
        // );
        if (!connectionStatus )
          onConnectionChange(CONNECTION_STATUS_ONLINE);
      } catch (err) {
        if (connectionStatus) onConnectionChange(CONNECTION_STATUS_OFFLINE);
      }
    };
    getConnectionStatus();
  }, 5000);

  useEffect(() => {
    if (connectionStatus !== null) {
      if (!lastToastDisplayDate) {
        setToastLastDisplayDateDispatch(new Date());
      } else {
        const now = new Date();
        if (shouldShowToast(lastToastDisplayDate, now)) {
          setToastLastDisplayDateDispatch(now);
          setShowSnackbar(true);
        }
      }
    }
  }, [connectionStatus]);

  return (
    <Snackbar
      open={showSnackbar}
      autoHideDuration={5000}
      onClose={closeSnackbar}>
      <MuiAlert
        severity={isOnline(connectionStatus) ? 'success' : 'error'}
        onClose={closeSnackbar}
        elevation={6}
        variant="filled"
        style={{ color: 'white' }}>
        {isOnline(connectionStatus)
          ? 'الجهاز متصل بالشبكة'
          : 'الجهاز غير متصل بالشبكة'}
      </MuiAlert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => {
  return {
    connectionStatus: state.status.connectionStatus,
    lastToastDisplayDate: state.status.lastToastDisplayDate,
    isListening: state.status.isListening
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setConnectionStatusDispatch: (status) =>
      dispatch(setConnectionStatus(status)),
    setIsListeningDispatch: (listening) => dispatch(setIsListening(listening)),
    setToastLastDisplayDateDispatch: (newDate) =>
      dispatch(setToastLastDisplayDate(newDate))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionStatus);
