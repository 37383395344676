import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  Menu,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import SaveTwoToneIcon from '@material-ui/icons/SaveTwoTone';
import client from 'api/apiAuth/guestClient';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import PeopleIcon from '@material-ui/icons/People';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import DownloadButton from 'components/DetailsComponents/DownloadButton';
import ArchiveIcon from '@material-ui/icons/Archive';
import { apiConfig } from 'api/utilities';
import qs from 'qs';
import { useHistory } from 'react-router';

const Cards = ({ cardsDetails }) => {
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [governorates, setGovernorates] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [programFilter, setProgramFilter] = useState('');
  const [governorateFilter, setGovernorateFilter] = useState('');
  const [allCards, setAllCards] = useState([]);
  const [anchorExportElFilter, setAnchorExportElFilter] = useState(null);
  const [programExcelFilter, setProgramExcelFilter] = useState('');
  const [fromDateExcelFilter, setFromDateExcelFilter] = useState('');
  const [toDateExcelFilter, setToDateExcelFilter] = useState('');
  const [cardID, setCardID] = useState('');
  const excelDownload = useRef(null);
  let history = useHistory();

  const icons = {
    AssignmentTurnedInIcon,
    DynamicFeedIcon,
    AssignmentLateIcon,
    CancelPresentationIcon,
    AssignmentIcon,
    PausePresentationIcon,
    PeopleIcon,
    LabelImportantIcon,
    ArchiveIcon
  };
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const handleClickExtractFilter = (event, card) => {
    setCardID(card.id);
    setAnchorExportElFilter(event.currentTarget);
  };

  const handleCloseExportFilter = () => {
    setAnchorExportElFilter(null);
  };

  const getFilteredData = async () => {
    let filters = [];
    if (programFilter && programFilter !== 'all') {
      filters = [
        ...filters,
        ...[
          {
            field: 'form_id',
            value: programFilter,
            cardsIds: [1, 2, 3, 4, 5, 8]
          },
          {
            field: '$submissions.form_id$',
            value: programFilter,
            cardsIds: [7]
          },
          {
            field: '$submission.form_id$',
            value: programFilter,
            cardsIds: [6]
          }
        ]
      ];
    }
    if (governorateFilter && governorateFilter !== 'all') {
      filters = [
        ...filters,
        ...[
          {
            field: '$contactsLog.governorate$',
            value: governorateFilter,
            cardsIds: [1, 2, 3, 4, 8]
          },
          {
            field: 'values.primaryFields.governorate',
            value: governorateFilter,
            cardsIds: [5]
          },
          {
            field: 'governorate',
            value: governorateFilter,
            cardsIds: [7]
          },
          {
            field: '$submission.contactsLog.governorate$',
            value: governorateFilter,
            cardsIds: [6]
          }
        ]
      ];
    }
    if (programFilter == 'all' || governorateFilter == 'all') {
      filters = [];
    }
    if (programFilter == 'all' && governorateFilter !== 'all') {
      filters = [
        {
          field: '$contactsLog.governorate$',
          value: governorateFilter,
          cardsIds: [1, 2, 3, 4, 8]
        },
        {
          field: 'values.primaryFields.governorate',
          value: governorateFilter,
          cardsIds: [5]
        },
        {
          field: 'governorate',
          value: governorateFilter,
          cardsIds: [7]
        },
        {
          field: '$submission.contactsLog.governorate$',
          value: governorateFilter,
          cardsIds: [6]
        }
      ];
    }
    let { data } = await client.get('/dashboard/getDashboardInformation', {
      params: { type: 'Cards', filters }
    });
    setAllCards(data.statistics[0].componentDetails);
  };

  const clearFilters = async () => {
    setProgramFilter('');
    setGovernorateFilter('');
    let { data } = await client.get('/dashboard/getDashboardInformation', {
      params: { type: 'Cards' }
    });

    setAllCards(data.statistics[0].componentDetails);
  };

  const clearExcelFilters = async () => {
    setProgramExcelFilter('');
    setFromDateExcelFilter('');
    setToDateExcelFilter('');
  };

  useEffect(() => {
    if (cardsDetails) setAllCards(cardsDetails);
  }, [cardsDetails]);

  useEffect(() => {
    const getInitialFilters = async () => {
      let { data } = await client.get('governorate/getGovernorates');
      let res = await client.get('forms/findForms', {
        params: {
          filter: {
            field: 'id',
            operator: 'notIn',
            value: [12, 13, 14]
          }
        }
      });
      setGovernorates(data.governorates);
      setPrograms(res.data.results.rows);
    };
    getInitialFilters();
  }, []);

  return (
    <Card className={`p-3`} style={{width:"100%"}}>
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item md={12}>
            <div>
              <Button
                onClick={handleClickFilter}
                variant="text"
                className="btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill">
                <FilterListTwoToneIcon className="w-50" />
              </Button>
              <Menu
                anchorEl={anchorElFilter}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElFilter)}
                classes={{ list: 'p-0' }}
                onClose={handleCloseFilter}>
                <div className="dropdown-menu-xxl overflow-hidden p-0">
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={6}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          className="w-100">
                          <InputLabel id="type-select-label">
                            المحافظات
                          </InputLabel>
                          <Select
                            labelId="type-select-label"
                            id="type-select-label-id"
                            fullWidth
                            value={governorateFilter}
                            onChange={(e) => {
                              setGovernorateFilter(e.target.value);
                            }}
                            label="governorates">
                            <MenuItem value={'all'}>
                              {'جميع المحافظات'}
                            </MenuItem>
                            {governorates.map((governorate) => {
                              return (
                                <MenuItem value={governorate.name}>
                                  {governorate.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          className="w-100">
                          <InputLabel id="status-select-label">
                            البرامج
                          </InputLabel>
                          <Select
                            labelId="status-select-label"
                            id="status-select-label-id"
                            fullWidth
                            value={programFilter}
                            onChange={(e) => {
                              setProgramFilter(e.target.value);
                            }}
                            label="Programs">
                            <MenuItem value={'all'}>{'جميع البرامج'}</MenuItem>

                            {programs.map((program) => {
                              return (
                                <MenuItem value={program.id}>
                                  {program.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="divider" />
                  <div className="p-3">
                    <Grid container spacing={6}>
                      <Grid item md={6}>
                        <List
                          component="div"
                          className="nav-neutral-danger flex-column p-0">
                          <ListItem
                            button
                            className="d-flex rounded-sm justify-content-center"
                            onClick={(e) => {
                              e.preventDefault();
                              clearFilters();
                            }}>
                            <DeleteTwoToneIcon className="mr-2" />
                            <span>مسح</span>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item md={6}>
                        <List
                          component="div"
                          className="nav-neutral-success flex-column p-0">
                          <ListItem
                            button
                            className="d-flex rounded-sm justify-content-center"
                            onClick={(e) => {
                              e.preventDefault();
                              getFilteredData();
                            }}>
                            <SaveTwoToneIcon className="mr-2" />
                            <span>فلترة النتائج</span>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Menu>
            </div>
          </Grid>
          {allCards?.map((card, index) => {
            let Icon = icons[card.icon];
            return (
              <Grid item xl={3} md={6} key={index}>
                <Card
                  className="card-box border-0 shadow-first-sm p-4"
                  style={{ backgroundColor: card.backgroundColor }}>
                  <div className="d-flex align-items-center">
                    <Button
                      onClick={(e) => {
                        if ([1, 2, 3, 4].includes(card.id))
                          handleClickExtractFilter(e, card);
                        else if (card.id == 9) {
                          history.push(`/archivedSubmissions`);
                        }
                      }}
                      className="d-40 btn-icon rounded-circle  text-white text-center font-size-lg mr-3"
                      style={{ backgroundColor: card.color }}>
                      <Icon />
                    </Button>
                    <Menu
                      anchorEl={anchorExportElFilter}
                      keepMounted
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={Boolean(anchorExportElFilter)}
                      classes={{ list: 'p-0' }}
                      onClose={handleCloseExportFilter}>
                      <div className="dropdown-menu-xxl overflow-hidden p-0">
                        <div className="p-3">
                          <Grid container spacing={6}>
                            <Grid item md={6}>
                              <span
                                style={{ display: 'inline', float: 'right' }}
                                className="mr-1">
                                من :
                              </span>
                              <FormControl
                                className="mr-4 ml-4 mb-2"
                                variant="outlined"
                                size="small"
                                style={{
                                  display: 'inline',
                                  float: 'left',
                                  width: '100px'
                                }}>
                                <TextField
                                  id="date"
                                  type="date"
                                  style={{ display: 'inline' }}
                                  name="from"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  value={fromDateExcelFilter}
                                  onChange={(e) => {
                                    setFromDateExcelFilter(e.target.value);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item md={6}>
                              <FormControl
                                variant="outlined"
                                size="small"
                                className="w-100">
                                <InputLabel id="status-select-label">
                                  البرامج
                                </InputLabel>
                                <Select
                                  labelId="status-select-label"
                                  id="status-select-label-id"
                                  fullWidth
                                  value={programExcelFilter}
                                  onChange={(e) => {
                                    setProgramExcelFilter(e.target.value);
                                  }}
                                  label="Programs">
                                  <MenuItem value={'all'}>
                                    {'جميع البرامج'}
                                  </MenuItem>

                                  {programs.map((program) => {
                                    return (
                                      <MenuItem value={program.id}>
                                        {program.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={6} style={{ marginTop: 'inherit' }}>
                              <span
                                style={{ display: 'inline', float: 'right' }}
                                className="mr-1">
                                الى :
                              </span>
                              <FormControl
                                className="mr-4 ml-4 mb-2"
                                variant="outlined"
                                size="small"
                                style={{
                                  display: 'inline',
                                  float: 'left',
                                  width: '100px'
                                }}>
                                <TextField
                                  id="date"
                                  type="date"
                                  style={{ display: 'inline' }}
                                  name="to"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  value={toDateExcelFilter}
                                  onChange={(e) => {
                                    setToDateExcelFilter(e.target.value);
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                        <div className="divider" />
                        <div className="p-3">
                          <Grid container spacing={6}>
                            <Grid item md={6}>
                              <List
                                component="div"
                                className="nav-neutral-danger flex-column p-0">
                                <ListItem
                                  button
                                  className="d-flex rounded-sm justify-content-center"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    clearExcelFilters();
                                  }}>
                                  <DeleteTwoToneIcon className="mr-2" />
                                  <span>مسح</span>
                                </ListItem>
                              </List>
                            </Grid>
                            <Grid item md={6}>
                              <List
                                component="div"
                                className="nav-neutral-success flex-column p-0">
                                <ListItem
                                  button
                                  className="d-flex rounded-sm justify-content-center">
                                  <DownloadButton
                                    buttonLabel={'اصدار ملف أكسل'}
                                    btnHref={`${
                                      apiConfig.baseUrl
                                    }dashboard/generateDashboardExcel?${qs.stringify(
                                      {
                                        programExcelFilter,
                                        fromDateExcelFilter,
                                        toDateExcelFilter,
                                        cardID
                                      }
                                    )}`}
                                    btnRef={excelDownload}
                                    btnClasses={
                                      'btn btn-transition-none mb-4 mr-2 '
                                    }
                                    btnStyle={{
                                      backgroundColor: '#3F54B1',
                                      color: 'white'
                                    }}
                                  />
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Menu>
                    <div className="text-black-50 mr-2">{card.cardTitle}</div>
                  </div>
                  <div className="display-3 text-center line-height-sm text-second text-center d-flex align-items-center pt-3 justify-content-center">
                    <div>{card.count}</div>
                  </div>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Card>
  );
};
export default Cards;
