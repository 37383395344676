import { createReducer } from '@reduxjs/toolkit';
import {
  SET_SIDEBAR_SHADOW,
  SET_SIDEBAR_FIXED,
  SET_SIDEBAR_STYLE,
  SET_SIDEBAR_FOOTER,
  SET_SIDEBAR_TOGGLE_MOBILE,
  SET_SIDEBAR_TOGGLE,
  SET_SIDEBAR_USERBOX,
  SET_HEADER_FIXED,
  SET_HEADER_SHADOW,
  SET_HEADER_BG_TRANSPARENT,
  SET_HEADER_SEARCH_HOVER,
  SET_HEADER_DRAWER_TOGGLE,
  SET_CONTENT_BACKGROUND,
  SET_THEME_CONFIGURATOR_TOGGLE,
  SET_FOOTER_FIXED,
  SET_FOOTER_SHADOW,
  SET_FOOTER_BG_TRANSPARENT,
  SET_PAGE_TITLE_STYLE,
  SET_PAGE_TITLE_BACKGROUND,
  SET_PAGE_TITLE_SHADOW,
  SET_PAGE_TITLE_ICON_BOX,
  SET_PAGE_TITLE_DESCRIPTION
} from './themeActionTypes';

const initialState = {
  // Sidebar

  sidebarFixed: true,
  sidebarFooter: true,
  sidebarShadow: false,
  sidebarStyle: 'app-sidebar--dark',
  sidebarUserbox: true,
  sidebarToggleMobile: false,
  sidebarToggle: false,

  // Header

  headerFixed: true,
  headerShadow: true,
  headerBgTransparent: true,
  headerSearchHover: false,
  headerDrawerToggle: false,

  // Main content

  contentBackground: '',
  themeConfiguratorToggle: false,

  // Footer

  footerFixed: false,
  footerShadow: false,
  footerBgTransparent: true,

  // Page title

  pageTitleStyle: '',
  pageTitleBackground: '',
  pageTitleShadow: false,
  pageTitleIconBox: true,
  pageTitleDescription: true
};

const reducer = createReducer(initialState, (builder) => {
  // Sidebar
  builder
    .addCase(SET_SIDEBAR_SHADOW, (state, action) => {
      state.sidebarShadow = action.payload.sidebarShadow;
    })
    .addCase(SET_SIDEBAR_FIXED, (state, action) => {
      state.sidebarFixed = action.payload.sidebarFixed;
    })
    .addCase(SET_SIDEBAR_STYLE, (state, action) => {
      state.sidebarStyle = action.payload.sidebarStyle;
    })
    .addCase(SET_SIDEBAR_FOOTER, (state, action) => {
      state.sidebarFooter = action.payload.sidebarFooter;
    })
    .addCase(SET_SIDEBAR_TOGGLE_MOBILE, (state, action) => {
      state.sidebarToggleMobile = action.payload.sidebarToggleMobile;
    })
    .addCase(SET_SIDEBAR_TOGGLE, (state, action) => {
      state.sidebarToggle = action.payload.sidebarToggle;
    })
    .addCase(SET_SIDEBAR_USERBOX, (state, action) => {
      state.sidebarUserbox = action.payload.sidebarUserbox;
    });

  // Header
  builder
    .addCase(SET_HEADER_FIXED, (state, action) => {
      state.headerFixed = action.payload.headerFixed;
    })
    .addCase(SET_HEADER_SHADOW, (state, action) => {
      state.headerShadow = action.payload.headerShadow;
    })
    .addCase(SET_HEADER_BG_TRANSPARENT, (state, action) => {
      state.headerBgTransparent = action.payload.headerBgTransparent;
    })
    .addCase(SET_HEADER_SEARCH_HOVER, (state, action) => {
      state.headerSearchHover = action.payload.headerSearchHover;
    })
    .addCase(SET_HEADER_DRAWER_TOGGLE, (state, action) => {
      state.headerDrawerToggle = action.payload.headerDrawerToggle;
    });

  // Main content
  builder
    .addCase(SET_CONTENT_BACKGROUND, (state, action) => {
      state.contentBackground = action.payload.contentBackground;
    })
    .addCase(SET_THEME_CONFIGURATOR_TOGGLE, (state, action) => {
      state.themeConfiguratorToggle = action.payload.themeConfiguratorToggle;
    });

  // Footer
  builder
    .addCase(SET_FOOTER_FIXED, (state, action) => {
      state.footerFixed = action.payload.footerFixed;
    })
    .addCase(SET_FOOTER_SHADOW, (state, action) => {
      state.footerShadow = action.payload.footerShadow;
    })
    .addCase(SET_FOOTER_BG_TRANSPARENT, (state, action) => {
      state.footerBgTransparent = action.payload.footerBgTransparent;
    });

  // Page title
  builder
    .addCase(SET_PAGE_TITLE_STYLE, (state, action) => {
      state.pageTitleStyle = action.payload.pageTitleStyle;
    })
    .addCase(SET_PAGE_TITLE_BACKGROUND, (state, action) => {
      state.pageTitleBackground = action.payload.pageTitleBackground;
    })
    .addCase(SET_PAGE_TITLE_SHADOW, (state, action) => {
      state.pageTitleShadow = action.payload.pageTitleShadow;
    })
    .addCase(SET_PAGE_TITLE_ICON_BOX, (state, action) => {
      state.pageTitleIconBox = action.payload.pageTitleIconBox;
    })
    .addCase(SET_PAGE_TITLE_DESCRIPTION, (state, action) => {
      state.pageTitleDescription = action.payload.pageTitleDescription;
    });
});

export default reducer;
