import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import client from 'api/apiAuth/guestClient';
import React, { useEffect, useState } from 'react';

export default function ProgramsFilter({ setAllCards, card }) {
  const [programs, setPrograms] = useState([]);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    const getInitialFilters = async () => {
      let res = await client.get('forms/findForms', {
        params: {
          filter: {
            field: 'id',
            operator: 'notIn',
            value: [12, 13, 14]
          }
        }
      });
      setPrograms(res.data.results.rows);
    };
    getInitialFilters();
  }, []);
  useEffect(() => {
    const filterResults = async () => {
      let filters;
      if (filter && filter != 'all') {
        filters = [
          { field: '$steps.processes.submission.form_id$', value: filter }
        ];
      }
      if (filter == 'all') filters = [];
      let { data } = await client.get('/dashboard/getDashboardInformation', {
        params: {
          type: 'PieChart',
          id: card.id,
          filters
        }
      });
      setAllCards((cardsPreviousState) => {
        return cardsPreviousState.map((card) => {
          let changedComponent = data.statistics[0].componentDetails.find(
            (component) => component.id == card.id
          );
          if (changedComponent) {
            card = changedComponent;
          }
          return card;
        });
      });
    };
    if (filter) filterResults();
  }, [filter]);
  return (
    <>
      <div className="card-header ">
        <div className="card-header--actions">
          <div>
            <FormControl variant="outlined" size="small" className="w-25">
              <InputLabel id="demo-simple-select-outlined-label">
                البرامج
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="type-select-label-id"
                className="mt-0"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                label="Programs">
                <MenuItem value={'all'}>{'جميع البرامج'}</MenuItem>

                {programs.map((program, index) => {
                  return (
                    <MenuItem value={program.id} key={index}>
                      {program.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
}
