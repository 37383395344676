export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_LOADING = 'SET_LOADING';

export const CHECK_LOGIN = 'CHECK_LOGIN';
export const CHECK_LOGIN_PENDING = 'CHECK_LOGIN_PENDING';
export const CHECK_LOGIN_FULFILLED = 'CHECK_LOGIN_FULFILLED';
export const CHECK_LOGIN_REJECTED = 'CHECK_LOGIN_REJECTED';

export const LOGIN = 'LOGIN';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';

// export const SET_USER = 'SET_USER';
