import React, { useState, useEffect } from 'react';
import {
  FormControl,
  TextareaAutosize,
  Button,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import client from '../../api/apiAuth/guestClient';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { ClipLoader } from 'react-spinners';
import _ from 'lodash';
import { getValue } from '../../utils/GetObjectValues';
import InputWidget from '../FormSteps/InputWidget';
import { enableSubmitButton } from '../../reducers/form/formActions';
import { GetStepContent } from 'components/FormSteps/getStepContent';

function SetInputKey(input, association, element) {
  // Handle inputs not belonging to section association
  if (input.location === 'outside') {
    return input.key;
  }

  return association
    ? typeof element != 'undefined'
      ? `${association}[${element}].${input.key}`
      : `${association}.${input.key}`
    : input.key;
}
const TransitionForm = ({
  selectedTransition,
  validationSchema,
  initialValues,
  setOpen,
  task_id,
  attachments,
  submissionAttachments,
  user,
  uploadedAttachments,
  submission,
  assignTasksBeforeSubmit,
  taskStepId,
  enableSubmitButton
}) => {
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(true);
  const [formInitialValues, setFormInitialValues] = useState({});
  const [tableData, setTableData] = useState([]);
  const [transition, setTransition] = useState({});
  const [removing, setRemoving] = useState(false);
  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: formInitialValues,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitTask();
    }
  });

  const handleSetComment = (e) => {
    setComment(e.target.value);
  };
  useEffect(() => {
    let notInitiatedInputs = [];
    selectedTransition.section &&
      selectedTransition.section.inputs.forEach((input, index) => {
        if (input.initialValue === 0) {
          notInitiatedInputs.push(input.key);
        }
      });
    //remove initial values from inputs that has property initalValue equal 0
    let initialValuesCopy = _.cloneDeep(initialValues);
    let tableInitialValues = [];
    for (let [key, value] of Object.entries(initialValues)) {
      if (notInitiatedInputs.includes(key)) {
        initialValuesCopy[key] = '';
      }
      if (Array.isArray(value)) {
        //filter the rejected services  in the finance step
        // if (key == 'requestPurpose') {
        //   initialValuesCopy[key] = initialValuesCopy[key].filter(
        //     (service) => service.service_status != 'rejected'
        //   );
        //   if (selectedTransition.section.key == 'requestPurpose') {
        //     selectedTransition = {
        //       ...selectedTransition,
        //       section: {
        //         ...selectedTransition.section,
        //         number: initialValuesCopy[key].length
        //       }
        //     };
        //     setTransition(selectedTransition);
        //   }
        // }
        value.forEach((v, index) => {
          tableInitialValues.push(v);
          if (typeof v == 'object') {
            notInitiatedInputs.forEach((input) => {
              if (_.has(v, input)) {
                initialValuesCopy[key][index][input] = '';
              }
            });
          }
        });
      } else if (typeof value == 'object') {
        tableInitialValues.push(value);
        notInitiatedInputs.forEach((input) => {
          if (_.has(value, input)) {
            initialValuesCopy[key][input] = '';
          }
        });
      }
    }
    setTableData(tableInitialValues);

    // Object.keys(initialValues).forEach((cur) => {
    //   if (cur == 'requestPurpose') {
    //     initialValues[cur].forEach((item) => {
    //       Object.keys(item).forEach((key) => {
    //         if (item[key] == null) {
    //           delete item[key];
    //         }
    //       });
    //     });
    //   }
    //  });

    setFormInitialValues(initialValuesCopy);
  }, [initialValues]);
  useEffect(() => {
    let attachmentReviewed = attachments.filter(
      (attachment) => attachment.is_reviewed
    );
    if (!submissionAttachments) submissionAttachments = [];
    attachmentReviewed = [
      ...attachmentReviewed,
      ...submissionAttachments.filter(
        (attachment) =>
          attachment.is_reviewed &&
          !attachments.some((attach) => attach.id == attachment.id)
      )
    ];
    if ([97, 100, 104, 105, 110].includes(selectedTransition.id)) {
      setSubmitFlag(true);
    } else if (
      attachmentReviewed.length != submissionAttachments.length &&
      !selectedTransition.is_rejection &&
      user.roles.find(
        (role) =>
          role.name == 'manager' ||
          role.name == 'administration office employee'
      )
    ) {
      setSubmitFlag(false);
    } else {
      setSubmitFlag(true);
    }
  }, [selectedTransition, submissionAttachments, attachments]);
  useEffect(() => {
    if (!_.isEmpty(validationSchema)) {
      formik.validateForm();
      // console.log(formik)
    }
  }, [validationSchema]);

  useEffect(() => {
    setTransition(selectedTransition);
  }, [selectedTransition]);
  const handleSubmitTask = async () => {
    if (
      [29, 30, 31, 32].includes(selectedTransition.id) &&
      !submission.values.requestPurpose
    ) {
      setOpen(false);
      Swal.fire({
        title: 'ﻻ يمكن تنفيذ هذا الاجراء ',
        text: 'يجب عليك اضافة خدمة لنقل الطلب ',
        icon: 'error',
        timer: 3000,
        dangerMode: true
      });
      return;
    }

    if (!submission?.values?.researchersReport && selectedTransition.id == 7) {
      setOpen(false);
      Swal.fire({
        title: 'ﻻ يمكن تنفيذ هذا الاجراء ',
        text: 'يجب عليك اضافة تقرير الباحث',
        icon: 'error',
        timer: 3000,
        dangerMode: true
      });
      return;
    }

    if (submitFlag) {
      setLoading(true);
      await client.post('workflow/submittask', {
        transition_id: selectedTransition.id,
        tasks: [
          {
            taskId: task_id,
            comment: comment,
            submission_edit: formik.values,
            taskStepId: taskStepId ? taskStepId : [],
            attachments: [...uploadedAttachments]
          }
        ],
        assignTasksBeforeSubmit
      });
      setLoading(false);
      window.location.reload();
    } else {
      setOpen(false);
      Swal.fire({
        title: 'ﻻ يمكن تنفيذ هذا الاجراء ',
        text: 'يجب عليك الموافقة على جميع المرفقات لنقل الطلب ',
        icon: 'error',
        timer: 3000,
        dangerMode: true
      });
    }
  };

  const onInputChange = (ev, value, input, option) => {
    const sectionKey = input.key.split('.')[0];
    transition.section.inputs.forEach((input) => {
      formik.setFieldTouched(`${sectionKey}.${input.key}`, true);
    });
  };

  const extractRowData = (row, rowIndex) => {
    return transition.section.inputs.map((column, index) => {
      let value = getValue(row, column.key);
      return (
        <TableCell key={index}>
          <div className={`d-flex  `}>
            {column.displayType == 'inputs' ? (
              <InputWidget
                formik={formik}
                setInitialValues={setFormInitialValues}
                onInputChange={onInputChange}
                formType={'Transition'}
                input={{
                  ...column,
                  section: transition.section.objectName,
                  name: '',
                  key: SetInputKey(
                    column,
                    transition.section.objectName,
                    rowIndex
                  )
                }}></InputWidget>
            ) : (
              <div className="align-center">{value}</div>
            )}
          </div>
        </TableCell>
      );
    });
  };
  return (
    <>
      <div>
        {transition.section && (
          <>
            {transition.section.view == 'edit' ? (
              <>
                {GetStepContent({
                  formik,
                  activeStep: 0,
                  steps: [transition.section],
                  childStep: null,
                  onInputChange,
                  setRemoving
                })}
              </>
            ) : (
              <Table className="table table-alternate-spaced text-nowrap mb-0">
                <TableHead className="font-size-sm text-uppercase">
                  <TableRow>
                    {transition.section.inputs.map((input) => {
                      return (
                        <TableCell
                          className="text-center bg-secondary pt-2"
                          key={input.key}>
                          <b>{input.name || 'اﻻجراء'}</b>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(formInitialValues) &&
                    tableData.map((row, index) => (
                      <TableRow key={index}>
                        {extractRowData(row, index)}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </>
        )}
        <span className="heading-4 d-block float-right mt-4">
          ملاحظة او تعليق
        </span>
        <FormControl className="comment-input">
          <TextareaAutosize
            value={comment}
            onChange={handleSetComment}
            rowsMin={'5'}
            cols={'125'}
            style={{ width: '100%', border: ' 1px solid #e4e5eb ' }}
          />
        </FormControl>
      </div>
      <div className="pt-4">
        <Grid container spacing={0}>
          <Grid item md={12}>
            <Button
              className="btn-first btn-primary mx-1 px-5 py-2"
              size="large"
              onClick={(e) => {
                enableSubmitButton();
                if (transition?.section?.inputs) {
                  formik.handleSubmit(e);
                } else {
                  handleSubmitTask();
                }
              }}
              type="submit"
              disabled={
                !selectedTransition.id ||
                (!_.isEmpty(formik.values) &&
                  selectedTransition?.section?.inputs.some(
                    (e) => !e.disabled
                  ) &&
                  !formik.isValid) ||
                loading
              }>
              <span className="btn-wrapper--label ml-2">تم</span>
              {loading && (
                <ClipLoader color={'var(--primary)'} loading={true} />
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    attachments: state.details.attachments,
    uploadedAttachments: state.form.submission?.attachments,
    submission: state.details.task?.submission,
    user: state.auth.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    enableSubmitButton: () => dispatch(enableSubmitButton())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransitionForm);
