let editButtonConfig = [
  //research supervisor steps
  {
    roles: ['research supervisor'],
    steps: [6, 8]
  },
  //reseacher steps
  {
    roles: ['researcher'],
    steps: [7]
  },
  //adminstration steps
  {
    roles: ['administration office employee', 'administration office manager'],
    steps: [4, 37, 38, 42, 40]
  },
  // requester steps
  {
    roles: ['requester'],
    steps: [39, 41, 44]
  },
  //research manager steps
  {
    roles: ['research general manager', 'research manager'],
    steps: [5, 9, 10, 29]
  }
];

export function checkForEditButton(roles, step) {
  let valid = false;
  roles = roles.map((role) => role.name);
  editButtonConfig.forEach((config) => {
    if (
      config['roles'].find((role) => roles.includes(role)) &&
      config['steps'].includes(step)
    )
      valid = true;
  });
  return valid;
}
