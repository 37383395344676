import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  StylesProvider,
  jssPreset,
  Button,
  Card,
  InputLabel,
  MenuItem,
  Select,
  FormControl
} from '@material-ui/core';
import './style.css';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { connect } from 'react-redux';
import FormSteps from '../../components/FormSteps';
import {
  getDraft,
  getFormLayout,
  setFormName,
  handleDraftAssignment,
  setSubmission
} from '../../reducers/form/formActions';
// import { NavLink } from 'react-router-dom';
import SpinnerLoader from '../../components/SpinnerLoader';
// import SetYupValidationSchema from '../../utils/SetYupValidationSchema';
import client from '../../api/apiAuth/guestClient';
import { getUsers } from '../../reducers/details/detailsAction';
import Swal from 'sweetalert2';
import { sanatizeObject } from '../../utils/SanatizaObject';
import { handleError } from '../../api/utilities';
import getSchema from 'utils/setValidationSchema';

// import { isManagerFastTrackForm } from './utils';
// import { taskNotInStepId } from '../../utils/helper';
import IndexedDB from '../../utils/indexedDB';
import _ from 'lodash';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
function CreateSubmission(props) {
  const {
    getLayout,
    getDraft,
    form,
    match,
    history,
    form_layout_loading,
    setSubmission,
    setFormName,
    user,
    getUsers,
    handleDraftAssignment,
    users,
    // location,
    beneficiaryId,
    savedValues
  } = props;
  const [validationSchema, setValidationSchema] = useState({});
  const [draftValidationSchema, setDraftvalidationSchema] = useState({});
  // state of confirm modal
  let { formId, id, submissionId } = match.params;
  const formStepsRef = useRef();
  const [initialValues, setInitialValues] = useState({});
  const [selectedUser, setSelectedUser] = useState(false);
  const [actionType, setActionType] = useState('');
  const [oldSubmission, setOldSubmission] = useState(false);
  const [saveFlag, setSaveFlag] = useState(false);
  // const [submissionStepId, setSubmissionStepId] = useState(null);
  const getSubmission = async (url, params, type) => {
    try {
      let { data } = await client.get(url, {
        params: { ...params }
      });
      // setSubmissionStepId(data.task.step_id);
      setSubmission({ attachments: [...data.attachments] });
      let SubmissionData = sanatizeObject(data[type]);
      setOldSubmission(true);
      setInitialValues(SubmissionData);
    } catch (error) {
      console.log(error);
      handleError(error);
    }
  };
  useEffect(() => {
    let currentUrl = window.location.href;
    currentUrl = currentUrl.split('/');
    let actionType = ['edit', 'reinvestigate'].includes(
      currentUrl[currentUrl.length - 1]
    )
      ? currentUrl[currentUrl.length - 1]
      : 'submit';
    setActionType(actionType);
    if (_.isEmpty(savedValues)) setSubmission({ attachments: [] });
    setInitialValues({});
    if (!submissionId) getLayout({ formId, actionType });

    setFormName('');
    IndexedDB.clearTemporaryAttachments();
  }, []);

  useEffect(() => {
    if (form) {
      setValidationSchema(getSchema(false));
      setDraftvalidationSchema(getSchema(true));
    }
  }, [form]);
  useEffect(() => {
    if (id) getDraft({ id });
  }, [id]);
  useEffect(() => {
    user?.roles?.find((userRole) => userRole.name == 'manager') &&
      getUsers({
        include: [
          {
            association: 'roles',
            attributes: ['id', 'name'],
            through: {
              attributes: []
            },
            where: {
              name: 'requester'
            }
          }
        ],
        filter: { dept_id: form.dept_id }
      });
  }, [user]);
  useEffect(() => {
    if (beneficiaryId && form.name) {
      getSubmission(
        `/submission/getOldSubmission`,
        {
          national_id: beneficiaryId,
          stepId: form.sections[0].id,
          formName: form.name, 
          sectionId: form.sections.find(section => section.name == "البيانات الأولية").id
        },
        'oldSubmission'
      );
    }
  }, [beneficiaryId, form]);

  useEffect(() => {
    if (submissionId && actionType) {
      getLayout({ formId, isEdit: true });
      getSubmission(
        `/submission/getSubmission`,
        {
          submissionId,
          formId,
          actionType
        },
        'submission'
      );
    }
  }, [submissionId, actionType]);

  const handleAssign = () => {
    handleDraftAssignment(
      {
        user_id: selectedUser
      },
      { draft_ids: [id] }
    ).then(() => {
      Swal.fire({
        title: 'تم',
        text: 'لقد تم إعادة التعيين بنجاح ',
        icon: 'success',
        timer: 3000
      });
    });
  };
  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };
  const handleSaveFlag = () => {
    setSaveFlag(true);
  };

  return (
    <StylesProvider jss={jss}>
      <Grid container spacing={0} direction="row" justify="center">
        {user?.roles?.some((role) => role.name == 'research manager') && (
          <Grid item sm={10} xs={12} container spacing={2} align="left">
            <Grid item sm={6}></Grid>
            <Grid item sm={3}>
              <Button
                className="btn-transition-none mb-4  btn-lg md-4 float-left"
                variant="contained"
                disabled={selectedUser ? false : true}
                onClick={handleAssign}>
                {'إرسال'}
              </Button>
            </Grid>
            <Grid item sm={3}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">
                  {'إعادة تعيين'}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedUser}
                  onChange={handleChange}
                  className="mt-0"
                  label="إعادة تعيين">
                  {users?.map((user) => (
                    <MenuItem value={user?.id}>{user.username}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card className="card-box">
            <div className="card-header">
              <div className="card-header--title">
                <b className="h3">{form && form.name}</b>
              </div>
              {/* {window.location.pathname.includes('programs') &&
                user.roles.some((role) => role.name === 'requester') && (
                  <div className="card-header--actions">


                    <Button
                      onClick={handleSaveFlag}
                      size="small"
                      className="btn-primary ">
                      <span>{'اختر برنامج مختلف'}</span>
                    </Button>
                  </div>
                )} */}
            </div>
            <div className="card-body pt-0 pr-0">
              {form_layout_loading ? (
                <SpinnerLoader />
              ) : (
                <FormSteps
                  form={form.sections}
                  formId={formId}
                  draftId={id}
                  validationSchema={validationSchema}
                  draftValidationSchema={draftValidationSchema}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  submissionId={submissionId}
                  history={history}
                  ref={formStepsRef}
                  actionType={actionType}
                  oldSubmission={oldSubmission}
                  saveFlag={saveFlag}
                  setSaveFlag={setSaveFlag}></FormSteps>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </StylesProvider>
  );
}
const mapStateToProps = (state) => {
  return {
    submission: state.form.submission,
    // enableSubmit: state.form.enableSubmit,
    form: state.form.form,
    form_layout_loading: state.form.form_layout_loading,
    user: state.auth.user,
    users: state.details.users,
    beneficiaryId: state.form.beneficiary_id,
    savedValues: state.form.savedValues
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(getFormLayout(params)),
    getDraft: (params) => dispatch(getDraft(params)),
    getUsers: (params) => dispatch(getUsers(params)),
    setFormName: (name) => dispatch(setFormName(name)),
    handleDraftAssignment: (params, data) =>
      dispatch(handleDraftAssignment(data, params)),
    setSubmission: (submissionDetails) =>
      dispatch(setSubmission(submissionDetails))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateSubmission);
