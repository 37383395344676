import React, { useState, useEffect } from 'react';
import './style.css';
import { useHistory } from 'react-router-dom';
import ArrowDropUpSharpIcon from '@material-ui/icons/ArrowDropUpSharp';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ColumnsRepresentation from './ColumnsRepresentation';
import {
  Table,
  Card,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import 'antd/dist/antd.css';
import { connect } from 'react-redux';
import { Pagination } from 'antd';
import Filters from '../Filters';
import SpinnerLoader from '../../components/SpinnerLoader';
import { getValue } from '../../utils/GetObjectValues';
import TableToolbar from './TableToolbar';
import _ from 'lodash';

function LivePreviewExample({
  layoutStructure,
  layoutData,
  filters,
  setFilters,
  currentTab,
  setSort,
  setPage,
  tasksCount,
  limit,
  loading,
  user,
  setSubmitTaskLoader,
  page,
  applyFilter,
  currentRole,
  activeTab
}) {
  // let history = createBrowserHistory({ forceRefresh: false });
  let history = useHistory();
  const [tableLayout, setTableLayout] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [SelectedCheckbox, setSelectedCheckbox] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const statusInfo = {
    Pending: { translation: 'قيد التنفيذ', color: 'text-warning' },
    Deserved: { translation: 'مستحقة', color: 'text-success' },
    Canceled: { translation: 'تم الرفض', color: 'text-danger' },
    Rejected: { translation: 'تم الرفض', color: 'text-danger' },
    NotDeserved: { translation: 'غير مستحقة', color: 'text-danger' },
    Finished: { translation: 'منتهيه', color: 'text-success' },
    Stopped: { translation: 'تم وقف الطلب', color: 'text-danger' },
    Reinvestegation: { translation: 'قيد اعادة البحث', color: 'text-warning' }
  };

  const redirectInstance = (instance) => {
    if (layoutStructure[activeTab].isDraft)
      history.push(`/programs/${instance.form_id}/${instance.id}`);
    else {
      if (layoutStructure[currentTab].redirectKey) {
        instance.id = getValue(
          instance,
          layoutStructure[currentTab].redirectKey
        );
      }
      history.push(`/details/${instance.id}`);
    }
  };
  const extractRowData = (row, rowIndex) => {
    return tableLayout.map((column, index) => {
      let color;
      row[column.key] = getValue(row, column.key);
      if (column.key.includes('status')) {
        let statusValue = getValue(row, column.key);
        row[column.key] =
          (statusInfo[statusValue] && statusInfo[statusValue]['translation']) ||
          statusValue;
        color =
          (statusInfo[statusValue] && statusInfo[statusValue]['color']) ||
          Object.values(statusInfo).find(
            (status) => status.translation == statusValue
          )?.color;
      }
      return (
        <ColumnsRepresentation
          rowsCount={layoutData.length}
          key={index}
          cellType={column.inputLayout.type}
          rowIndex={rowIndex}
          index={index}
          value={row[column.key]}
          color={color}
          history={history}
          row={row}
          column={column}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setSelectedCheckbox={setSelectedCheckbox}
          SelectedCheckbox={SelectedCheckbox}
          currentTab={currentTab}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      );
    });
  };
  const tableData =
    layoutData && layoutData.length > 0 ? (
      layoutData.map((row, index) => (
        <TableRow
          key={index}
          className={
            row.is_website == 1
              ? 'color_code_success'
              : row.urgent_case == 1
                ? 'color_code_warning'
                : row.due_date && new Date() - new Date(row.due_date) > 0
                  ? 'color_code_danger'
                  : ''
          }
          onClick={(e) => redirectInstance(row)}>
          {extractRowData(row, index)}
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell align="center" colSpan="9">
          <h5 className="m-top">ﻻ توجد نتائج</h5>
        </TableCell>
      </TableRow>
    );

  //pagination
  function itemRender(current, type, originalElement) {
    if (type === 'page') {
      return (
        <a className="pagination-color">{current.toLocaleString('ar-EG')}</a>
      );
    }
    if (type === 'prev') {
      return <a className="prev-next-border">السابق</a>;
    }
    if (type === 'next') {
      return <a className="prev-next-border">التالى</a>;
    }
    return originalElement;
  }
  const handlePagination = (page, pageSize) => {
    setPage(page > 0 ? page : 1);
    // setLimit(pageSize);
  };
  //sorting
  let [sortUp, setSortUp] = useState(false);
  let [sortingColumn, setSortingColumn] = useState();
  const handleSorting = (key, association, forceKey) => {
    setSortUp(!sortUp);
    setSortingColumn(key);
    let sortingType = association + 'Sorting';
    let sortObj = { orderBy: key, type: sortingType };
    if (sortUp) sortObj = { ...sortObj, order: 'DESC' };
    else sortObj = { ...sortObj, order: 'ASC' };
    if (forceKey) sortObj.forceKey = true;
    setSort(sortObj);
  };
  useEffect(() => {
    if (
      !_.isEmpty(layoutStructure) &&
      !layoutStructure[activeTab].hasOwnProperty('headerName')
    ) {
      let layout = [...layoutStructure[activeTab]['Columns']['shared']];

      if (layoutStructure[activeTab]['Columns'][currentRole]) {
        layout = [
          ...layout,
          ...layoutStructure[activeTab]['Columns'][currentRole].filter(
            (item) => !layout.map((cur) => cur.name).includes(item.name)
          )
        ];
      }
      layout = _.orderBy(layout, ['order'], ['asc']);
      setTableLayout(layout);
    }
  }, [layoutStructure, activeTab]);
  useEffect(() => {
    if (layoutData)
      setSelectedCheckbox(new Array(layoutData.length).fill(false));
  }, [layoutData]);
  useEffect(() => {
    // setPage(1);
    setSelectedRows([]);
  }, [currentTab]);
  const onWheel = (e) => {
    //prevent vertical scrolling inside worklist
    document
      .querySelector('#container')
      .addEventListener('wheel', preventScroll, { passive: false });
    //enable horizontal scrollling
    const container = document.getElementById('container');
    const containerScrollPosition = document.getElementById('container')
      .scrollLeft;
    container.scrollTo({
      top: 0,
      left: containerScrollPosition - e.deltaY,
      behaviour: 'smooth'
    });
  };
  function preventScroll(e) {
    e.preventDefault();
    return false;
  }
  return (
    <>
      <Card className="card-box mb-spacing-6-x2" style={{ borderRadius: '0' }}>
        {applyFilter && (
          <Filters
            isDraft={
              !_.isEmpty(layoutStructure)
                ? layoutStructure[currentTab].isDraft
                : null
            }
            filters={filters}
            setFilters={setFilters}
            currentTab={currentTab}
          />
        )}
        {loading ? (
          <>
            <SpinnerLoader />
          </>
        ) : (
          <>
            {selectedRows.length > 0 && (
              <TableToolbar
                selectedRows={selectedRows}
                history={history}
                setSubmitTaskLoader={setSubmitTaskLoader}
                currentTab={currentTab}
                user={user}
                openModal={openModal}
                setOpenModal={setOpenModal}
              />
            )}
            <div
              className="table-container"
              id="container"
              onWheel={onWheel}
              style={{ paddingRight: 0 }}>
              <Table className="table table-alternate-spaced text-nowrap mb-0">
                <TableHead className="font-size-sm text-uppercase">
                  <TableRow>
                    {tableLayout.map((column) => {
                      return (
                        <TableCell
                          className={
                            column.key == 'action'
                              ? 'sticky-header'
                              : 'text-center bg-secondary pt-2'
                          }
                          key={column.key}>
                          {sortUp &&
                          (sortingColumn == column.key ||
                            (column.orderKey &&
                              sortingColumn === column.orderKey))
                            ? column.sorting && (
                                <ArrowDropUpSharpIcon
                                  onClick={() =>
                                    handleSorting(
                                      column.orderKey
                                        ? column.orderKey
                                        : column.key,
                                      column.association,
                                      column.orderKey ? true : false
                                    )
                                  }
                                />
                              )
                            : column.sorting && (
                                <ArrowDropDownSharpIcon
                                  onClick={() =>
                                    handleSorting(
                                      column.orderKey
                                        ? column.orderKey
                                        : column.key,
                                      column.association,
                                      column.orderKey ? true : false
                                    )
                                  }
                                />
                              )}
                          {column.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>{tableData}</TableBody>
              </Table>
            </div>
          </>
        )}
        <div className="p-4 d-flex " style={{ justifyContent: 'flex-end' }}>
          <Pagination
            defaultCurrent={page}
            page={page}
            total={tasksCount}
            pageSize={limit}
            responsive={true}
            showSizeChanger={false}
            onChange={handlePagination}
            itemRender={itemRender}
          />
        </div>
      </Card>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loading: state.list.loading,
    user: state.auth.user
  };
};
export default connect(mapStateToProps, null)(LivePreviewExample);
