import { createAction } from '@reduxjs/toolkit';
import {
  SET_SIDEBAR_SHADOW,
  SET_SIDEBAR_FIXED,
  SET_SIDEBAR_STYLE,
  SET_SIDEBAR_FOOTER,
  SET_SIDEBAR_TOGGLE_MOBILE,
  SET_SIDEBAR_TOGGLE,
  SET_SIDEBAR_USERBOX,
  SET_HEADER_FIXED,
  SET_HEADER_SHADOW,
  SET_HEADER_BG_TRANSPARENT,
  SET_HEADER_SEARCH_HOVER,
  SET_HEADER_DRAWER_TOGGLE,
  SET_CONTENT_BACKGROUND,
  SET_THEME_CONFIGURATOR_TOGGLE,
  SET_FOOTER_FIXED,
  SET_FOOTER_SHADOW,
  SET_FOOTER_BG_TRANSPARENT,
  SET_PAGE_TITLE_STYLE,
  SET_PAGE_TITLE_BACKGROUND,
  SET_PAGE_TITLE_SHADOW,
  SET_PAGE_TITLE_ICON_BOX,
  SET_PAGE_TITLE_DESCRIPTION
} from './themeActionTypes';

// Sidebar
export const setSidebarShadow = createAction(
  SET_SIDEBAR_SHADOW,
  function prepare(sidebarShadow) {
    return {
      payload: {
        sidebarShadow
      }
    };
  }
);
export const setSidebarFixed = createAction(SET_SIDEBAR_FIXED, function prepare(
  sidebarFixed
) {
  return {
    payload: {
      sidebarFixed
    }
  };
});
export const setSidebarStyle = createAction(SET_SIDEBAR_STYLE, function prepare(
  sidebarStyle
) {
  return {
    payload: {
      sidebarStyle
    }
  };
});
export const setSidebarFooter = createAction(
  SET_SIDEBAR_FOOTER,
  function prepare(sidebarFooter) {
    return {
      payload: {
        sidebarFooter
      }
    };
  }
);
export const setSidebarToggleMobile = createAction(
  SET_SIDEBAR_TOGGLE_MOBILE,
  function prepare(sidebarToggleMobile) {
    return {
      payload: {
        sidebarToggleMobile
      }
    };
  }
);
export const setSidebarToggle = createAction(
  SET_SIDEBAR_TOGGLE,
  function prepare(sidebarToggle) {
    return {
      payload: {
        sidebarToggle
      }
    };
  }
);
export const setSidebarUserbox = createAction(
  SET_SIDEBAR_USERBOX,
  function prepare(sidebarUserbox) {
    return {
      payload: {
        sidebarUserbox
      }
    };
  }
);

// Header
export const setHeaderFixed = createAction(SET_HEADER_FIXED, function prepare(
  headerFixed
) {
  return {
    payload: {
      headerFixed
    }
  };
});

export const setHeaderShadow = createAction(SET_HEADER_SHADOW, function prepare(
  headerShadow
) {
  return {
    payload: {
      headerShadow
    }
  };
});

export const setHeaderBgTransparent = createAction(
  SET_HEADER_BG_TRANSPARENT,
  function prepare(headerBgTransparent) {
    return {
      payload: {
        headerBgTransparent
      }
    };
  }
);

export const setHeaderSearchHover = createAction(
  SET_HEADER_SEARCH_HOVER,
  function prepare(headerSearchHover) {
    return {
      payload: {
        headerSearchHover
      }
    };
  }
);

export const setHeaderDrawerToggle = createAction(
  SET_HEADER_DRAWER_TOGGLE,
  function prepare(headerDrawerToggle) {
    return {
      payload: {
        headerDrawerToggle
      }
    };
  }
);

// Main content
export const setContentBackground = createAction(
  SET_CONTENT_BACKGROUND,
  function prepare(contentBackground) {
    return {
      payload: {
        contentBackground
      }
    };
  }
);

export const setThemeConfiguratorToggle = createAction(
  SET_THEME_CONFIGURATOR_TOGGLE,
  function prepare(themeConfiguratorToggle) {
    return {
      payload: {
        themeConfiguratorToggle
      }
    };
  }
);

// Footer
export const setFooterFixed = createAction(SET_FOOTER_FIXED, function prepare(
  footerFixed
) {
  return {
    payload: {
      footerFixed
    }
  };
});
export const setFooterShadow = createAction(SET_FOOTER_SHADOW, function prepare(
  footerShadow
) {
  return {
    payload: {
      footerShadow
    }
  };
});
export const setFooterBgTransparent = createAction(
  SET_FOOTER_BG_TRANSPARENT,
  function prepare(footerBgTransparent) {
    return {
      payload: {
        footerBgTransparent
      }
    };
  }
);

// Page title
export const setPageTitleStyle = createAction(
  SET_PAGE_TITLE_STYLE,
  function prepare(pageTitleStyle) {
    return {
      payload: {
        pageTitleStyle
      }
    };
  }
);
export const setPageTitleBackground = createAction(
  SET_PAGE_TITLE_BACKGROUND,
  function prepare(pageTitleBackground) {
    return {
      payload: {
        pageTitleBackground
      }
    };
  }
);
export const setPageTitleShadow = createAction(
  SET_PAGE_TITLE_SHADOW,
  function prepare(pageTitleShadow) {
    return {
      payload: {
        pageTitleShadow
      }
    };
  }
);
export const setPageTitleIconBox = createAction(
  SET_PAGE_TITLE_ICON_BOX,
  function prepare(pageTitleIconBox) {
    return {
      payload: {
        pageTitleIconBox
      }
    };
  }
);
export const setPageTitleDescription = createAction(
  SET_PAGE_TITLE_DESCRIPTION,
  function prepare(pageTitleDescription) {
    return {
      payload: {
        pageTitleDescription
      }
    };
  }
);
