import { Card, Divider, Grid } from '@material-ui/core';
import Filters from '../Filters';
import React, { useEffect, useState } from 'react';

import { Doughnut } from 'react-chartjs-2';

export default function PieChart({ cardsDetails }) {
  let [pieChartDetails, setPieChartDetails] = useState([]);
  const [allCards, setAllCards] = useState([]);
  useEffect(() => {
    if (allCards) {
      let formatedData = allCards.map((element) => {
        let labels = [];
        let arabicLabels = [];
        let data = [];
        let backgroundColor = [];
        element.count.forEach((i) => {
          labels.push(i.name);
          arabicLabels.push(i.arabic_name);
          data.push(i.count);
          backgroundColor.push(element.color[i.name]);
        });
        return {
          labels,
          arabicLabels,
          paragraphs: element.count,
          datasets: [
            {
              data,
              backgroundColor,
              hoverBackgroundColor: backgroundColor
            }
          ]
        };
      });
      setPieChartDetails(formatedData);
    }
  }, [allCards]);
  useEffect(() => {
    if (cardsDetails) setAllCards(cardsDetails);
  }, [cardsDetails]);

  return (
    <>
      {pieChartDetails.map((data, index) => {
        let FilterComponent = Filters[cardsDetails[index].filterComponent];
        return (
          <Card className={` mt-4`} style={{ width: '100%' }}>
            <Grid container>
              <React.Fragment key={index}>
                <Grid item md={6} className="mt-4 ml-4 mr-4">
                  <h6
                    className="font-weight-bold font-size-lg mb-1 text-black"
                    style={{ display: 'inline' }}>
                    {cardsDetails[index].title}
                  </h6>
                </Grid>
                <Grid item md={5} style={{ textAlign: 'left' }}>
                  {FilterComponent && (
                    <FilterComponent
                      setAllCards={setAllCards}
                      card={cardsDetails[index]}
                    />
                  )}
                </Grid>
                <hr className="mb-4" />
                <Grid item md={4} className="">
                  {data.paragraphs.map((element, index1) => {
                    return (
                      <React.Fragment key={index1}>
                        <h4 style={{ textAlign: 'left' }} className="ml-2">
                          {'(' +
                            element.arabic_name +
                            ')' +
                            '  ' +
                            element.name}
                        </h4>
                        <p style={{ textAlign: 'left' }} className="ml-4">
                          {element.count}
                        </p>
                      </React.Fragment>
                    );
                  })}
                </Grid>
                <Divider
                  style={{ backgroundColor: 'lightGray' }}
                  orientation="vertical"
                  flexItem
                />
                <Grid item md={7}>
                  <Doughnut data={data} />;
                </Grid>
              </React.Fragment>
            </Grid>
          </Card>
        );
      })}
    </>
  );
}
