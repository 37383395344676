import InputValidation from './InputValidation';
import SchemaFactory from './SchemaFactory';

class ValidationSchema {
  constructor(inputs, schemaType, isDraft) {
    //console.log('validation schema', inputs);
    this.inputs = inputs;
    this.schemaType = new SchemaFactory(schemaType);
    this.isDraft = isDraft;
  }

  checkValidation() {
    const schemaObj = {};
    const isDraft = this.isDraft;
    try {
      for (let input of this.inputs) {
        let pathParts = input.key.split('.');
        let schema = this.schemaType;
        const getPaths = function (schemaObj) {
          const element = pathParts.shift();
          if (pathParts.length === 0) {
            const inputValidation = new InputValidation(
              {
                ...input,
                key: element,
                name: input.name
              },
              schemaObj,
              schema,
              isDraft
            );
            inputValidation.validateInput();
            //{invoice_no:string, submissions:[{product_type,batchNumber, .... }]}
          } else {
            if (pathParts[0] === '[i]') {
              pathParts.shift();
              let itemsSchema;
              if (schemaObj[element]) {
                let extendedPart = schema.setObject(getPaths({}));
                itemsSchema = schema.extendObject(
                  extendedPart,
                  schema.getArrayItemSchema(schemaObj[element])
                );
              } else {
                itemsSchema = schema.setObject(getPaths({}));
              }
              const required = Object.entries(
                itemsSchema.fields
              ).some(([key, value]) => schema.checkRequired(value));

              schemaObj[element] = schema.setArray(itemsSchema);
              if (required && !isDraft) {
                schemaObj[element] = schema.setRequired(
                  schemaObj[element],
                  input,
                  isDraft
                );
              }
            } else {
              if (schemaObj[element]) {
                let extendedPart = schema.setObject(getPaths({}));
                schemaObj[element] = schema.extendObject(
                  extendedPart,
                  schemaObj[element]
                );
              } else {
                schemaObj[element] = schema.setObject(getPaths({}));
              }
            }
          }
          return schemaObj;
        };
        getPaths(schemaObj);
      }
      this.schema = schemaObj;
    } catch (error) {
      let errorMessage = error?.details?.reduce((msg, err) => {
        return `${msg && msg + 'and'}  ${err.message} `;
      }, '');
      console.log(error);
      throw errorMessage;
    }
  }

  extractFormSchema() {
    try {
      this.checkValidation(this.inputs, this.isDraft);
    } catch (error) {
      console.log('error', error);
    }
  }
}
export default ValidationSchema;
