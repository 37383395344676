import { createReducer } from '@reduxjs/toolkit';
import {
  GET_TASK_DETAILS_PENDING,
  GET_TASK_DETAILS_FULFILLED,
  GET_TASK_DETAILS_REJECTED,
  GET_TIMELINE_PENDING,
  GET_TIMELINE_FULFILLED,
  GET_TIMELINE_REJECTED,
  SAVE_CARD_ATTACHMENTS,
  GET_TASK_USERS_PENDING,
  GET_TASK_USERS_FULFILLED,
  GET_TASK_USERS_REJECTED,
  HANDLE_TASK_ASSIGNMENT_PENDING,
  HANDLE_TASK_ASSIGNMENT_FULFILLED,
  HANDLE_TASK_ASSIGNMENT_REJECTED
} from './detailsActionTypes';
import _ from 'lodash';
import { sanatizeObject } from '../../utils/SanatizaObject';
const initialState = {
  error: null,
  task: null,
  loading: false,
  taskLoading: false,
  attachments: [],
  success: null,
  timeline: [],
  users: []
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(GET_TASK_DETAILS_PENDING, (state, action) => {
    state.taskLoading = true;
    state.success = false;
  });
  builder.addCase(GET_TASK_DETAILS_FULFILLED, (state, action) => {
    state.task = sanatizeObject(action.payload.data.task);
    state.success = true;
    state.taskLoading = false;
  });
  builder.addCase(GET_TASK_DETAILS_REJECTED, (state, action) => {
    state.error = action.payload.error;
    state.success = false;
    state.taskLoading = false;
  });

  // builder.addCase(SUBMIT_TASK_PENDING, (state, action) => {
  //   state.loading = true;
  //   state.success = false;
  // });
  // builder.addCase(SUBMIT_TASK_FULFILLED, (state, action) => {
  //   state.task = action.payload.data.task;
  //   state.success = true;
  // });
  // builder.addCase(SUBMIT_TASK_REJECTED, (state, action) => {
  //   state.error = action.payload.error;
  //   state.success = false;
  // });

  builder.addCase(GET_TIMELINE_PENDING, (state, action) => {
    state.loading = true;
    state.success = false;
  });
  builder.addCase(GET_TIMELINE_FULFILLED, (state, action) => {
    state.timeline = action.payload.data;
    state.loading = false;
    state.success = true;
  });
  builder.addCase(GET_TIMELINE_REJECTED, (state, action) => {
    state.error = action.payload.error;
    state.success = false;
    state.loading = false;
  });

  builder.addCase(SAVE_CARD_ATTACHMENTS, (state, action) => {
    state.attachments = !_.isEmpty(action.payload.attachment)
      ? [
          ...state.attachments.filter(
            (attach) => action.payload.attachment.id !== attach.id
          ),
          action.payload.attachment
        ]
      : [...state.attachments];
  });
  //get users
  builder.addCase(GET_TASK_USERS_PENDING, (state, action) => {
    state.loading = true;
    state.success = false;
  });
  builder.addCase(GET_TASK_USERS_FULFILLED, (state, action) => {
    state.users = action.payload.data || [];
    state.success = true;
    state.loading = false;
  });
  builder.addCase(GET_TASK_USERS_REJECTED, (state, action) => {
    state.error = action.payload.error;
    state.success = false;
    state.loading = false;
  });
  //handle task  assignment
  builder.addCase(HANDLE_TASK_ASSIGNMENT_PENDING, (state, action) => {
    state.loading = true;
    state.success = false;
  });
  builder.addCase(HANDLE_TASK_ASSIGNMENT_FULFILLED, (state, action) => {
    state.success = true;
    state.loading = false;
  });
  builder.addCase(HANDLE_TASK_ASSIGNMENT_REJECTED, (state, action) => {
    state.error = action.payload.error;
    state.success = false;
    state.loading = false;
  });
});
export default reducer;
