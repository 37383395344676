import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout, setIsLoggedIn } from '../../reducers/auth';
import _ from 'lodash';
import { useCookies } from 'react-cookie';

const SidebarFooter = ({ user, redirectUrl, logout, clearStore }) => {
  let history = useHistory();
  const [removeCookie] = useCookies();

  const logoutHandler = async () => {
    Swal.fire({
      text: 'هل تريد تسجيل الخروج',
      icon: 'question',
      showDenyButton: true,
      confirmButtonText: `نعم`,
      denyButtonText: `لا`,
      confirmButtonColor: '#253053',
      denyButtonColor: '#25305338'
    }).then(function (result) {
      if (result.isConfirmed) {
        logout().then(() => {
          localStorage.removeItem('user');
          document.cookie = "browserupdateorg" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
          clearStore();
          // history.push(redirectUrl);
        });
      }
    });
  };

  return (
    <>
      <div className="app-sidebar--footer ">
        <Button variant="text" className="btn-exit " onClick={logoutHandler}>
          خروج
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    redirectUrl: state.auth.redirectUrl
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    clearStore: () => dispatch({ type: 'RESET_STORE', payload: null }),
    setIsLoggedIn: (data) => dispatch(setIsLoggedIn(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarFooter);
