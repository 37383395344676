import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  Button,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@material-ui/core';
import { handleDraftAssignment } from '../../reducers/form/formActions';
import { getUsers } from '../../reducers/details/detailsAction';
import client from '../../api/apiAuth/guestClient';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { handleTaskAssignment } from '../../reducers/details/detailsAction';
import { handleError } from '../../api/utilities';
import _ from 'lodash';

const SEND_AR_STRING = 'موافقة';
const DECLINE_AR_STRING = 'رفض';
const REINVESTIGATE_AR_STRING = 'اعادة بحث';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}));
function TableToolbar(props) {
  const classes = useToolbarStyles();
  const {
    selectedRows,
    history,
    setSubmitTaskLoader,
    user,
    currentTab,
    users,
    getUsers,
    handleTaskAssignment,
    handleDraftAssignment,
    setOpenModal
  } = props;
  let numSelected = selectedRows.length;

  const [selectedUser, setSelectedUser] = useState(false);
  let userRoles = user && user.roles.map((role) => role.name);

  const submitMultipleTasks = (filter, responseMessage, responseIcon) => {
    client.post('workflow/submittask', filter).then((res) => {
      setSubmitTaskLoader(false);
      Swal.fire({
        text: responseMessage,
        icon: responseIcon
      })
        .then(function (result) {
          history.push('/worklist');
        })
        .catch((error) => {
          handleError(error);
        });
    });
  };
  const reassingTasks = () => {
    const selectedTasks = selectedRows.map((row) => row.taskId);
    if ([5, 7].includes(currentTab)) {
      //reassign tasks
      handleTaskAssignment({
        filter: {
          task_status: 'Pending'
        },
        user_id: selectedUser,
        tasks: selectedTasks
      })
        .then(() => {
          setSubmitTaskLoader(false);
          Swal.fire({
            title: 'تم',
            text: 'لقد تم إعادة التعيين بنجاح ',
            icon: 'success',
            timer: 3000
          });
        })
        .catch((error) => {
          handleError(error);
        });
    } else if ([6].includes(currentTab)) {
      //reassign drafts
      handleDraftAssignment(
        {
          user_id: selectedUser
        },
        { draft_ids: selectedTasks }
      )
        .then(() => {
          setSubmitTaskLoader(false);
          Swal.fire({
            title: 'تم',
            text: 'لقد تم إعادة التعيين بنجاح ',
            icon: 'success',
            timer: 3000
          });
          window.location.reload();
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };
  const handleSendRequest = () => {
    setSubmitTaskLoader(true);
    if (userRoles.length > 0 && userRoles.includes('research manager')) {
      reassingTasks();
    } else {
      submitMultipleTasks(
        {
          transition_id: 13,
          tasks: selectedRows,
          assignTasksBeforeSubmit: true
        },
        'موافقه وإرسال إلى اﻻدارة المالية والارشيف',
        'success'
      );
    }
  };
  const handleRejectRequests = () => {
    setSubmitTaskLoader(true);
    if (currentTab == 0) {
      submitMultipleTasks(
        {
          transition_id: 6,
          tasks: selectedRows,
          assignTasksBeforeSubmit: true
        },
        'تم رفض الطلب بنجاح',
        'success'
      );
    } else {
      client
        .post('submission/stopServices', {
          requests: selectedRows
        })
        .then((res) => {
          setSubmitTaskLoader(false);
          Swal.fire({
            text: 'تم رفض الطلبات',
            icon: 'success'
          });
          // TODO: Refactor this into a proper react state update
          window.location.reload();
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };
  useEffect(() => {
    if (userRoles.length > 0 && userRoles.includes('research manager')) {
      //get available users to be assigned for drafts
      if ([5, 6].includes(currentTab)) {
        getUsers({
          include: [
            {
              association: 'roles',
              attributes: ['id', 'name'],
              through: {
                attributes: []
              },
              where: {
                id: 3
              }
            }
          ]
        });
        // get available users(administration officers) to be assigned for revision
      } else if (currentTab == 7) {
        getUsers({
          include: [
            {
              association: 'roles',
              attributes: ['id', 'name'],
              through: {
                attributes: []
              },
              where: {
                id: 2
              }
            }
          ],
          filter: { dept_id: user.dept_id }
        });
      }
    }
  }, [user, currentTab]);
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}>
      {numSelected > 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item md={5}>
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div">
                تم اختيار {numSelected}
              </Typography>
            </Grid>
            {/* available users */}
            <Grid item md={7} style={{ textAlign: 'left' }}>
              {[5, 6, 7].includes(currentTab) && (
                <FormControl
                  variant="outlined"
                  size="small"
                  className="ml-3"
                  style={{ width: '30%' }}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {'إعادة تعيين'}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    size="small"
                    fullWidth
                    value={selectedUser}
                    onChange={handleChange}
                    className="mt-0"
                    label="إعادة تعيين">
                    {users?.map((user) => (
                      <MenuItem value={user?.id} key={user?.id}>
                        {user.username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {/* send button */}
              {[0].includes(currentTab) && (
                <Button
                  className="btn btn-transition-none "
                  variant="contained"
                  color="primary"
                  disabled={[5, 6, 7].includes(currentTab) && !selectedUser}
                  onClick={() => {
                    setOpenModal(true);
                  }}>
                  اتخاذ اجراء
                </Button>
              )}
              {![10, 11, 13, 14, 16, 0].includes(currentTab) && (
                <Button
                  className="btn btn-transition-none "
                  variant="contained"
                  color="primary"
                  disabled={[5, 6, 7].includes(currentTab) && !selectedUser}
                  onClick={handleSendRequest}>
                  {SEND_AR_STRING}
                </Button>
              )}
              {/* reject button */}
              {[10, 11, 13, 14].includes(currentTab) && (
                <Button
                  style={{ backgroundColor: 'red' }}
                  className="btn btn-transition-none mr-2"
                  variant="contained"
                  color="secondary"
                  onClick={handleRejectRequests}>
                  {DECLINE_AR_STRING}
                </Button>
              )}
              {/* reinvestigation button */}
              {[16].includes(currentTab) && (
                <Button
                  style={{ backgroundColor: '#fcae31' }}
                  className="btn btn-transition-none mr-2"
                  variant="contained"
                  color="secondary"
                  onClick={handleReinvestigation}>
                  {REINVESTIGATE_AR_STRING}
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Toolbar>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    users: state.details.users
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(getUsers(params)),
    handleTaskAssignment: (params) => dispatch(handleTaskAssignment(params)),
    handleDraftAssignment: (params, data) =>
      dispatch(handleDraftAssignment(data, params))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TableToolbar);
