import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckIcon from '@material-ui/icons/Check';
import './style.css';
// import client from 'api/apiAuth/guestClient';
import Swal from 'sweetalert2';
import { Checkbox, Button } from '@material-ui/core';
import SimpleModal from '../SimpleModal';
import FormModal from '../FormModal';
import client from 'api/apiAuth/guestClient';

const redirectToEdit = (e, row, history) => {
  e.stopPropagation();
  if (row.submissionType == 'إعادة البحث')
    history.push(
      `/programs/${row.form_id}/submissions/${row.id}/reinvestigate`
    );
  else history.push(`/programs/${row.form_id}/submissions/${row.id}/edit`);
};

const convertToArabicNumbers = (number) => {
  return number?.replace(/\d/g, (d) => '٠١٢٣٤٥٦٧٨٩'[d]);
};

function ColumnsRepresentation({
  cellType,
  index,
  value,
  color,
  history,
  row,
  // setSubmitTaskLoader,
  column,
  selectedRows,
  setSelectedRows,
  setSelectedCheckbox,
  SelectedCheckbox,
  rowIndex,
  currentTab,
  openModal,
  setOpenModal
}) {

  const [openActionModal, setOpenActionModal] = useState(false);

  const toggleCheckboxValue = (e, selectedIndex, selectedTask, taskStep, transitions) => {
    e.stopPropagation();
    let selectedTasks = [...selectedRows];

    if (SelectedCheckbox[selectedIndex]) {
      //when uncheck row,remove it from selected rows
      selectedTasks = selectedTasks.filter(
        (task) => task.taskId !== selectedTask
      );
      setSelectedRows([...selectedTasks]);
    } else {
      if (selectedRows.length >= 10) {
        Swal.fire({
          text: 'الحد اﻻقصى لعدد الطلبات المرسله 10 طلبات ',
          icon: 'error',
          timer: 3000,
          dangerMode: true
        });
        return;
      } else {
        selectedTasks.push({ taskId: selectedTask, taskStepId: taskStep, transitions });
        setSelectedRows([...selectedTasks]);
        //setOpenModal(true);
      }
    }
    setSelectedCheckbox([
      ...SelectedCheckbox.map((item, checkboxIndex) =>
        checkboxIndex === selectedIndex ? !item : item
      )
    ]);
  };

  const softDeleteDraft = async (selectedTask) => {
       await Swal.fire({
        title: 'هل انت متأكد؟',
        text: 'سوف يتم مسح الطلب',
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: `بالطبع`,
        denyButtonText: `لا`,
        confirmButtonColor: '#253053',
        denyButtonColor: '#25305338'
      }).then(async function (result) {
        if (result.isConfirmed) {
          await client.get('/submission/softDeleteDraft', {
          params: {
            draft_id: selectedTask
          }
        });
          window.location.reload();
        } 
      });
  };
  
  const deleteSubmission = async (selectedTask) => {
       await Swal.fire({
        title: 'هل انت متأكد؟',
        text: 'سوف يتم مسح الطلب',
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: `بالطبع`,
        denyButtonText: `لا`,
        confirmButtonColor: '#253053',
        denyButtonColor: '#25305338'
      }).then(async function (result) {
        if (result.isConfirmed) {
          await client.get('/submission/deleteSubmission', {
          params: {
            submission_id: selectedTask
          }
        });
          window.location.reload();
        } 
      });
  };

  try {
    switch (column.itype) {
      case 'integer':
        value = !isNaN(value) ? value?.toLocaleString('ar-EG') : value;
        break;
      case 'string':
        value = !isNaN(value) ? convertToArabicNumbers(value) : value;
        break;
      case 'date':
        value = value ? new Date(value).toLocaleDateString('ar-EG') : value;
        break;
      default:
        break;
    }
  } catch (err) {
    value = null;
  }

  const cells = {
    text: (
      <TableCell key={index}>
        <div className={`d-flex  ${color ? color : ''}`}>
          <div className="align-center">{value}</div>
        </div>
      </TableCell>
    ),
    number: (
      <TableCell key={index}>
        <div className={`d-flex  ${color ? color : ''}`}>
          <div className="align-center">{value}</div>
        </div>
      </TableCell>
    ),

    image: (
      <TableCell key={index} className="text-center">
        <div
          className="avatar-icon-wrapper avatar-icon-sm"
          title="Lili Pemberton">
          <div className="avatar-icon">
            <img alt="..." src={value} />
          </div>
        </div>
      </TableCell>
    ),
    badge: (
      <TableCell key={index} className="text-center">
        <div className={`badge light`}>{value}</div>
      </TableCell>
    ),
    date: (
      <TableCell key={index} className="text-center text-black-50">
        {value}
      </TableCell>
    ),
    editIcon: (
      <TableCell
        className="text-center"
        key={index}
        onClick={(e) => redirectToEdit(e, row, history)}>
        <EditIcon className="edit-icon" />
      </TableCell>
    ),
    checkIcon: (
      <TableCell className="text-center" key={index}>
        <Checkbox
          // name={}
          checked={SelectedCheckbox[rowIndex] || false}
          onClick={(e) =>
            toggleCheckboxValue(e, rowIndex, row.taskId || row.id, row?.step_id, row.step.transitions)
          }
        />
        {openModal && [0, 10, 11].includes(currentTab) && (
          <SimpleModal
            open={openModal}
            setOpen={setOpenModal}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            taskId={row.taskId || row.id}
            transitions={selectedRows[0].transitions}
            history={history}
          />
        )}
      </TableCell>
    ),
    button: (
      <TableCell
        className={"sticky"}
        key={index}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <Button
          onClick={(e) => {
            setOpenActionModal(true);
          }}
          className="btn-transition-none mb-4  btn-lg float-left"
          variant="contained"
          color="primary"
          size="small">
          {'اتخاذ اجراء'}
        </Button>
        {openActionModal && (
          <FormModal
            open={openActionModal}
            setOpen={setOpenActionModal}
            transitions={row.step.transitions}
            assignTasksBeforeSubmit={true}
            taskStepId={row.step_id}
            submission={row}
            lastComment={row.last_task.comment}
            task_id={row.taskId}
            modalSize={'lg'}
          />
        )}
      </TableCell>
    ),
    approveIcon: (
      <TableCell
        className="text-center"
        key={index}
        // onClick={(e) => approveTask(e, row, history, setSubmitTaskLoader)}
      >
        <CheckIcon />
      </TableCell>
    ),
    deleteDraftIcon: (
      <TableCell 
        className="text-center"
        key={index}
        onClick={(e) => {
          e.stopPropagation();
        }}
        >
          <Button
            style={{ color: 'red' }}
            className="btn-transition-none mb-4  btn-lg float-left"
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => softDeleteDraft(row.taskId || row.id)}
            >
            <DeleteForeverIcon />
            <span style={{color: 'white'}}> حذف </span>
          </Button>
      </TableCell>
    ),
    deleteSubmissionIcon: (
      <TableCell 
        className="text-center"
        key={index}
        onClick={(e) => {
          e.stopPropagation();
        }}
        >
          <Button
            style={{ color: 'red' }}
            className="btn-transition-none mb-4  btn-lg float-left"
            variant="contained"
            color="primary"
            size="small"
            onClick={(e) => deleteSubmission(row.taskId || row.id)}
            >
            <DeleteForeverIcon />
            <span style={{color: 'white'}}> حذف </span>
          </Button>
      </TableCell>
    )
  };
  return <>{cells[cellType]}</>;
}
export default ColumnsRepresentation;
