import React, { useState, useEffect, useRef } from 'react';

import Tables13 from '../../components/Table';

import {
  fetchWorklist,
  fetchWorklistLayout,
  setLastTab
} from '../../reducers/list';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import clsx from 'clsx';
import './style.css';
import TableTabs from '../../components/TableTabs';
import SpinnerLoader from '../../components/SpinnerLoader';
import { apiConfig } from '../../api/utilities';
import qs from 'qs';
import useSound from 'use-sound';
import soundAlert from '../../assets/lyrebird.mp3';
import DownloadButton from 'components/DetailsComponents/DownloadButton';

const getDefaultTab = (user) => {
  if (user && user.roles.some((role) => role.name === 'super admin')) {
    return 2;
  }
  return 0;
};

function WorkList(props) {
  let {
    getWorklist,
    tasks,
    tasksTotalCount,
    getLayout,
    columns,
    user,
    lastTab,
    setLastTab
  } = props;
  const [layoutStructure, setLayoutStructure] = useState([]);
  const [layoutData, setLayoutData] = useState();
  const [filters, setFilters] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [submitTaskLoader, setSubmitTaskLoader] = useState(false);
  const [delayedSubmissions, setDelayedSubmissions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentRole, setCurrentRole] = useState(
    localStorage.getItem('userRole')
  );
  const [roleChangeLoader, setRoleChangeLoader] = useState(false);
  const [roleChangedFlag, setRoleChangedFlag] = useState(false);
  const [sort, setSort] = useState({ type: '', order: 'none', orderBy: '' });
  const excelDownload = useRef(null);
  const [playSound] = useSound(soundAlert);
  let savedTab =
    JSON.parse(localStorage.getItem('activeTab')) || getDefaultTab(user);
  const [currentTab, setCurrentTab] = useState(savedTab);
  const [activeTab, setActiveTab] = useState(lastTab || currentTab);
  let savedRole = localStorage.getItem('userRole');
  useEffect(() => {
    setActiveTab(lastTab || currentTab);
  }, [lastTab, currentTab]);
  useEffect(() => {
    if (user)
      user.roles.some((role) => role.name == savedRole)
        ? setCurrentRole(localStorage.getItem('userRole') || user.roles[0].name)
        : setCurrentRole(user.roles[0].name);
  }, [user]);

  useEffect(() => {
    setLayoutStructure(columns);
    setLayoutData(tasks);
  }, [tasks, columns]);

  useEffect(() => {
    setPage(1);
  }, [filters, limit, sort]);

  useEffect(() => {
    getWorklist({
      filters,
      page,
      limit,
      sort,
      currentTab: activeTab,
      currentRole
    });
  }, [filters, page, limit, sort, activeTab]);

  useEffect(() => {
    if (roleChangedFlag) {
      if (currentRole) {
        localStorage.setItem('userRole', currentRole);
      }
      getWorklist({
        filters,
        page,
        limit,
        sort,
        currentTab: activeTab,
        currentRole
      })
        .then(() => {
          setRoleChangeLoader(false);
        })
        .catch((err) => {
          setRoleChangeLoader(false);
        });
    }
  }, [currentRole]);

  useEffect(() => {
    getLayout({ model: 'Task' }).catch((err) => {});
  }, []);
  useEffect(() => {
    if (tasks)
      setDelayedSubmissions(
        tasks.filter((task) => {
          return task.due_date && new Date() - new Date(task.due_date) > 0;
        })
      );
  }, [tasks]);
  useEffect(() => {
    if (
      [0, 1].includes(currentTab) &&
      delayedSubmissions &&
      delayedSubmissions.length > 0 &&
      delayedSubmissions.every((submission) =>
        submission.users.some((taskUser) => taskUser.id == user.id)
      )
    ) {
      setOpenDialog(true);
      // playSound();
    }
  }, [delayedSubmissions]);
  const handleRoleChange = (e) => {
    setRoleChangeLoader(true);
    setRoleChangedFlag(true);
    setActiveTab(getDefaultTab(user));
    setCurrentRole(e.target.value);
  };
  return (
    <>
      <Grid container>
        <Grid
          item
          container
          lg={12}
          md={12}
          xs={12}
          style={{ justifyContent: 'flex-end' }}>
          <Grid item md={2} lg={2} xs={2}>
            {
              <DownloadButton
                buttonLabel={'اصدار ملف أكسل'}
                btnHref={`${
                  apiConfig.baseUrl
                }tasks/exportExcelForTab?${qs.stringify({
                  limit,
                  page,
                  filters,
                  sort,
                  currentTab,
                  currentRole
                })}`}
                btnRef={excelDownload}
                btnClasses={'btn btn-transition-none mb-4 mr-2 '}
                btnStyle={{ backgroundColor: '#3F54B1', color: 'white' }}
              />
            }
          </Grid>
          <Grid item md={2} lg={2} xs={2}>
            {
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">
                  {'تغيير الدور'}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={currentRole ?? ''}
                  onChange={(e) => handleRoleChange(e)}
                  className="mt-0"
                  label={'تغيير الدور'}>
                  {user?.roles?.map((item) => (
                    <MenuItem value={item?.name} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} xs={12} className="mt-1">
          {roleChangeLoader ? (
            <Grid item lg={12} md={12} xs={12}>
              <SpinnerLoader style={{ height: '300px' }} />
            </Grid>
          ) : (
            <>
              <TableTabs
                setFilters={setFilters}
                setSort={setSort}
                setCurrentTab={setCurrentTab}
                setLayoutData={setLayoutData}
                setLastTab={setLastTab}
                currentRole={currentRole}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tableName={'worklist'}
                fetchCountOnRoleChange={true}
              />
              {submitTaskLoader ? (
                <Grid item lg={12} md={12} xs={12}>
                  <SpinnerLoader style={{ height: '300px' }} />
                  <div style={{ textAlign: 'center' }}>
                    <small>برجاء اﻻنتظار سيتم ارسال الطلب خلال دقائق</small>
                  </div>
                </Grid>
              ) : (
                <div
                  className={clsx('tab-item-wrapper', {
                    active: true
                  })}
                  style={{ overflow: 'hidden' }}
                  index={0}>
                  <Tables13
                    className="mt-4"
                    layoutStructure={layoutStructure}
                    setLayoutStructure={setLayoutStructure}
                    layoutData={layoutData}
                    currentTab={
                      [null, undefined].includes(currentTab)
                        ? lastTab
                        : currentTab
                    }
                    setPage={setPage}
                    setLimit={setLimit}
                    limit={limit}
                    setSort={setSort}
                    tasksCount={tasksTotalCount}
                    filters={filters}
                    setFilters={setFilters}
                    setSubmitTaskLoader={setSubmitTaskLoader}
                    page={page}
                    applyFilter={true}
                    currentRole={currentRole}
                    activeTab={activeTab}
                  />
                </div>
              )}
            </>
          )}
        </Grid>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <h1 style={{ textAlign: 'center' }} className="text-danger">
                تحذير
              </h1>
              <h5 style={{ textAlign: 'center' }}>
                {'الطلبات التالية تأخرت '}
              </h5>
              <ol style={{ padding: 20 }}>
                {delayedSubmissions?.map((submission) => {
                  return (
                    <li style={{ marginBottom: 8 }}>
                      <a
                        href={`${window.location.origin}/details/${submission.id}`}>
                        {`كود الطلب : ${submission.file_code} / برنامج : ${submission.form.name}`}
                      </a>
                    </li>
                  );
                })}
              </ol>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDialog(false)}
              color="primary"
              autoFocus>
              حسنا
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    tasks: state.list?.tasks,
    tasksTotalCount: state.list?.tasksCount,
    user: state.auth.user,
    columns: state.layout.columns,
    lastTab: state.layout.lastTab
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWorklist: (params) => dispatch(fetchWorklist(params)),
    getLayout: (params) => dispatch(fetchWorklistLayout(params)),
    setLastTab: (params) => dispatch(setLastTab(params))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkList);
