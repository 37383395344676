import { createAction } from '@reduxjs/toolkit';
import { SET_ERROR_MESSAGE, SET_ERROR_PAGE } from './errorActionTypes';

export const setErrorMessage = createAction(SET_ERROR_MESSAGE, function prepare(
  errorMessage
) {
  return {
    payload: {
      errorMessage
    }
  };
});

export const setErrorPage = createAction(SET_ERROR_PAGE, function prepare(
  errorPage
) {
  return {
    payload: {
      errorPage
    }
  };
});
