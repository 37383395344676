import { setInputKey } from 'components/FormSteps/utils';
import React from 'react';
import { displayInputWidget } from '../../FormSteps/getStepContent';

function Container({
  inputs,
  formik,
  randomId,
  arrayIndexes,
  submission,
  sectionIndex,
  groupIdx,
  stepId,
  isMany,
  setAlertMessage,
  extraData,
  setInitialValues,
  actionType
}) {
  return (
    <>
      {inputs?.map((input, index) => (
        <React.Fragment key={input.key + index}>
          {displayInputWidget({
            input: {
              ...input,
              key: setInputKey(input, arrayIndexes)
            },
            formik,
            sectionIndex,
            groupIdx,
            index,
            isTransition: false,
            submission,
            step: { id: stepId, isMany },
            randomId,
            extraData,
            setAlertMessage,
            setInitialValues,
            actionType
          })}
        </React.Fragment>
      ))}
    </>
  );
}

export default Container;
