export const FETCH_WORKLST = 'FETCH_WORKLST';
export const FETCH_WORKLST_PENDING = 'FETCH_WORKLST_PENDING';
export const FETCH_WORKLST_FULFILLED = 'FETCH_WORKLST_FULFILLED';
export const FETCH_WORKLST_REJECTED = 'FETCH_WORKLST_REJECTED';

export const FETCH_WORKLST_LAYOUT = 'FETCH_WORKLST_LAYOUT';
export const FETCH_WORKLST_LAYOUT_PENDING = 'FETCH_WORKLST_LAYOUT_PENDING';
export const FETCH_WORKLST_LAYOUT_FULFILLED = 'FETCH_WORKLST_LAYOUT_FULFILLED';
export const FETCH_WORKLST_LAYOUT_REJECTED = 'FETCH_WORKLST_LAYOUT_REJECTED';

export const GET_SUBMISSIONS_IDS = 'GET_SUBMISSIONS_IDS';
export const GET_SUBMISSIONS_IDS_PENDING = 'GET_SUBMISSIONS_IDS_PENDING';
export const GET_SUBMISSIONS_IDS_FULFILLED = 'GET_SUBMISSIONS_IDS_FULFILLED';
export const GET_SUBMISSIONS_IDS_REJECTED = 'GET_SUBMISSIONS_IDS_REJECTED';

export const FETCH_FORMS = 'FETCH_FORMS';
export const FETCH_FORMS_PENDING = 'FETCH_FORMS_PENDING';
export const FETCH_FORMS_FULFILLED = 'FETCH_FORMS_FULFILLED';
export const FETCH_FORMS_REJECTED = 'FETCH_FORMS_REJECTED';

export const FETCH_ReqStage = 'FETCH_ReqStage';
export const FETCH_ReqStage_PENDING = 'FETCH_ReqStage_PENDING';
export const FETCH_ReqStage_FULFILLED = 'FETCH_ReqStage_FULFILLED';
export const FETCH_ReqStage_REJECTED = 'FETCH_ReqStage_REJECTED';

export const SET_LAST_TAB = 'SET_CURRENT_TAB';
