import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@material-ui/core';
import { ClipLoader } from 'react-spinners';

import client from '../../api/apiAuth/guestClient';
import IndexedDB from 'utils/indexedDB';
import { sleep } from 'utils/helper';

const SUBMISSION_STATUS = { saved: 1, uploaded: 2, failed: 3, pending: 4 };
const SUBMISSION_STATUS_STRING = {
  1: 'محفوظ فى المخزن',
  2: 'تم الرفع إلى قاعدة البيانات',
  3: 'فشل رفع الملف إلى قاعدة البيانات',
  4: 'جارى رفع الملف'
};

let LOCK = false;

const SubmissionStorage = (props) => {
  const [submissions, setSubmissions] = useState([]);
  useEffect(() => {
    const fetchSubmissionsAsync = async () => {
      const dbSubmissions = await IndexedDB.getAllSubmissions(true);
      setSubmissions(
        dbSubmissions.map((submission) => {
          return {
            ...submission.value,
            key: submission.key,
            sub_status: SUBMISSION_STATUS.saved
          };
        })
      );
    };
    fetchSubmissionsAsync();
  }, []);

  useEffect(() => {
    LOCK = false;
  }, [submissions]);

  const updateSubmissionStatus = (index, newStatus) => {
    setSubmissions((oldSubmissions) => {
      const newSubmissions = [...oldSubmissions];
      const newSubmission = { ...newSubmissions[index] };
      newSubmission.sub_status = newStatus;
      newSubmissions[index] = newSubmission;
      return newSubmissions;
    });
  };

  const uploadSubmissions = () => {
    const uploadData = async () => {
      for (let i = 0; i < submissions.length; i++) {
        try {
          updateSubmissionStatus(i, SUBMISSION_STATUS.pending);
          const newAttachments = [];
          for (const attachment of submissions[i].attachments) {
            let formData = new FormData();
            formData.append(
              'avatar',
              new File([attachment.buffer], attachment.data.name, {
                type: attachment.data.type
              })
            );

            const response = await client.post(
              'attachment/uploadFile',
              formData
            );
            newAttachments.push({
              ...response.data.results,
              input_id: attachment.input_id,
              name: attachment.name,
              contact_id: attachment.contact_id,
              original_file_name: attachment.original_file_name,
              section: attachment.section,
              sectionName: attachment.sectionName,
              metadata: {
                ...response.data.results.metadata,
                type: attachment.type,
                preview: attachment.preview
              }
            });
          }
          const response = await client.post('workflow/startEngine', {
            ...submissions[i],
            attachments: newAttachments
          });
          updateSubmissionStatus(i, SUBMISSION_STATUS.uploaded);
          IndexedDB.removeSubmission(submissions[i].key);
        } catch (error) {
          LOCK = true;
          updateSubmissionStatus(i, SUBMISSION_STATUS.failed);
          while (LOCK) await sleep(250);
          continue;
        }
      }
    };
    uploadData();
  };

  const clearSubmissions = () => {
    try {
      IndexedDB.clearSubmissions();
      setSubmissions([]);
    } catch (err) {
      console.log(
        '[SubmissionStorage] Something went wrong while clearing submissions'
      );
    }
  };

  const renderSubmissionStatus = (status) => {
    if (status === SUBMISSION_STATUS.pending) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ color: 'orange' }}>
          <span>{SUBMISSION_STATUS_STRING[status]}</span>
          <span className="mr-3">
            <ClipLoader color="orange" />
          </span>
        </div>
      );
    } else if (status === SUBMISSION_STATUS.uploaded) {
      return (
        <span style={{ color: 'green' }}>
          {SUBMISSION_STATUS_STRING[status]}
        </span>
      );
    } else if (status === SUBMISSION_STATUS.failed) {
      return (
        <span style={{ color: 'red' }}>{SUBMISSION_STATUS_STRING[status]}</span>
      );
    }
    return <span>{SUBMISSION_STATUS_STRING[status]}</span>;
  };

  return (
    <Grid>
      <TableContainer component={Paper}>
        <Table style={{ direction: 'ltr' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>حالة الملف</TableCell>
              <TableCell style={{ textAlign: 'center' }}>رقم البطاقة</TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                اسم المستفيد
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>رقم الملف</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {submissions.map((item, index) => (
              <TableRow style={{ direction: 'ltr' }} key={item.key}>
                <TableCell style={{ textAlign: 'center' }}>
                  {renderSubmissionStatus(item.sub_status)}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {item.primaryFields.national_id.toLocaleString('ar-SA')}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {item.primaryFields.first_name +
                    item.primaryFields.family_name}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>{index}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="mt-3 d-flex justify-content-around">
        <Button variant="contained" color="primary" onClick={uploadSubmissions}>
          رفع إلى قاعدة البيانات
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: 'red', color: 'white' }}
          onClick={clearSubmissions}>
          إزالة جميع الملفات من المخزن
        </Button>
      </div>
    </Grid>
  );
};

export default SubmissionStorage;
