import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Input,
  makeStyles,
  Radio,
  RadioGroup,
  Tooltip,
  MenuItem,
  Grid,
  ListItem
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';
import Select from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import {
  setNationalIds,
  setSubmission,
  uploadFile
} from 'reducers/form/formActions';
import client from 'api/apiAuth/guestClient';
import _ from 'lodash';
import { pdfjs } from 'react-pdf';
import PdfViewer from 'components/PdfViewer';
import FileUploader from 'components/FileUploader';
import { handleDocument } from '../../utils/uploadFile';
import ImageViewer from '../ImageViewer';
import ErrorMessage from '../ErrorMessage';
import getDOF, { calculateAge, getGender } from 'utils/ExtractDataFromId';
import { handleError } from 'api/utilities';
import WysiwygEditor from 'components/WysiwygEditor';
// import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CameraModal from 'components/CameraModal';
import { sanatizeObject } from 'utils/SanatizaObject';
import { BootstrapInput } from 'utils/BootstrapInput';
import GOVERNORATE_CODES from '../../assets/jsondata/governorates_codes';
import { isOnline } from './utils';
import IndexedDB from '../../utils/indexedDB';
import { applyInputConstraints } from 'utils/LayoutConstraints/ConstraintsService';
import Container from 'components/FormComponents/Container';
import BooleanCheckbox from 'components/FormComponents/BooleanCheckbox/BooleanCheckbox';
import { DecreaseRequestsNo } from 'reducers/general/generalActions';

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: 'white',
    textIndent: '10px',
    border: '1px solid #dcd7d7',
    borderRadius: '0.4rem !important'
  },
  documentTypography: {
    backgroundColor: 'white',
    border: '1px solid #dcd7d7',
    borderRadius: '0.4rem 0 0 0.4rem   !important',
    padding: '6px 0 6px',
    textIndent: '10px',
    minWidth: '60%',
    maxWidth: '65%',
    color: '#a09d9d',
    fontSize: '.7em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  uploadButton: {
    padding: '8px ',
    height: '34px',
    fontSize: '.7em',
    minWidth: '17% !important',
    '&:hover': {
      margin: '0 !important'
    },
    borderRadius: ' 0% !important'
  },
  label: {
    color: '#1f253adb'
  }
}));

const getValue = (obj, path, inputType) => {
  if (path) {
    path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexepaths to propertis
    path = path.replace(/^\./, ''); // strip a leading dot
    let value = path.split('.').reduce((o, i) => {
      if (o) {
        // if (o[i] === true || o[i] === false) return o[i].toString();
        if (inputType === 'date') {
          return typeof o[i] !== 'object'
            ? new Date(o[i]).toLocaleDateString('en-CA')
            : o[i];
        }
        // else if (o[i] === false) o[i] = 'false';
        return o[i];
      }
    }, obj);
    return value;
  }
};
const getOldSubmission = async (
  national_id,
  setInitialValues,
  inputName,
  oldValues = {},
  setSubmission,
  actionType,
  extraData,
  setAlertMessage,
  formik,
  currentSection,
  stepId,
  form
) => {
  if (!isOnline(extraData)) return;
  try {
    if (!['reinvestigate', 'edit'].includes(actionType)) {
      let primaryFieldsSectionId = form.sections.find(
        (x) => x.key == 'primaryFields'
      ).id;
      let result = await client.get('/submission/getOldSubmission', {
        params: {
          national_id,
          stepId,
          formName: form.name,
          sectionId: primaryFieldsSectionId
        }
      });
      if (result.data.message) {
        setAlertMessage({
          message: result.data.message,
          submissionData: result.data.allSubmissions
        });
      }
      const oldSubmission = result.data.oldSubmission;
      const attachments = result.data.attachments;
      if (!_.isEmpty(oldSubmission)) {
        // Remove null primaryFields to prevent validation errors
        const initialValues = sanatizeObject(oldSubmission);
        if (!_.isEmpty(initialValues)) {
          const primaryFields = {
            ...oldValues['primaryFields'],
            ...initialValues['primaryFields']
          };
          setInitialValues({ ...oldValues, ...initialValues, primaryFields });
          if (attachments) setSubmission({ attachments });
        }
      } else {
        await extractContactDataFromNationalId(
          formik,
          oldValues.primaryFields.national_id,
          currentSection,
          inputName
        );
        // setInitialValues({ ...formik.values });
        if (attachments) setSubmission({ attachments });
      }
    }
  } catch (error) {
    handleError(error);
  }
};

const updateDocument = (
  submission,
  nationalId,
  setSubmission,
  currentSection,
  contactsSection,
  InputName
) => {
  let attachments =
    !_.isEmpty(submission) && !_.isEmpty(submission.attachments)
      ? [...submission.attachments]
      : [];
  if (currentSection) {
    //change contact id for documents related to the relatives and friends sections
    attachments = attachments.map((attachment) => {
      let attachmentObj = { ...attachment };
      if (attachment.name.includes(InputName)) {
        attachmentObj['contact_id'] = nationalId;
      }
      return attachmentObj;
    });
  } else {
    //change contact id for all documents related to requester
    attachments =
      nationalId && attachments.length > 0
        ? attachments.map((attachment) => {
            let attachmentObj = { ...attachment };
            if (!contactsSection.includes(attachment.sectionName)) {
              attachmentObj['contact_id'] = nationalId;
            }
            return attachmentObj;
          })
        : attachments;
  }
  attachments.length > 0 && setSubmission({ ...submission, attachments });
};

const checkDuplicationInNationalIds = (
  formik,
  InputName,
  inputValue,
  nationalIds,
  setNationalIds,
  setIdError
) => {
  if (nationalIds.find((input) => input.national_id == inputValue)) {
    if (
      nationalIds.find(
        (input) =>
          input.InputName != InputName && input.national_id == inputValue
      )
    ) {
      _.set(formik.errors, InputName, "غير مسموح بتكرار الرقم القومى'");
      setIdError('غير مسموح بتكرار الرقم القومى');
      setNationalIds(inputValue, InputName);
    }
  } else {
    setNationalIds(inputValue, InputName);
    let flag = _.get(formik.errors, InputName);
    setIdError('');
    if (flag) delete formik.errors[InputName.split('.')[0]];
  }
};

const extractContactDataFromNationalId = async (
  formik,
  inputValue,
  currentSection,
  InputName
) => {
  if (inputValue && inputValue.length !== 14) return;
  let birthDate = getDOF(inputValue);
  const gender = getGender(inputValue);
  let inputSection;
  currentSection == 'relatives'
    ? (inputSection = InputName.split('.')[0] + '.' + InputName.split('.')[1])
    : (inputSection = currentSection);

  formik.setFieldValue(`${inputSection}.age`, calculateAge(birthDate));
  birthDate = birthDate?.toLocaleDateString();
  if (currentSection == 'primaryFields')
    formik.setFieldValue(`${inputSection}.birth_date`, birthDate);

  if (gender) {
    formik.setFieldValue(`${inputSection}.gender`, gender);
  }
  const code = inputValue.substring(7, 9);
  if (code in GOVERNORATE_CODES) {
    const govern = GOVERNORATE_CODES[code];
    formik.setFieldValue(`${inputSection}.governorate`, govern);
  }
};

function InputWidget(props) {
  let {
    input,
    formik,
    uploadFile,
    enableSubmit,
    setInitialValues,
    submission,
    setSubmission,
    setNationalIds,
    actionType,
    formType,
    extraData,
    setAlertMessage,
    isMany,
    submissionValue,
    groupIdx,
    arrayIndexes,
    sectionIndex,
    stepId,
    nationalIds,
    form,
    DecreaseRequestsNo
  } = props;
  const [file, setFile] = useState([]);
  const [openDocument, setOpenDocument] = useState(false);
  const [openCameraModal, setOpenCameraModal] = useState(false);
  const [idError, setIdError] = useState('');
  const classes = useStyles();
  const fileInput = useRef(null);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const inputValue = getValue(formik.values, input.key, input.itype);
  const inputError = getValue(formik.errors, input.key);
  const inputTouched = getValue(formik.touched, input.key);
  const [rejectedFileErrorMessage, setRejectedFileErrorMessage] = useState([]);

  useEffect(() => {
    if ('initialValue' in input.input_layout) {
      if (!inputValue) {
        formik.setFieldValue(input.key, input.input_layout.initialValue);
      }
    }
  }, []);

  useEffect(() => {
    if (!isOnline(extraData) && file?.data) {
      var reader = new FileReader();
      reader.readAsArrayBuffer(file.data);
      reader.onload = function (e) {
        IndexedDB.addTemporaryAttachment({
          buffer: e.target.result,
          ...file
        });
      };
    }
    if (isOnline(extraData) && file?.data) {
      uploadFile(file).catch((error) => {
        DecreaseRequestsNo();
        formik.setFieldValue(input.key, '');
      });
    }
  }, [file]);

  const showDocument = () => {
    setOpenDocument(true);
  };
  const handleNationalIdChange = async (
    e,
    InputName,
    inputValue,
    contactsSection,
    sectionName,
    currentSection,
    setInitialValues,
    nationalIds,
    setNationalIds,
    setIdError
  ) => {
    if (InputName.includes('national_id') && inputValue) {
      checkDuplicationInNationalIds(
        formik,
        InputName,
        inputValue,
        nationalIds,
        setNationalIds,
        setIdError
      );
      await extractContactDataFromNationalId(
        formik,
        inputValue,
        currentSection,
        InputName
      );
    }
    //update contact id for all documents related to the requester
    if (InputName === 'primaryFields.national_id' && e.target.value !== '') {
      updateDocument(
        submission,
        inputValue,
        setSubmission,
        null,
        contactsSection,
        InputName
      );
      await getOldSubmission(
        inputValue,
        setInitialValues,
        InputName,
        formik.values,
        setSubmission,
        actionType,
        extraData,
        setAlertMessage,
        formik,
        currentSection,
        props.stepId,
        form
      );
    }
    //update contact id for documents related to relatives and friends
    else if (
      (InputName.includes('national_id') ||
        InputName.includes('passport_id')) &&
      contactsSection.includes(currentSection)
    ) {
      updateDocument(
        submission,
        inputValue,
        setSubmission,
        currentSection,
        null,
        InputName
      );
    }
  };

  const handleOthersOption = (array) => {
    let lastItem;
    const newArray = array.filter((item, index) => {
      if (
        ['أخري', 'أخرى', 'اخري', 'اخرى'].includes(item.data_value) &&
        index !== array.length - 1
      ) {
        lastItem = item;
      } else {
        return true;
      }
    });
    lastItem && newArray.push(lastItem);
    return newArray;
  };
  const checkConditionalProps = () => {
    const regex = /[.][0-9]*[.]/g;
    const numParts = input.key.match(regex);
    if (!_.isEmpty(input.dependency)) {
      const constraints = input.dependency.reduce((constraints, element) => {
        let newKey;
        const conditions = element.conditions.map((condition) => {
          newKey = isMany ? condition.key : condition.single_key;
          if (numParts)
            numParts.forEach((element) => {
              newKey = (isMany ? condition.key : condition.single_key).replace(
                '.[i].',
                element
              );
            });

          return {
            ...condition,
            key: newKey,
            actualValue:
              getValue(formik.values, newKey) ||
              getValue(submissionValue, newKey)
          };
        });

        let result = {
          ...constraints,
          ...applyInputConstraints(
            {
              ...element,
              conditions
            },
            'layout'
          )
        };

        if (!Object(result).hasOwnProperty('display')) result.display = true;
        if (
          !Object(result).hasOwnProperty('display') &&
          Object(result).hasOwnProperty('disable')
        )
          result.display = true;
        if (
          input.dependency.find((dependent) =>
            dependent.constraints?.validation?.hasOwnProperty('dependent')
          )
        ) {
          constraints.required = true;
          //FIXME:
          constraints.display = true;

          let validation = {
            ...constraints,
            ...applyInputConstraints(
              {
                ...element,
                conditions
              },
              'validation'
            )
          };
          result = {
            ...result,
            ...validation
          };
        }

        if (element.constraints?.enumValues?.length && result.enumValues) {
          if (!constraints.hasOwnProperty('display')) result.display = true;
          result = {
            ...result,
            enums: element.constraints?.enumValues.map((option) => {
              return {
                data_value: option
              };
            })
          };
        }
        return result;
      }, {});
      if (!constraints.display && inputValue) {
        formik.setFieldValue(input.key, undefined);
      }

      if (constraints.disable && inputValue) {
        if (input.itype == 'document') {
          let attachment = submission.attachments?.find((x) => {
            return input.key == x.name;
          });
          if (attachment) {
            client.post(
              `attachment/deleteUnlinkedAttachment?avatarFd=${attachment.avatarFd}&&id=${attachment.id}`
            );
            setSubmission({
              ...submission,
              attachments: submission.attachments.filter(
                (x) => input.key != x.name
              )
            });
          }
        }
        // disable inputs from storing values after a disable constraint has been applied
        formik.setFieldValue(input.key, undefined);
      }
      return constraints;
    } else {
      return { disable: false, display: true };
    }
  };

  (function setInputProperties() {
    const inputLayoutProps = checkConditionalProps();
    Object.keys(inputLayoutProps).forEach((key) => {
      input[key] = inputLayoutProps[key];
    });
  })();

  const handleDOBInputChange = async (input_key, date, formik) => {
    let currentSection = input_key.split('.')[0];
    let inputSection;
    currentSection == 'relatives'
      ? (inputSection = input_key.split('.')[0] + '.' + input_key.split('.')[1])
      : (inputSection = currentSection);
    formik.setFieldValue(`${inputSection}.age`, calculateAge(date));
  };

  const handleInputChange = async (e, formik, setInitialValues) => {
    let inputValue = e.target.value;
    let InputName = e.target.name;
    let currentSection = InputName.split('.')[0];
    let sectionName = e.target.dataset.section;
    let contactsSection = ['relatives', 'friends'];
    if (getValue(formik.values, e.target.name) == '') {
      formik.setFieldValue(e.target.name, undefined);
    }
    if (
      InputName.includes('national_id') ||
      InputName.includes('passport_id')
    ) {
      await handleNationalIdChange(
        e,
        InputName,
        inputValue,
        contactsSection,
        sectionName,
        currentSection,
        setInitialValues,
        nationalIds,
        setNationalIds,
        setIdError
      );
    }

    if (
      InputName.includes('hearing_disability_number') ||
      InputName.includes('hearing_disability_cost')
    ) {
      let currSectionInput =
        formik.values[currentSection][InputName.split('.')[1]];
      if (
        currSectionInput.hearing_disability_number &&
        currSectionInput.hearing_disability_cost
      ) {
        let cost =
          parseInt(currSectionInput.hearing_disability_number) *
          parseInt(currSectionInput.hearing_disability_cost);
        formik.setFieldValue(
          `${currentSection}.${
            InputName.split('.')[1]
          }.hearing_disability_total`,
          cost
        );
      }
    }
  };

  const handleDocumentChange = (event) => {
    event.persist();
    let documentName = event.target.name,
      section = documentName.split('.')[0],
      sectionName = event.target.dataset.section,
      file = event.target.files[0],
      allFiles = event.target.files,
      input_id = parseInt(event.target.dataset.document);
    let documnet = handleDocument(
      formik,
      section,
      sectionName,
      documentName,
      file,
      allFiles,
      input_id,
      rejectedFileErrorMessage,
      setRejectedFileErrorMessage
    );
    setTimeout(() => {
      formik.setFieldTouched(documentName, true, true).then((err) => {
        if (!getValue(err, documentName)) {
          setFile(documnet);
        } else {
          formik.setFieldValue(input.key, '');
        }
      });
    }, 0);
  };
  useEffect(() => {
    if ('display' in input && !input.display && inputValue) {
      formik.setFieldValue(input.key, undefined);
    }
  }, []);

  const inputs = {
    string: () => {
      return (
        <React.Fragment>
          {input.name ? (
            <React.Fragment>
              <span
                className={'p-1 heading-4' + classes.label}
                style={{ display: 'block', ...input.input_layout.labelStyle }}>
                {input.name}
                {input.required && <span className="color-red">*</span>}
              </span>
            </React.Fragment>
          ) : (
            ''
          )}
          <FormControl fullWidth>
            <Input
              fullWidth
              name={input.key}
              id={input.key}
              value={inputValue || ''}
              disableUnderline
              placeholder={
                input.input_layout && input.input_layout.placeholder
                  ? input.input_layout.placeholder
                  : ''
              }
              inputProps={{
                className: `${classes.input} ${input.disable && 'disabled'} `,
                disabled: input.disable,
                'data-section': input.section
              }}
              onChange={(ev, value) => {
                formik.handleChange(ev, value);
              }}
              onBlur={(e) => {
                handleInputChange(e, formik, setInitialValues);
                formik.handleBlur(e);
              }}
            />
            <ErrorMessage
              isTouched={inputTouched}
              enableSubmit={enableSubmit}
              errorMessage={idError ? idError : inputError}
            />
          </FormControl>
        </React.Fragment>
      );
    },
    container: () => (
      <Container
        inputs={input.inputs}
        formik={formik}
        arrayIndexes={arrayIndexes}
        submission={submission}
        sectionIndex={sectionIndex}
        groupIdx={groupIdx}
        stepId={stepId}
        extraData={extraData}
        setAlertMessage={setAlertMessage}
        setInitialValues={setInitialValues}
        actionType={actionType}
        isMany={isMany}></Container>
    ),
    number: () => {
      return (
        <>
          {input.name && (
            <span
              className={'p-1 heading-4 ' + classes.label}
              style={{ display: 'block', ...input.input_layout.labelStyle }}>
              {input.name}
              {input.required && <span className="color-red">*</span>}
            </span>
          )}
          <FormControl fullWidth>
            <Input
              fullWidth
              name={input.key}
              id={input.key}
              value={inputValue || 0}
              type="number"
              disableUnderline
              inputProps={{
                className: `${classes.input} ${
                  (input.disable || input.input_layout.disabled) && 'disabled'
                }`,
                min: 0,
                disabled: input.disable || input.input_layout.disabled,
                'data-section': input.section
              }}
              onChange={(ev, value) => {
                formik.handleChange(ev, value);
              }}
              onBlur={formik.handleBlur}
            />
            <ErrorMessage
              isTouched={inputTouched}
              enableSubmit={enableSubmit}
              errorMessage={inputError}
            />
          </FormControl>
        </>
      );
    },
    date: () => {
      return (
        <>
          <span
            className={'p-1 heading-4 d-block ' + classes.label}
            style={input.input_layout.labelStyle}>
            {input.name}
            {input.required && <span className="color-red">*</span>}
          </span>

          <FormControl fullWidth>
            <KeyboardDatePicker
              format="dd/MM/yyyy"
              clearable
              onChange={(date) => {
                if (date) {
                  formik.setFieldValue(input.key, date).then(() => {
                    if (input.key == 'primaryFields.birth_date') {
                      handleDOBInputChange(input.key, date, formik);
                    }
                    formik.handleBlur({ target: { name: input.key } });
                  });
                } else {
                  formik.setFieldValue(input.key, null).then(() => {
                    formik.handleBlur({ target: { name: input.key } });
                  });
                }
              }}
              onBlur={formik.handleBlur}
              disabled={input.disable}
              value={inputValue == 'Invalid Date' ? null : inputValue}
            />
            <ErrorMessage
              isTouched={inputTouched}
              enableSubmit={enableSubmit}
              errorMessage={inputError}
            />
          </FormControl>
        </>
      );
    },
    radioSelect: () => {
      return (
        <>
          <span
            className={'p-1 heading-4 d-block ' + classes.label}
            style={input.input_layout.labelStyle}>
            {input.name}
            {input.required && <span className="color-red">*</span>}
          </span>

          <RadioGroup
            row
            name={input.key}
            id={input.key}
            data-section={input.section}
            value={inputValue || ''}
            disableunderline={'true'}
            placeholder={
              input.style && input.style.placeholder
                ? input.style.placeholder
                : ''
            }
            inputprops={{
              className: `${classes.input} ${input.disabled && 'disabled'} `,
              disabled: input.disabled,
              'data-section': input.section
            }}
            onChange={(ev, value) => {
              formik.handleChange(ev, value);
            }}
            onBlur={formik.handleBlur}>
            {input.itype === 'enum'
              ? input.enums?.map((option, i) => (
                  <FormControlLabel
                    key={option.id}
                    value={option.data_value}
                    control={<Radio />}
                    label={option.data_value}
                    disabled={input.disable}
                  />
                ))
              : [
                  { label: 'نعم', value: 'true' },
                  { label: 'لا', value: 'false' }
                ].map((option, i) => (
                  <FormControlLabel
                    key={i}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    disabled={input.disable}
                  />
                ))}
          </RadioGroup>
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={enableSubmit}
            errorMessage={inputError}
          />
        </>
      );
    },
    select: () => {
      return (
        <>
          {input.name && input.input_layout.display != 'none' && (
            <span
              className={'p-1 heading-4 d-block ' + classes.label}
              style={input.input_layout.labelStyle}>
              {input.name}
              {input.required && <span className="color-red">*</span>}
            </span>
          )}
          <Select
            name={input.key}
            id={input.key}
            data-section={input.section}
            value={inputValue || ''}
            input={<BootstrapInput />}
            onChange={(ev, value) => {
              formik.handleChange(ev, value);
            }}
            endAdornment={
              <IconButton
                sx={{ display: inputValue ? '' : 'none' }}
                onClick={() => formik.setFieldValue(input.key, undefined)}>
                <ClearIcon />
              </IconButton>
            }
            className={input.disable ? 'disabled' : ''}
            onBlur={formik.handleBlur}
            disabled={input.disable}
            style={{ width: '100%' }}>
            {input.itype === 'enum'
              ? input.enums &&
                handleOthersOption(input.enums).map((option, index) => (
                  <MenuItem key={index} value={option.data_value}>
                    {option.data_value}
                  </MenuItem>
                ))
              : [
                  { label: 'نعم', value: 'true' },
                  { label: 'لا', value: 'false' }
                ].map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
          </Select>
          {(inputTouched || enableSubmit) && inputError ? (
            <div className="text-danger">{inputError}</div>
          ) : null}
        </>
      );
    },
    document: () => (
      <>
        {input.input_layout.labelStyle.display != 'none' ? (
          <div
            className={'p-1 heading-4 ' + classes.label}
            style={input.input_layout.labelStyle}>
            {input.name}
            {input.required && <span className="color-red">*</span>}
          </div>
        ) : (
          ''
        )}
        <Grid container spacing={0}>
          <Grid
            item
            md={9}
            style={{ display: 'flex', alignItems: 'center', maxWidth: '65%' }}>
            <input
              type="file"
              accept='application/pdf'
              name={input.key}
              data-document={input.itype !== 'string' && input.id}
              data-section={input.section}
              ref={fileInput}
              onChange={(e) => handleDocumentChange(e)}
              onBlur={formik.handleBlur}
              width="50%"
              required={input.required}
              style={{ display: 'none' }}
            />
            <Tooltip
              title={getValue(formik.values, input.key)?.name || ''}
              arrow
              classes={{ tooltip: 'p-3' }}>
              <div display="inline" className={classes.documentTypography}>
                {getValue(formik.values, input.key)?.original_file_name ||
                  getValue(formik.values, input.key)?.name ||
                  'أسم ملف'}
              </div>
            </Tooltip>
            <Button
              className={`btn btn-primary btn-transition-none d-inline ${classes.uploadButton}`}
              disableFocusRipple
              disableRipple
              onClick={() => fileInput.current.click()}
              disabled={input.disable}>
              {' رفع ملف'}
            </Button>
            {/* <Button
              className={`btn btn-primary btn-transition-none d-inline camera-button`}
              style={{
                borderRadius: '10%',
                backgroundColor: 'white',
                color: '#3c44b1',
                width: '40px'
              }}
              disableFocusRipple
              disableRipple
              disabled={input.disable}
              onClick={() => setOpenCameraModal(true)}>
              <CameraAltIcon />
            </Button> */}
          </Grid>
          <Grid item md={3}>
            {getValue(formik.values, input.key, input.input_layout.type) &&
            !inputError ? (
              <Button
                onClick={showDocument}
                className="btn-outline-primary border-1 btn-small preview-btn-size"
                variant="outlined">
                Preview
              </Button>
            ) : (
              <div></div>
            )}
            {<span>
            {rejectedFileErrorMessage.length > 0 &&
              rejectedFileErrorMessage.map((file, index) => {
                return (
                  <ListItem
                    className="font-size-sm py-0 px-0 d-flex text-primary align-items-center"
                    key={file.fileName + index}>
                    <span className="text-danger">{file.message}</span>
                    <span className="file-name text-danger">{file.fileName}</span>
                  </ListItem>
                );
              })}
          </span>}
          </Grid>
        </Grid>
        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
        {/* preview */}
        {getValue(formik.values, input.key)?.type?.includes('pdf') ? (
          <PdfViewer
            modal={openDocument}
            setModal={setOpenDocument}
            file={
              getValue(formik.values, input.key, input.input_layout.type)
                ?.pdfUrl ||
              getValue(formik.values, input.key, input.input_layout.type)
            }></PdfViewer>
        ) : (
          <ImageViewer
            open={openDocument}
            setOpen={setOpenDocument}
            file={getValue(formik.values, input.key, input.input_layout.type)}
            local></ImageViewer>
        )}
        {/* camera modal */}
        <CameraModal
          open={openCameraModal}
          setOpen={setOpenCameraModal}
          formik={formik}
          name={input.key}
          documentType={'single'}
          document={input.itype !== 'string' && input.id}
          Section={input.section}></CameraModal>
      </>
    ),
    multipleDocument: () => {
      return (
        <>
          {input.name && (
            <span
              className={'p-1 heading-4 ' + classes.label}
              style={input.input_layout.labelStyle}>
              {input.name}
              {input.required && <span className="color-red">*</span>}
            </span>
          )}
          <div style={{ alignItems: 'center' }}>
            <FileUploader
              submission={submission}
              handleDocument={handleDocument}
              input={input}
              getValue={getValue}
              formik={formik}
              setSubmission={setSubmission}
              extraData={extraData}
            />
          </div>
          <ErrorMessage
            isTouched={inputTouched}
            enableSubmit={enableSubmit}
            errorMessage={inputError}
          />
        </>
      );
    },
    checkbox: () => (
      <>
        {input.itype === 'boolean' ? (
          <BooleanCheckbox input={input} formik={formik} value={inputValue} />
        ) : (
          <>
            <span
              className={'p-1 heading-4 d-block ' + classes.label}
              style={input.input_layout.labelStyle}>
              {input.name}
              {input.required && <span className="color-red">*</span>}
            </span>
            <FormControl
              component="fieldset"
              className={`${classes.formControl} w-100`}
              style={input.input_layout.labelStyle}>
              <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                {input.enums?.map((option) => {
                  return (
                    <React.Fragment key={option.id}>
                      <FormControlLabel
                        key={option.id}
                        control={
                          <Checkbox
                            name={input.key}
                            data-section={input.section}
                            onChange={(ev, value) => {
                              formik.handleChange(ev, value);
                            }}
                            onBlur={formik.handleBlur}
                            checked={
                              inputValue?.includes(option.data_value) || false
                            }
                            value={option.data_value}
                            disabled={input.disable}
                          />
                        }
                        label={option.data_value}
                      />
                    </React.Fragment>
                  );
                })}
              </FormGroup>
            </FormControl>
          </>
        )}
        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    ),
    textEditor: () => (
      <>
        <span
          className="p-1 heading-4 d-block"
          style={input.input_layout.labelStyle}>
          {input.name}
          {input.required && <span className="color-red">*</span>}
        </span>
        <WysiwygEditor formik={formik} input={input}></WysiwygEditor>

        {(inputTouched || enableSubmit) &&
        getValue(formik.errors, input.key, input.itype) ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    textArea: () => (
      <>
        <FormControl>
          <span
            className="p-1 heading-4 d-block"
            style={input.input_layout.labelStyle}>
            {input.name}
            {input.required && <span className="color-red">*</span>}
          </span>

          <textarea
            name={input.key}
            value={inputValue || ''}
            data-section={input.section}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={20}
            cols={'125'}
            style={{
              width: '100%',
              border: ' 1px solid #e4e5eb',
              height: '200px'
            }}
            disabled={input.disable}
          />
        </FormControl>
        {(inputTouched || enableSubmit) &&
        getValue(formik.errors, input.key, input.itype) ? (
          <div className="text-danger">{inputError}</div>
        ) : null}
      </>
    ),
    buttonSelect: () => (
      <>
        <div className="radio-toolbar">
          {input.itype === 'enum'
            ? input.enums?.map((option, i) => (
                <div key={option.id + input.key} className="btnSelect">
                  <input
                    type="radio"
                    id={option.id + input.key}
                    name={input.key}
                    value={option.data_value}
                    disabled={input.disable}
                    checked={inputValue == option.data_value}
                    onChange={(ev, value) => {
                      formik.handleChange(ev, value);
                    }}
                  />
                  <label
                    htmlFor={option.id + input.key}
                    className={`label-center ${option?.enum_layout?.className}`}>
                    {option?.enum_layout?.label}
                  </label>
                </div>
              ))
            : [
                { label: 'نعم', value: 'true' },
                { label: 'لا', value: 'false' }
              ].map((option, i) => (
                <div key={option.id + input.key} className="btnSelect">
                  <input
                    type="radio"
                    id={option.id}
                    name={input.key}
                    value={option.data_value}
                    disabled={input.disable}
                  />
                  <label
                    htmlFor={option.id}
                    style={option?.enum_layout}
                    className="label-center">
                    {option.data_value}
                  </label>
                </div>
              ))}
        </div>
        <ErrorMessage
          isTouched={inputTouched}
          enableSubmit={enableSubmit}
          errorMessage={inputError}
        />
      </>
    )
  };

  return (
    <React.Fragment key={input.key}>
      {input.display === true && (
        <Grid
          item
          md={input.input_layout?.gridSize}
          lg={input.input_layout?.gridSizelg}
          sm={input.input_layout ? input.input_layout.gridSizeSM : 6}
          xs={12}
          style={input.input_layout || {}}
          className="pl-0">
          <div>
            {input.input_layout?.type &&
              inputs[String(input.input_layout?.type)]()}
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    enableSubmit: state.form.enableSubmit,
    fileUploadError: state.form.fileUploadError,
    submission: state.form.submission,
    user: state.auth.user,
    form: state.form.form,
    nationalIds: state.form.nationalIds
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (file) => dispatch(uploadFile(file)),
    setSubmission: (submissionDetails) =>
      dispatch(setSubmission(submissionDetails)),
    setNationalIds: (nationalId, InputName) =>
      dispatch(setNationalIds(nationalId, InputName)),
    DecreaseRequestsNo: () => dispatch(DecreaseRequestsNo())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InputWidget);
