import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
export default function DownloadButton({
  buttonLabel,
  btnHref,
  btnRef,
  btnClasses,
  btnStyle
}) {
  return (
    <>
        <Button
          variant="contained"
          onClick={(e) => {
            btnRef.current.click();
          }}
          className={btnClasses}
          style={btnStyle}
          justify="flex-start">
          {buttonLabel}
        </Button>
        <a
          ref={btnRef}
          className="d-none"
          // href={`${apiConfig.baseUrl}attachment/generateTaskPdf?submissionId=${submissionId}`}
          href={btnHref}
          name="transfer"
          download="transfer"></a>
    </>
  );
}
