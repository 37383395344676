import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import client from '../../api/apiAuth/guestClient';
// import rtl from 'jss-rtl';
import './index.css';
import _ from 'lodash';
import { fetchWorklistLayout } from '../../reducers/list';
import {
  DataGrid,
  GridToolbarContainer,
  getGridStringOperators,
  getGridNumericOperators,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  useGridApiRef,
  GridToolbarExport
} from '@mui/x-data-grid';
import { diff } from 'deep-object-diff';
import Swal from 'sweetalert2';

// const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function Grid(props) {
  const { getLayout } = props;
  let rowCount = 12135;
  let isMounted = true;
  let model = '';
  let Tab = 0;
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState([]);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({ type: '', order: 'none', orderBy: '' });
  // const [table, setTable] = useState("");
  const [currentTab, setCurrentTab] = useState(29);
  const apiRef = useGridApiRef();
  let { currentTable } = props.match.params;

  // const useStyles = makeStyles(() => ({
  //   formControl: {
  //     "& .MuiInputBase-root": {
  //       color: "#ECEDF6",
  //       borderColor: "#ECEDF6",
  //       borderWidth: "5px",
  //       borderStyle: "solid",
  //       minWidth: "30px",
  //       minHeight: "30px",
  //       justifyContent: "center"
  //     },
  //     "& .MuiSelect-select.MuiSelect-select": {
  //       paddingRight: "0px",
  //       paddingLeft: "0px",
  //       color: 'black'
  //     }
  //   },
  //   select: {
  //     "&:focus": {
  //       backgroundColor: "transparent"
  //     }
  //   },
  //   selectIcon: {
  //     position: "relative",
  //     fontSize: "20px"
  //   },
  //   paper: {
  //     borderRadius: 12,
  //     marginTop: 8
  //   },
  //   list: {
  //     paddingTop: 0,
  //     paddingBottom: 0,
  //     "& li": {
  //       fontWeight: 200,
  //       paddingTop: 8,
  //       paddingBottom: 8,
  //       fontSize: "12px"
  //     },
  //     "& li.Mui-selected": {
  //     },
  //     "& li.Mui-selected:hover": {
  //       background: "#6EC177"
  //     }
  //   }
  // }));
  // const classes = useStyles();
  // const menuProps = {
  //   classes: {
  //     list: classes.list,
  //     paper: classes.paper
  //   },
  //   anchorOrigin: {
  //     vertical: "bottom",
  //     horizontal: "center"
  //   },
  //   transformOrigin: {
  //     vertical: "top",
  //     horizontal: "center"
  //   },
  //   getContentAnchorEl: null
  // };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [setRowCountState]);

  useEffect(() => {
    client
      .get('tasks/findSubmissions', {
        params: { filters: [], currentTab: 29, page: 0, currentTable }
      })
      .then(function (result) {
        setRows(result.data.result);
      });
  }, []);

  const fetchCounts = async (table) => {
    if (table == 'table1' || table == undefined) {
      model = 'phase4_final_UFT8';
      Tab = 29;
    } else if (table == 'table2') {
      model = 'payments';
      Tab = 30;
    }
    setCurrentTab(Tab);
    client
      .get('tasks/findSubmissions', {
        params: { filters: [], currentTab: Tab, page: 0, currentTable }
      })
      .then(function (result) {
        setRows(result.data.result);
      });
    if (isMounted) {
      try {
        getLayout({ model: currentTable }).then(function (result) {
          result.value.data.columns?.map((col) => {
            if (col.headerName == 'counter') {
              col.filterOperators = getGridNumericOperators().filter(
                (operator) =>
                  operator.value === '>' ||
                  operator.value === '<' ||
                  operator.value === '='
              );
            } else {
              col.filterOperators = getGridStringOperators().filter(
                (operator) =>
                  operator.value === 'contains' || operator.value === 'equals'
              );
            }
          });
          setColumns(result.value.data.columns);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchCounts().catch((err) => {});
    return () => {
      isMounted = false;
    };
  }, []);

  let handleNext = (PageIndex) => {
    setPage(PageIndex);
    if (
      !_.isEmpty(filters) &&
      filters.items[0]?.columnField &&
      filters.items[0]?.operatorValue &&
      filters.items[0]?.value
    ) {
      client
        .get('tasks/findSubmissions', {
          params: {
            filters: [
              {
                field: filters.items[0].columnField,
                operator: filters.items[0].operatorValue,
                value: [filters.items[0].value]
              }
            ],
            currentTab,
            page: PageIndex,
            currentTable
          }
        })
        .then(function (result) {
          setRows(result.data.result);
        });
    } else {
      client
        .get('tasks/findSubmissions', {
          params: {
            filters: [],
            currentTab,
            page: PageIndex,
            currentTable
          }
        })
        .then(function (result) {
          setRows(result.data.result);
        });
    }
  };

  let handleFilter = (filters) => {
    setFilters(filters);
    if (filters.items[0]?.value == '' || filters.items[0]?.value == undefined) {
      client
        .get('tasks/findSubmissions', {
          params: { filters: [], currentTab, page: 0, currentTable }
        })
        .then(function (result) {
          setRows(result.data.result);
        });
    } else if (
      filters.items[0]?.columnField &&
      filters.items[0]?.operatorValue &&
      filters.items[0]?.value
    ) {
      client
        .get('tasks/findSubmissions', {
          params: {
            filters: [
              {
                field: filters.items[0].columnField,
                operator: filters.items[0].operatorValue,
                value: [filters.items[0].value]
              }
            ],
            currentTab,
            page: page,
            currentTable
          }
        })
        .then(function (result) {
          setRows(result.data.result);
        });
    }
  };

  const [differnces, setDiffernces] = useState([]);
  const difference = (newRow, oldRow) => {

    let differnces_clone = _.cloneDeep(differnces);
    let _differnces = diff(oldRow, newRow);

    switch (currentTable) {
      case 'Main':
        _differnces.id = newRow.unique_basic;
        break;
      case 'Healthcare':
        _differnces.id = newRow.unique_medical;
        break;
      case 'Marriage':
        _differnces.id = newRow.unique_marr;
        break;
      case 'Non_deserved':
        _differnces.id = newRow.id;
        break;
      case 'Passerby':
        _differnces.id = newRow.unique_sabil;
        break;
      case 'Payment':
        _differnces.id = newRow.IDD;
        break;
    }
    if (differnces.length > 0) {
      differnces.map((diff, index) => {
        if (diff.id == _differnces.id) {
          // replace
          differnces_clone[index] = _differnces;
          setDiffernces([...differnces_clone, _differnces]);
        } else {
          // add
          setDiffernces([...differnces, _differnces]);
        }
      });
    } else {
      setDiffernces([...differnces, _differnces]);
    }
  };

  let handleSaveChanges = () => {
    const unique = [...new Set(differnces)];
    client
      .get('tasks/updateSubmissions', {
        params: { rows: unique, currentTable }
      })
      .then(function (result) {
        Swal.fire({
          icon: 'success',
          title: 'تم حفظ التعديلات بنجاح',
          showConfirmButton: false,
          timer: 1500,
          color: '#ffffff'
        });
      })
      .catch((error) => {
        console.log(error);
        // setErrorMessage(error.response && error.response.data.message);
      });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer style={{ marginBottom: '3px', direction: 'rtl' }}>
        {/* <FormControl size="small" style={{width: '10%'}} className={classes.formControl}>    
          <InputLabel style={{color: '#1890ff', marginRight: '9px'}} id="demo-simple-select-label"> اختر الجدول</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="الجدول"
            onChange={handleSelect}
            MenuProps={menuProps}
            variant="outlined"
            defaultValue="" 
            classes={{
              select: classes.select,
              icon: classes.selectIcon
            }}
            // sx={styles.select}
            value={table}
          >
            <MenuItem value={'table1'}>جدول 1</MenuItem>
            <MenuItem value={'table2'}>الصرف</MenuItem>
          </Select>
        </FormControl> */}
        <GridToolbarColumnsButton className="toolbar-btn" />
        <GridToolbarFilterButton className="toolbar-btn" />
        <GridToolbarDensitySelector className="toolbar-btn" />
        <GridToolbarExport
          className="toolbar-btn"
          csvOptions={{ allColumns: true }}
          printOptions={{ disableToolbarButton: true }}
        />
        {/* <button className="btnStyle" onClick={handleSaveChanges}>
          حفظ التعديلات
        </button> */}
      </GridToolbarContainer>
    );
  }

  // let handleSelect = (e) => {
  //     setTable(e.target.value)
  //     fetchCounts(e.target.value).catch((err) => {});
  //     return () => {
  //       isMounted = false;
  //     };
  // }

  return (
    // <StylesProvider jss={jss}>
    <html dir="ltr">
      <div style={{ width: '100%' }}>
        {/* <button className='btnStyle' onClick={handleExport}>
        اصدار ملف أكسل
        </button> */}
        <DataGrid
          apiRef={apiRef}
          localeText={{
            // ToolBar Filters text
            toolbarColumns: 'الاعمدة',
            toolbarFilters: 'فلترة',
            noResultsOverlayLabel: '.لا يوجد طلبات',
            // Filter panel text
            filterPanelAddFilter: 'Add filter',
            filterPanelDeleteIconLabel: 'إلغاء',
            filterPanelLogicOperator: 'المعامل',
            filterPanelOperators: 'المعامل',
            filterPanelOperatorAnd: 'And',
            filterPanelOperatorOr: 'Or',
            filterPanelColumns: 'الاعمدة',
            filterPanelInputLabel: 'القيمة',
            filterPanelInputPlaceholder: 'Filter value',
            // Filter operators text
            filterOperatorContains: 'يتضمن',
            filterOperatorEquals: 'يساوي',
            // Columns panel text
            columnsPanelTextFieldLabel: 'إبحث عن العمود',
            columnsPanelTextFieldPlaceholder: 'اسم العمود',
            columnsPanelShowAllButton: 'إظهار الأعمدة',
            columnsPanelHideAllButton: 'إخفاء الأعمدة',
            // Density selector toolbar button text
            toolbarDensity: 'نظام الأعمدة',
            toolbarDensityLabel: 'نظام الأعمدة',
            toolbarDensityCompact: 'أعمدة مدمجة',
            toolbarDensityStandard: 'أعمدة قياسية',
            toolbarDensityComfortable: 'أعمدة متسعة',
            // Export selector toolbar button text
            toolbarExport: 'إصدار ملف',
            toolbarExportLabel: 'إصدار ملف',
            toolbarExportCSV: 'تحميل ملف csv'
          }}
          autoHeight
          initialState={{
            pagination: {
              pageSize: 100
            }
          }}
          sx={{
            '.MuiDataGrid-columnHeaders': {
              boxShadow: 1,
              borderRadius: 2,
              p: 2,
              minWidth: 300,
              color: 'white',
              fontSize: 'larger',
              bgcolor: '#a1a4c4'
            }
          }}
          rows={rows}
          getRowId={(row) => {
            if(row.IDD) return row.IDD
            if(row.unique_basic) return  row.unique_basic 
            if(row.id) return row.id
            if(row.unique_medical) return row.unique_medical
            if(row.unique_marr) return row.unique_marr
            if(row.unique_sabil) return row.unique_sabil
          }}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          editMode="row"
          paginationMode="server"
          rowCount={rowCountState} // let the grid know how many pages there are
          pagination
          rowsPerPageOptions={[]}
          onPageChange={handleNext}
          filterMode="server"
          onFilterModelChange={handleFilter}
          filterOperators
          sortingMode="server"
          components={{ Toolbar: CustomToolbar }}
          processRowUpdate={difference}
          onProcessRowUpdateError={(error) => console.log(error)}
        />
      </div>
    </html>
    // </StylesProvider>
  );
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    getLayout: (params) => dispatch(fetchWorklistLayout(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Grid);
