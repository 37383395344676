import React, { useState, useEffect } from 'react';

import {
  Button,
  createMuiTheme,
  Dialog,
  DialogContent,
  Grid
} from '@material-ui/core';
import { connect } from 'react-redux';
import fontawesome from '@fortawesome/fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import SuccessModal from '../SucessModal';
import './style.css';
import _ from 'lodash';
import { useFormik } from 'formik';
import {
  enableSubmitButton,
  uploadFile
} from '../../reducers/form/formActions';
import client from 'api/apiAuth/guestClient';
import { GetStepContent } from 'components/FormSteps/getStepContent';
import { setSchema } from 'utils/setValidationSchema';
import * as Yup from 'yup';
import { setProtoTypeInitialValues } from 'components/FormSteps';
fontawesome.library.add(faCaretLeft, faCaretRight);

function StepInputModal(props) {
  const {
    open,
    setOpen,
    sections,
    task,
    submission,
    stepAttachements,
    modalSize,
    enableSubmitButton,
    isUploadingFile,
    connectionStatus
  } = props;
  const [successModal, setSuccessModal] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setvalidationSchema] = useState({});
  const [errorMesssage, setErrorMesssage] = useState('');
  const [steps, setSteps] = useState([]);
  const [manyEntities, setManyEntities] = useState({});
  const [elementsProtoType, setElementsProtoType] = useState({});
  const [removing, setRemoving] = useState(false);
  const [numbering, setNumbering] = useState({
    sectionIndex: 1,
    childSectionIndex: 1
  });
  let attachmentReviewed = [];


  const handleSubmitTask = async () => {
    if (!_.isEmpty(formik.values)) {
      setErrorMesssage('');
      let rules = [];
      task.step.groups.forEach((group) => {
        rules.push(group.rules);
      });
      rules = rules.flat();

      if (stepAttachements.attachments) {
        attachmentReviewed = _.cloneDeep(stepAttachements.attachments);
        attachmentReviewed.forEach((attach) => (attach.is_reviewed = 1));
      }

      await client.post('submission/updateSubmissionValues', {
        submission_id: submission.id,
        section_id: sections[0].id,
        rules: rules,
        task_id: task.id,
        submission_edit: formik.values,
        attachmentReviewed
      });

      setOpen(false);
    } else {
      setErrorMesssage('Please fill data before submit');
    }
  };

  let protoTypeObj = {};
  const setProtoType = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (key !== 'randomId') {
        protoTypeObj[key] = Array.isArray(obj[key]) ? obj[key][0] : obj[key];
        setProtoType(protoTypeObj[key]);
      }
    });
    setElementsProtoType(protoTypeObj);
  };

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: initialValues,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitTask();
    }
  });
  useEffect(() => {
    setErrorMesssage('');
  }, [formik.values]);

  useEffect(() => {
    if (submission && sections) {
      setInitialValues({
        [sections[0].key]: submission.values[sections[0].key]
      });
    }
    if (submission && submission.attachments) {
      let initSection = {};
      sections.forEach((section) => {
        if (!['requestPurpose', 'requiredProcedures'].includes(section.key))
          initSection[section.key] = {};
        section.inputs.forEach((input) => {
          if (input.itype == 'document') {
            initSection[section.key][input.key.split('.')[1]] = {};
          }
        });
        submission.attachments.forEach((attach) => {
          section.inputs.forEach((input) => {
            if (input.key == attach.document?.key) {
              let metadata = {
                ...attach.metadata,
                pdfUrl: attach.url,
                name: attach.original_file_name
              };
              initSection[section.key][
                attach.document.key.split('.')[1]
              ] = !_.isEmpty(
                initSection[section.key][attach.document.key.split('.')[1]]
              )
                  ? [
                    ...initSection[section.key][
                    attach.document.key.split('.')[1]
                    ],
                    metadata
                  ]
                  : [metadata];
            }
          });
        });
        if (!_.isEmpty(initSection))
          Object.keys(initSection[section.key]).forEach((key) => {
            if (_.isEmpty(initSection[section.key][key]))
              delete initSection[section.key][key];
          });
      });
        setInitialValues({
          ...initSection,
          [sections[0].key]: submission.values[sections[0].key]
        });
    }
  }, [submission, sections]);

  useEffect(() => {
    if (sections) {
      setvalidationSchema(
        Yup.object().shape(setSchema(sections[0].inputs, false))
      );
        setSteps(sections);
    }
  }, [sections]);

  useEffect(() => {
    const sectionsObj = sections.reduce((acc, section) => {
      section = { [section.name]: section.isMany ? [{}] : {} };
      return { ...acc, ...section };
    }, {});
    let obj = {};
    setProtoTypeInitialValues(obj, initialValues, sections);
    if (!submission.values[sections[0].key]) {
      setManyEntities(sectionsObj);
    } else {
      setManyEntities(obj);
    }
    setProtoType(obj);
  }, [initialValues]);

  return (
    <>
      <Dialog
        classes={{ paper: 'modal-content' }}
        fullWidth
        maxWidth={modalSize || 'md'}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title2">
        <DialogContent className="p-0">
          <div>
            <div className="text-center p-5">
              {steps?.length >= 1 && (
                <div className="card-header d-block bg-white pt-4 pb-3">
                  <Grid container alignItems="center" justify="center">
                    {steps?.map((section, i) => (
                      <>
                        {GetStepContent({
                          formik,
                          activeStep: 0,
                          steps: [section],
                          submission: stepAttachements,
                          setManyEntities,
                          elementsProtoType,
                          manyEntities,
                          numbering,
                          extraData: { connectionStatus },
                          setRemoving
                        })}
                      </>
                    ))}
                  </Grid>
                </div>
              )}
            </div>
            <div className="pt-4">
              <Grid container spacing={0} alignItems="center">
                <Grid
                  item
                  md={12}
                  style={{ textAlign: 'center' }}
                  className="mb-2">
                  <Button
                    className="btn-first btn-primary mx-1 px-5 py-2"
                    size="large"
                    disabled={isUploadingFile}
                    onClick={async (e) => {
                      enableSubmitButton();
                      const res = await formik.validateForm();
                      formik.handleSubmit(e);
                      if (_.isEmpty(res)) {
                        setTimeout(() => {
                          location.reload();
                        }, 500);
                      }
                      // handleSubmitTask();
                    }}
                    type="submit">
                    <span className="btn-wrapper--label ml-2">تم</span>
                    {/* {loading && (
                <ClipLoader color={'var(--primary)'} loading={true} />
              )} */}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <SuccessModal
        modal={successModal}
        setModal={setSuccessModal}></SuccessModal>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    enableSubmitButton: () => dispatch(enableSubmitButton()),
    uploadFile: (file) => dispatch(uploadFile(file))
  };
};
const mapStateToProps = (state) => {
  return {
    sucess: state.details.sucess,
    stepAttachements: state.form.submission,
    connectionStatus: state.status.connectionStatus,
    isUploadingFile: state.form.isUploadingFile

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepInputModal);
