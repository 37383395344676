import React, { useState, useEffect } from 'react';
import {
  Grid,
  MenuItem,
  FormControl,
  OutlinedInput,
  Input,
  Select
} from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchForms } from '../../reducers/list';
import { DatePicker } from '@material-ui/pickers';
import client from 'api/apiAuth/guestClient';
// import { setFiltersArray } from 'reducers/filter';

const Filters = ({
  filters,
  setFilters,
  getForms,
  forms,
  isDraft,
  currentTab,
  user
}) => {
  const renderCustomDateInput = (props) => {
    return (
      <FormControl>
        <Input
          type="text"
          disableUnderline
          style={{
            backgroundColor: 'white',
            textIndent: '10px',
            border: '1px solid #dcd7d7',
            borderRadius: '0.4rem'
          }}
          onClick={props.onClick}
          value={props.value}
          id={props.name}
          name={props.name}
        />
      </FormControl>
    );
  };
  // useEffect(() => {
  //   getForms({ model: 'Form' }).catch((err) => {});
  // }, []);
  useEffect(() => {
    setName('');
    setSelectedProgram('');
    setSelectedGovernorate('');
    setFileCode('');
    setNationalId('');
    setStartDate();
    setEndDate();
  }, [currentTab]);
  let [selectedProgram, setSelectedProgram] = useState('');
  let [selectedGovernorate, setSelectedGovernorate] = useState('');
  const [name, setName] = useState('');
  const [fileCode, setFileCode] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [nationalId, setNationalId] = useState('');
  const [governorates, setGovernorates] = useState([]);
  useEffect(() => {
    const getInitialFilters = async () => {
      let { data } = await client.get('governorate/getGovernorates');
      setGovernorates(data.governorates);
    };
    getInitialFilters();
    return () => {
      setGovernorates([]);
    };
  }, []);
  const handleSelectedProgram = (event) => {
    setSelectedProgram(event.target.value);
    addToFilterArray('submissionFilter', event);
  };
  const handleSelectedGovernorate = (event) => {
    setSelectedGovernorate(event.target.value);
    event.target.value = governorates[event.target.value - 1].name;
    addToFilterArray('primaryInputFilter', event);
  };
  const handleSelectedDate = (value, operator) => {
    const filterObj = {
      type: 'submissionFilter',
      field: 'createdAt',
      value: value,
      operator
    };
    let newFilters = [...filters];
    let filterIndex = filters.findIndex(
      (filter) => filter.field === 'createdAt'
    );
    if (filterIndex > 0) {
      newFilters[filterIndex] = filterObj;
    } else {

      _.remove(newFilters, function(elem) { return elem.operator == filterObj.operator || elem.operator == "startsWith";});
      newFilters.push(filterObj);
      //incase from & to dates are equal 
      if (newFilters.length == 2) {
        if (Date.parse(newFilters[0].value) == Date.parse(newFilters[1].value)) {
          newFilters = _.remove(newFilters, function (elem) { return elem.operator == 'lt' });
          newFilters[0].value = new Date(newFilters[0].value).toISOString().slice(0, 10)
          newFilters[0].operator = "startsWith";
        }
      }
    }
    setFilters([...newFilters]);
  };
  //search handling
  const handleChange = (filterType, event, having = false) => {
    if (event.key === 'Enter' && event.target.value !== '') {
      addToFilterArray(filterType, event, true, having);
    }
    if (event.key === 'Backspace' && event.target.value === '') {
      removeFromFilterArray(event);
    }
  };
  const removeFromFilterArray = (event) => {
    let filterIndex = filters.findIndex(
      (filter) => filter.field === event.target.name
    );
    let newFilters = [...filters];
    if (filterIndex > -1) {
      newFilters.splice(filterIndex, 1);
      setFilters([...newFilters]);
    }
  };
  const getFilCode = (event) => {
    if (event.target.value.includes('-')) {
      let strArray = event.target.value.split('-');
      let strAccumulator = '-';
      let numberOfRequiredZeroes = 9 - strArray[1].length;
      for (let i = 0; i < numberOfRequiredZeroes; i++) {
        strAccumulator += '0';
      }
      strAccumulator = strAccumulator.concat(strArray[1]);
      let file_code = strArray[0].concat(strAccumulator);

      return file_code;
    } else {
      return event.target.value;
    }
  };
  const addToFilterArray = (filterType, event, like = false, having) => {
    if (event.target.name == 'file_code') {
      event.target.value = getFilCode(event);
    }
    let filterObj = like
      ? {
          type: filterType,
          field: event.target.name,
          value: event.target.value + '%',
          operator: 'like',
          having
        }
      : {
          type: filterType,
          field: event.target.name,
          value: event.target.value,
          having
        };
    let newFilters = [...filters];
    let filterIndex = filters.findIndex(
      (filter) => filter.field === event.target.name
    );
    if (filterIndex > 0) {
      newFilters[filterIndex] = filterObj;
    } else {
      newFilters.push(filterObj);
    }
    setFilters([...newFilters]);
  };
  return (
    <>
      <div className="card-header d-flex align-items-center justify-content-between card-header-alt pr-4 pt-4 pb-4">
        <Grid container spacing={0}>
          {user.roles[0].name !== 'architect' && 
          <Grid item container xs spacing={0}>
            <Grid item xs={12}>
              <span style={{ textAlign: 'center' }}>البرنامج</span>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Select
                  labelId="demo-simple-select-standard"
                  id="demo-simple-select-standard"
                  value={selectedProgram}
                  name="form_id"
                  onChange={handleSelectedProgram}
                  style={{
                    height: '30px',
                    width: '75%'
                  }}>
                  {forms &&
                    forms.map((program) => (
                      <MenuItem value={program.id} key={program.id}>
                        {program.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>}
          {!isDraft && user.roles[0].name !== 'architect'?
          <Grid item container xs spacing={0}>
            <Grid item xs={12}>
              <span style={{ textAlign: 'center' }}>المحافظة</span>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <Select
                  labelId="demo-simple-select-standard"
                  id="demo-simple-select-standard"
                  value={selectedGovernorate}
                  name="governorate"
                  onChange={handleSelectedGovernorate}
                  style={{
                    height: '30px',
                    width: '75%'
                  }}>
                  {governorates &&
                    governorates.map((governorate) => (
                      <MenuItem value={governorate.id} key={governorate.id}>
                        {governorate.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid> : null }
          <Grid item xs container spacing={0}>
            <Grid item xs={12}>
              <span>بحث بكود الطلب</span>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="inp-search">
                <OutlinedInput
                  name="file_code"
                  value={fileCode}
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(ev) => setFileCode(ev.target.value)}
                  onKeyUp={(event) => handleChange('submissionFilter', event)}
                  labelWidth={0}
                />
              </FormControl>
            </Grid>
          </Grid>
          {!isDraft ?
          <Grid item xs container spacing={0}>
            <Grid item xs={12}>
              <span className="ml-1">بحث باﻻسم</span>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="inp-search">
                <OutlinedInput
                  name="name"
                  value={name}
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(ev) => setName(ev.target.value)}
                  onKeyUp={(event) =>
                    handleChange('primaryInputFilter', event, true)
                  }
                  labelWidth={0}
                />
              </FormControl>
            </Grid>
          </Grid> : ''}
          {!isDraft ?
           <Grid item xs container spacing={0}>
            <Grid item xs={12}>
              <span className="ml-1">بحث برقم البطاقه</span>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="inp-search">
                <OutlinedInput
                  name={isDraft ? 'primaryFields.national_id' : 'contact_id'}
                  value={nationalId}
                  aria-describedby="outlined-weight-helper-text"
                  onChange={(ev) => setNationalId(ev.target.value)}
                  onKeyUp={(event) =>
                    handleChange(
                      isDraft ? 'inputFilter' : 'submissionFilter',
                      event
                    )
                  }
                  labelWidth={0}
                />
              </FormControl>
            </Grid>
          </Grid> : ''}
          <Grid item xs container spacing={0}>
            <Grid item xs={12}>
              <span>تاريخ الطلب من :</span>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="inp-search">
                <DatePicker
                  TextFieldComponent={renderCustomDateInput}
                  format="dd-MM-yyyy"
                  autoOk
                  name="createdAt"
                  value={startDate}
                  onChange={(value) => {
                    setStartDate(value);
                    handleSelectedDate(value, 'gte');
                  }}
                  labelWidth={0}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs container spacing={0}>
            <Grid item xs={12}>
              <span>تاريخ الطلب الي :</span>
            </Grid>
            <Grid item xs={12}>
              <FormControl className="inp-search">
                <DatePicker
                  TextFieldComponent={renderCustomDateInput}
                  format="dd-MM-yyyy"
                  autoOk
                  name="createdAt"
                  value={endDate}
                  onChange={(value) => {
                    setEndDate(value);
                    handleSelectedDate(value, 'lt');
                  }}
                  labelWidth={0}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="divider" />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    forms: state.formList.forms,
    user: state.auth.user
    // filters: state.filter.filters
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getForms: (params) => dispatch(fetchForms(params))
    // setFilters: (data) => dispatch(setFiltersArray(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Filters);
// export default Filters;
