import React from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Button
} from '@material-ui/core';
export default function Selection({
  Selectionlabel,
  SelectionValue,
  handleChange,
  handleButton,
  buttonLabel,
  menuItems
}) {
  return (
    <>
      <Grid item sm={3}>
        <Button
          className="btn-transition-none mb-4  btn-lg md-4 float-left"
          variant="contained"
          disabled={SelectionValue ? false : true}
          onClick={() => handleButton(SelectionValue)}>
          {buttonLabel}
        </Button>
      </Grid>
      <Grid item sm={7}>
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="demo-simple-select-outlined-label">
            {Selectionlabel}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={SelectionValue}
            onChange={handleChange}
            className="mt-0"
            label={Selectionlabel}>
            {menuItems?.map((item) => (
              <MenuItem value={item?.id} key={item.id}>
                {item.username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}
