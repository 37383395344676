import Schema from "./Schema";
import Joi from "joi";

class JoiSchema extends Schema {
  setInteger() {
    return Joi.number();
  }
  setString() {
    return Joi.string();
  }
  setBoolean() {
    return Joi.boolean();
  }
  setDate() {
    return Joi.date();
  }
  setObject(objectSchema) {
    return Joi.object(objectSchema);
  }
  setEnum(enums) {
    return Joi.string().valid(...enums);
  }
  setArray(itemSchema) {
    return Joi.array().items(itemSchema);
  }
  setRequired(element) {
    return element.required();
  }
  extendObject(extendedPart, oldPart) {
    return extendedPart.concat(oldPart);
  }
  getArrayItemSchema(arraySchema) {
    return arraySchema.$_terms.items[0];
  }
  setConditionalSchema(element, condition, is, then, otherwise) {
    return element.when(condition, {
      is,
      then,
      otherwise,
    });
  }
  setAny() {
    return Joi.any();
  }
  getAttachmentSchema() {
    return {
      id: Joi.string(),
      avatarFd: Joi.string(),
      name: Joi.string(),
      metadata: Joi.object(),
      extension: Joi.string(),
      comment: Joi.string(),
      size: Joi.string(),
      storage_type: Joi.string(),
      pages: Joi.number(),
      is_reviewed: Joi.boolean(),
      annotations: Joi.string(),
      original_file_name: Joi.string(),
      submission_id: Joi.string(),
      input_id: Joi.number(),
    };
  }
}
export default JoiSchema;
