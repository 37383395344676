import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog } from '@material-ui/core';
import React from 'react';

export default function SuccessModal(props) {
  const { modal, setModal } = props;
  const toggle = () => setModal(!modal);

  return (
    <>
      <Dialog
        open={modal}
        onClose={toggle}
        classes={{ paper: 'modal-content rounded-lg' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
              <FontAwesomeIcon
                icon={['fa', 'lightbulb']}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">{'نجاح'}</h4>
          <p className="mb-0 font-size-lg text-muted">
            "لقد تم تنفيذ طلبك بنجاح"
          </p>
          <div className="pt-4">
            <Button onClick={toggle} className="btn-success btn-pill mx-1">
              <span className="btn-wrapper--label">حسنا</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
