import React, { useEffect, useState } from 'react';
import { List, ListItem, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import client from '../../api/apiAuth/guestClient';
import tabs from './tabs';

const useStyles = makeStyles({
  root: {
    flexBasis: '150px',
    flexShrink: 0,
    justifyContent: 'center'
  }
});

export default function TableTabs({
  setFilters,
  setSort,
  setCurrentTab,
  setLastTab,
  setLayoutData,
  currentRole,
  activeTab,
  setActiveTab,
  tableName,
  fetchCountOnRoleChange
}) {
  const classes = useStyles();
  //0 ---> قائمة اعمالك
  //1 ----> الغير مكلفه
  //2 ----> الطلبات السابقه
  //3 ----> الطلبات المنتهيه
  //4 ----> الطلبات الغير مكتملة
  //5 ----> الطلبات المعطلة للبحث
  //6 ----> مسودات الباحثين
  //7 ----> الطلبات المعطلة للمراجعة
  //8 ----> الطلبات
  //16 ----> جميع الطلبات المنتهيه
  //20 ----> الطلبات المحفوظة

  //21 ----> الطلبات المتوقفة عند مشرف البحث
  //22 ----> الطلبات المتوقفة عند الباحثين
  //23 ----> الطلبات المتوقفة عند مستخدمي القسم الاجتماعي
  //24 ----> الطلبات المتوقفة عند الموظفين
  //25 ----> الطلبات المتوقفة عند المراجعين
  //26 ----> الطلبات المتوقفة عند مستخدمي القسم الطبي
  //27 ----> غارمون و غارمات
  //28 ----> الطلبات المتوقفة للمراجعة
  //32 ---->  اسكان و وصلات مياه
  const menuObj = tabs[tableName];
  const [menus, setMenus] = useState(menuObj.shared);
  const fetchCounts = async (isMounted) => {
    let extraTabs = [];
    menuObj[currentRole]?.forEach((obj) => {
      if (!extraTabs.some((tab) => tab.id == obj.id)) {
        extraTabs.push(obj);
      }
    });
    const newMenus = [...menuObj.shared, ...extraTabs];
    for (const menu of newMenus) {
      if (isMounted) {
        try {
          // const res = await client.get('tasks/find', {
          //   params: {
          //     page: 1,
          //     limit: 1,
          //     currentTab: menu.id,
          //     currentRole
          //   }
          // });
          // menu.badge = res.data.count;
          menu.badge = 0;
        } catch (error) {
          console.log(error);
          menu.badge = 0;
        }
      }
    }
    if (currentRole) setMenus(newMenus);
  };
  useEffect(() => {
    let isMounted = true;

    fetchCounts(isMounted).catch((err) => {});
    return () => {
      isMounted = false;
    };
  }, [currentRole]);
  useEffect(() => {
    let isMounted = true;
    if (!fetchCountOnRoleChange) fetchCounts(isMounted).catch((err) => {});
    return () => {
      isMounted = false;
    };
  }, []);

  const handleMenu = (menu) => {
    setLastTab(menu.id);
    localStorage.setItem('activeTab', menu.id);
    if (activeTab !== menu.id) {
      setLayoutData([]);
      setActiveTab(menu.id);
      setCurrentTab(menu.id);
      setSort({ type: '', order: 'none', orderBy: '' });
      setFilters([]);
    }
  };
  return (
    <>
      <List
        className="nav-tabs nav-tabs-primary d-flex p-0"
        style={{
          maxWidth: '100%',
          overflowX: 'auto'
        }}>
        {menus.map((menu) => {
          return (
            <ListItem
              classes={{
                root: classes.root
              }}
              key={menu.id}
              style={
                menu.name === 'الطلبات الغير مكتملة' && activeTab === menu.id
                  ? {
                      borderTop: 'red solid'
                    }
                  : {}
              }
              button
              disableRipple
              selected={activeTab === menu.id}
              className="m-0 text-center"
              onClick={() => {
                handleMenu(menu);
              }}>
              {menu.badge ? (
                <Badge
                  badgeContent={menu.badge}
                  max={menu.badge}
                  color={'error'}
                  className="pl-3">
                  {menu.name}
                </Badge>
              ) : (
                menu.name
              )}
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
