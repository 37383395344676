class InputValidation {
  constructor(input, schemaObj, schemaType, isDraft) {
    this.input = input;
    this.schemaObj = schemaObj;
    this.schemaType = schemaType;
    this.isDraft = isDraft;
  }

  validateInput() {
    try {
      this.typeValidation();
      if (!this.isDraft) this.checkDependentValidation();
    } catch (error) {
      console.log(error);
    }
  }
  schemaValidation(input, validation, inputValue) {
    Object.keys(validation).forEach((key) => {
      if (key === 'required' && !this.isDraft) {
        input = this.schemaType.setRequired(input, inputValue);
      }
      if (
        !inputValue.dependency.filter((input) =>
          input.constraints?.validation?.hasOwnProperty('dependent')
        ).length > 0
      ) input = validation[key] ? input[key](validation[key]) : input[key](); 
    }); 
    return input;
  }

  checkDependentValidation() {
    const applyValidationConstraints = () => {
      this.input.dependency.forEach((item) => {
        const lastInputPart = (input) => input.split('.').pop();
        const checkInputs = [
          ...new Set(
            item.conditions.map((checkInput) =>
              lastInputPart(
                this.input.isManySec ? checkInput.key : checkInput.single_key
              )
            )
          )
        ];

        if (item.constraints.validation) {
          this.schemaObj[
            inputValues.key
          ] = this.schemaType.setConditionalSchema(
            this.schemaObj[inputValues.key],
            checkInputs,
            item,
            this.input,
            (schema) =>
              this.schemaValidation(
                schema,
                item.constraints.validation,
                inputValues
              )
          );
        }
      });
    };
    const inputValues = this.input;
    if (
      this.input.dependency &&
      this.input.dependency.some((e) => e.constraints.validation)
    ) {
      applyValidationConstraints();
    } else {
      if (inputValues.required && !this.isDraft)
        this.schemaObj[inputValues.key] = this.schemaType.setRequired(
          this.schemaObj[inputValues.key],
          inputValues
        );
    }
  }

  typeValidation() {
    const { regex, ...validation } = { regex: null, ...this.input.validation };
    const inputValues = this.input;
    try {
      // eslint-disable-next-line default-case
      switch (inputValues.itype) {
        case 'string':
        case 'view_select':
          this.schemaObj[inputValues.key] = this.schemaType.setString(
            inputValues['name']
          );
          if (regex)
            this.schemaObj[inputValues.key] = this.schemaObj[
              inputValues.key
            ].matches(new RegExp(regex.pattern), regex.message);

          this.schemaObj[inputValues.key] = this.schemaValidation(
            this.schemaObj[inputValues.key],
            validation,
            inputValues
          );
          break;
        case 'boolean':
          this.schemaObj[inputValues.key] = this.schemaType.setBoolean(
            inputValues
          );
          break;
        case 'number':
          this.schemaObj[inputValues.key] = this.schemaType.setInteger(
            inputValues.name
          );
          
          this.schemaObj[inputValues.key] = this.schemaValidation(
            this.schemaObj[inputValues.key],
            validation,
            inputValues
          );
          break;
        case 'date':
          this.schemaObj[inputValues.key] = this.schemaType.setDate(
            inputValues
          );

          this.schemaObj[inputValues.key] = this.schemaValidation(
            this.schemaObj[inputValues.key],
            validation,
            inputValues
          );
          break;
        case 'enum':
          let list = this.input.enums.map((e) => e.data_value);
          this.schemaObj[inputValues.key] = this.schemaType.setEnum(list);
          break;
        case 'array':
          let options = this.input.enmus.map((e) => e.label);
          let enumSchema = this.schemaType.setEnum(options);
          this.schemaObj[inputValues.key] = this.schemaType
            .setArray(enumSchema)
            .min(1);
          break;

        case 'document':
          this.schemaObj[inputValues.key] = this.schemaType.setAny();
          break;
        case 'manyAssociation':
          this.schemaObj[inputValues.key] = this.schemaType.setArray(
            this.schemaType.setAny()
          );
          break;
        case 'association':
          this.schemaObj[inputValues.key] = this.schemaType.setAny();

          break;
        case 'appLevelAssociation':
          this.schemaObj[inputValues.key] = this.schemaType.setAny();

          break;
      }
    } catch (error) {
      throw error;
    }
  }
}

export default InputValidation;
