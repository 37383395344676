import React from 'react';
import './index.css';

export default function Timeline({ item, index }) {
  return (
    <ul
      className={`steps w-100 d-flex p-0 ${
        index % 2 == 1 ? 'flex-row-reverse' : ''
      }`}>
      {item?.map((task) => (
        <li key={task.id} className="timeline-item text-right p-0 flex-grow-1">
          <div className="timeline-item--content text-right ">
            <div>
              <div className="timeline-item--icon" />
              <div className="line mb-2"></div>
              <span className="font-weight-bold timeline-element-margin">
                {new Date(task.updatedAt).toLocaleDateString('ar-eg', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric'
                })}
                <br />
              </span>
              <span className="font-weight-bold timeline-element-margin ">
                {new Date(task.updatedAt).toLocaleTimeString('ar-eg', {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </span>
              <span className="timeline-item--label timeline-element-margin font-weight-bold yellow-color mt-2">
                {task.step.name}
              </span>
              {task.users[0] && (
                <div className="timeline-element-margin">
                  <strong>
                    {`${task.users[0]?.username}`}{' '}
                  </strong>
                </div>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
