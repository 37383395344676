import * as Yup from 'yup';

import { setLocale } from 'yup';
import _ from 'lodash';
import store from '../reducers/store';
setLocale({
  string: {
    min: (obj) => 'قصير جدا',
    max: (obj) => `الحد الاقصى ${obj.max} حرف`
  },
  date: {
    min: (obj) =>
      `يجب ان لا يكون التاريخ قبل ${new Date(obj.min).toLocaleDateString()}`,
    max: (obj) =>
      `يجب ان لا يكون التاريخ بعد ${new Date(obj.max).toLocaleDateString()}`
  },
  number: {
    min: (obj) => `الحد الادنى هو ${obj.min}`,
    max: (obj) => ` الحد الاقصى هو ${obj.max}`
  }
});

export const requiredCheckCB = (checkInput, value, validationObj) => {
  let { value: checkValue, operator } = checkInput[validationObj].condition || {
    value: null,
    operator: null
  };
  return typeof value !== 'undefined' && checkValue && operator
    ? eval(
        ((['string', 'enum'].includes(checkInput.itype) && `'${value}'`) ||
          (checkInput.itype === 'array' &&
            Array.isArray(value) &&
            JSON.stringify(value)) ||
          value) +
          operator +
          checkValue
      )
    : false;
};
let imagesExtensions = ['image/jpeg', 'image/png', 'image/jpg'];

const checkRequired = (input, yupObj) => {
  let validationObj =
    (input.itype === 'document' && 'DocumentValidation') || 'InputValidation';
  if (input.dependency?.length > 0) {
    input.dependency.forEach((checkInput) => {
      yupObj[input.key] = yupObj[input.key].when(checkInput.key, {
        is: (value) => requiredCheckCB(checkInput, value, validationObj),
        then:
          input.itype == 'document'
            ? yupObj[input.key]
                .required('هذا الحقل مطلوب')
                .test('fileSize', 'حجم الملف كبير جدا', (value) => {
                  return value
                    ? Array.isArray(value)
                      ? value[0]
                        ? value[0].size <= 10000000
                        : true
                      : value?.size <= 10000000
                    : true;
                })
                .test('type', 'غير مسموح اﻻ برفع ملفات pdfاو صور', (value) => {
                  return value
                    ? Array.isArray(value)
                      ? value[0]
                        ? imagesExtensions.includes(value[0].type) ||
                          value[0].type === 'application/pdf'
                        : true
                      : imagesExtensions.includes(value?.type) ||
                        value?.type === 'application/pdf'
                    : true;
                })
            : yupObj[input.key].required('هذا الحقل مطلوب'),
        otherwise: yupObj[input.key]
      });
    });
  } else {
    yupObj[input.key] =
      input.is_required || input.required
        ? yupObj[input.key].required('هذا الحقل مطلوب')
        : yupObj[input.key];
  }
};
// Set validation rules of each property "input" according to its validation object
const yupValidation = (input, validation) => {
  Object.keys(validation).forEach((key) => {
    input = validation[key] ? input[key](validation[key]) : input[key]();
  });
  return input;
};
const validateInputs = (inputs, yupObj, isDraft, sectionName) => {
  inputs.forEach((input) => {
    let { regex, ...validation } = { regex: null, ...input.validation };
    yupObj[input.key] = Yup[input.itype.replace(/enum|document/g, 'mixed')]();
    if (!isDraft) checkRequired(input, yupObj);
    switch (input.itype) {
      case 'string':
        if (regex)
          yupObj[input.key] = yupObj[input.key].matches(
            new RegExp(regex.pattern),
            regex.message
          );
        yupObj[input.key] = yupValidation(yupObj[input.key], validation);
        if (input.key.includes('national_id')) {
          yupObj[input.key] = yupObj[input.key].test(
            'unique',
            'غير مسموح بتكرار الرقم القومى',
            function (value) {
              let nationalIds = store.getState()['form']['nationalIds'];
              return value
                ? !nationalIds.find((nationalId) => {
                    return (
                      !nationalId['InputName'].includes(sectionName) &&
                      nationalId['national_id'] == value
                    );
                  })
                : true;
            }
          );
        }

        break;
      case 'integer':
      case 'date':
        yupObj[input.key] = yupValidation(yupObj[input.key], validation);
        break;

      case 'enum':
        let list = input.enums.map((e) => e.data_value);
        yupObj[input.key] = yupObj[input.key].oneOf(list);
        break;
      case 'array':
        yupObj[input.key] = yupObj[input.key].of(
          Yup.mixed().oneOf(input.enums.map((e) => e.data_value))
        );
        yupObj[input.key] =
          input.is_required || input.required
            ? yupObj[input.key].min(1)
            : yupObj[input.key].min(0);
        break;
      case 'document':
        if (input.checkInputs.length <= 0) {
          yupObj[input.key] = yupObj[input.key]
            .nullable()
            .test('fileSize', 'حجم الملف كبير جدا', (value) => {
              return value
                ? Array.isArray(value)
                  ? value[0]
                    ? value[0].size <= 10000000
                    : true
                  : value?.size <= 10000000
                : true;
            })
            .test('type', 'غير مسموح اﻻ برفع ملفات pdfاو صور', (value) => {
              return value
                ? Array.isArray(value)
                  ? value[0]
                    ? imagesExtensions.includes(value[0].type) ||
                      value[0].type === 'application/pdf'
                    : true
                  : imagesExtensions.includes(value?.type) ||
                    value?.type === 'application/pdf'
                : true;
            });
        }
        break;
      default:
        break;
    }
  });
};

const setItype = (docs) => {
  return docs.map((doc) => {
    return { ...doc, itype: 'document' };
  });
};
// Validate inputs in each step
export default (steps, isDraft) => {
  let yupObj = {};

  steps.forEach((step) => {
    if (step.objectName) {
      let internalObj = {},
        outInputs = [],
        inInputs = [],
        inputs =
          step.documents?.length > 0
            ? [...step.inputs, ...setItype(step.documents)]
            : [...step.inputs];
      inputs.forEach((i) => {
        i.location == 'outside' ? outInputs.push(i) : inInputs.push(i);
      });
      validateInputs(outInputs, yupObj, isDraft, step.objectName);
      validateInputs(inInputs, internalObj, isDraft, step.objectName);
      if (step.number) {
        if (isDraft)
          yupObj[step.objectName] = Yup.array().of(
            Yup.object().shape(internalObj)
          );
        else
          yupObj[step.objectName] = Yup.array()
            .required()
            .of(Yup.object().shape(internalObj))
            .min(1);
      } else {
        yupObj[step.objectName] = Yup.object().shape(internalObj);
      }
    } else {
      if (step.inputs.length > 0) {
        validateInputs(
          [...step.inputs, ...setItype(step.documents)],
          yupObj,
          isDraft
        );
      } else {
        validateInputs(
          step.child_sections.flatMap((e) => [
            ...e.inputs,
            ...setItype(e.documents)
          ]),
          yupObj,
          isDraft
        );
      }
    }
  });

  const schema = Yup.object().shape(yupObj);
  return schema;
};
