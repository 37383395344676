import client from '../../api/apiAuth/guestClient';
const style = {
  width: "100%",
  // height:"80vh"
}
const previewImgAttachment = async (attachment) => {
  let img = new Image();
  let url = "";
  //incase if the file is saved in file accordion in the details page  
  if (attachment.data?.extension) {
    let extension = attachment.data.extension;
    const imageData = await client.get(attachment.data.url, { responseType: "blob", withCredentials: true })
    url = URL.createObjectURL(new Blob([imageData.data], { type: `image/${extension}` }));
    // incase if used in the edit submission without using more data
  } else if (attachment.pdfUrl) {
    let extension = attachment.name.split(".")
    extension = extension[extension.length - 1];
    const imageData = await client.get(attachment.pdfUrl, { responseType: "blob", withCredentials: true })
    url = URL.createObjectURL(new Blob([imageData.data], { type: `image/${extension}` }));
  }
  else url = URL.createObjectURL(attachment);
  //display the image loaded in a new window 
  img.onload = () => {
    window.open(url, '_blank', `height=${img.height},width=${img.width},left=100,top=50`)
  }
  img.src = url

}
function ImageViewer({ file, open, setOpen, local, serverImgSrc }) {
  const toggle = () => setOpen(!open);
  if (file && open) {
    previewImgAttachment(file);
    toggle()
  }
  return null;
}

export default ImageViewer;
