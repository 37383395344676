import Schema from './Schema';
import * as yup from 'yup';
import _, { isEmpty } from 'lodash';
import {
  getConditionsWithValues,
  TryFillDataFromContext
} from './helperFunctions';
import { applyInputConstraints } from 'utils/LayoutConstraints/ConstraintsService';

class YupSchema extends Schema {
  setInteger(inputName) {
    return yup.number().typeError(`${inputName} should be number`);
  }
  setString(inputName) {
    return yup.string().typeError(`${inputName} should be string.`);
  }
  setBoolean(inputName) {
    return yup.boolean().typeError(`${inputName} should be true or false`);
  }
  setDate(input) {
    return yup.date().typeError(`${input.name} invalid Date`);
  }
  setObject(objectSchema, input) {
    return yup
      .object()
      .default({})
      .shape(objectSchema)
      .typeError(`${input ? input.name : ''} invalid Document`);
  }
  setEnum(enums) {
    return yup.string().oneOf(enums);
  }
  setArray(itemSchema) {
    return yup.array().of(itemSchema);
  }
  setRequired(element, inputName) {
    return element.test(
      'Required',
      `${inputName && inputName.name} is Required`,
      (value) => {
        return typeof value == 'object'
          ? value instanceof Date
            ? value !== undefined
            : !isEmpty(value) || (value && Object.keys(value).length == 0)
          : value !== undefined;
      }
    );
  }
  setMinValue(element, input, item, checkInput, checkInputName) {
    return element.min(
      yup.ref(checkInput),
      `${input.name}  must be after ${checkInputName}`
    );
  }
  extendObject(extendedPart, oldPart) {
    return extendedPart.concat(oldPart);
  }
  //ToFix
  getArrayItemSchema(arraySchema) {
    return arraySchema.innerType;
  }
  setConditionalSchema(element, checkInputs, item, input, finishedCallBack) {
    return element.when(checkInputs, (...args) => {
      const schema = args[args.length - 2];
      const conditions = getConditionsWithValues(
        item.conditions,
        args,
        checkInputs
      );
      TryFillDataFromContext(conditions, args[args.length - 1].context);
      const result = applyInputConstraints(
        {
          ...item,
          conditions
        },
        'validation'
      );
      if (item.constraints.validation.hasOwnProperty('dependent')) {
        let checkInput = item.constraints.validation['dependent']['key'];
        let checkInputName = item.constraints.validation['dependent']['name'];
        let constraint = item.constraints.validation['dependent']['constraint'];
        let minInputSchema = this[constraint](
          element,
          input,
          item,
          checkInput,
          checkInputName
        );
        return finishedCallBack(minInputSchema);
      }
      if (conditions && result) {
        return finishedCallBack(schema);
      }
    });
  }
  setAny() {
    return yup.mixed();
  }
  getAttachmentSchema() {
    return {
      id: yup.string(),
      avatarFd: yup.string(),
      size: yup.string(),
      pages: yup.number(),
      is_reviewed: yup.boolean(),
      original_file_name: yup.string(),
      submission_id: yup.string(),
      input_id: yup.number(),
      URL: yup.string()
    };
  }
  checkRequired(field) {
    return field.tests?.[0]?.OPTIONS?.name === 'Required';
  }
}
export default YupSchema;
