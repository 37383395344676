import {
  FETCH_WORKLST,
  FETCH_WORKLST_LAYOUT,
  GET_SUBMISSIONS_IDS,
  FETCH_FORMS,
  FETCH_ReqStage,
  SET_LAST_TAB
} from './listActionTypes';
import client from '../../api/apiAuth/guestClient';

export const fetchWorklist = function (params) {
  return {
    type: FETCH_WORKLST,
    payload: client.get('tasks/find', { params })
  };
};
export const setLastTab = function (params) {
  return {
    type: SET_LAST_TAB,
    payload: params
  };
};

export const fetchWorklistLayout = function (params) {
  return {
    type: FETCH_WORKLST_LAYOUT,
    payload: client.get('app/getTableData', { params })
  };
};

export const fetchForms = function (params) {
  return {
    type: FETCH_FORMS,
    payload: client.get('forms/findForms', { params })
  };
};

export const fetchReqStage = function (params) {
  return {
    type: FETCH_ReqStage,
    payload: client.get('steps/findSteps', { params })
  };
};

export const AddFilter = function (params) {
  return {
    type: FETCH_WORKLST_LAYOUT,
    payload: client.get('app/getTableData', { params })
  };
};
export const getSubmissionIDs = function (params) {
  return {
    type: GET_SUBMISSIONS_IDS,
    payload: client.get('workflow/getCompleteReqTasks', {
      params
    })
  };
};
