import React from 'react';
import { Button, Typography, Modal } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { NavLink } from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
const InquiryModal = ({
  open,
  setOpen,
  submission,
  errorMessage,
  setErrorMessage,
  setSubmission,
  setNationalId
}) => {
  const closeModal = () => {
    setOpen(false);
    setSubmission({});
    setErrorMessage('');
    setNationalId('');
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={closeModal}>
      <div className=" modal-style">
        {!errorMessage && submission ? (
          <>
            <Typography variant="h2" id="modal-title">
              <CheckCircleOutlineIcon
                style={{ color: 'green', width: '60px', height: '60px' }}
              />
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              <div>
                <h3>هذا الرقم تم تسجيله من قبل</h3>
                <p>{`رقم الطلب هو ${submission.id}`} </p>
              </div>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h2" id="modal-title" className="mb-2">
              <CancelIcon
                style={{ color: 'red', width: '60px', height: '60px' }}
              />
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              <div>
                <h3>{'ﻻ توجد نتائج'}</h3>
              </div>
            </Typography>
          </>
        )}
        <Typography style={{ marginTop: '50px' }}>
          <Button
            className="btn-transition-none inquiry-btn modal-btn"
            variant="contained"
            onClick={() => {
              closeModal(false);
            }}
            color="primary"
            style={{ marginLeft: '30px' }}>
            ادخال رقم اخر
          </Button>
          {!errorMessage && submission ? (
            <NavLink to={`/details/${submission.id}`}>
              <Button
                className="btn-transition-none inquiry-btn modal-btn"
                variant="contained"
                color="primary">
                اذهب للطلب
              </Button>
            </NavLink>
          ) : (
            ''
          )}
        </Typography>
      </div>
    </Modal>
  );
};

export default InquiryModal;
