import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import clsx from 'clsx';

import { connect } from 'react-redux';
import client from '../../api/apiAuth/guestClient';

const Footer = (props) => {
  const [version, setVersion] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { footerShadow, footerBgTransparent } = props;

  useEffect(() => {
    const fetchVersion = async () => {
      const response = await client.get('app/version');
      setVersion(response.data.version);

      let appVersion = localStorage.getItem('version');
      if (!appVersion || appVersion !== response.data.version) {
        console.log('UPDATING VERSION');
        setShowSnackbar(true);
        localStorage.setItem('version', response.data.version);
        if (
          'serviceWorker' in navigator &&
          navigator.serviceWorker.controller
        ) {
          navigator.serviceWorker.controller.postMessage({
            type: 'UPDATE_VERSION'
          });
        }
      }
    };

    // fetchVersion();
  });

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <>
      <Snackbar open={showSnackbar} onClose={closeSnackbar}>
        <MuiAlert
          severity="error"
          onClose={closeSnackbar}
          elevation={6}
          variant="filled"
          style={{ color: 'white' }}>
          تم تحديث التطبيق. من فضلك إغلاق المتصفح وإعادة الفتح
        </MuiAlert>
      </Snackbar>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent
        })}>
        <div className="app-footer--first"></div>
        <div className="app-footer--second" style={{ margin: '0 auto' }}>
          <span style={{ display: 'inline' }}>Everest Minds © </span>
          <a
            href="https://www.everestminds.com/"
            target="_blank"
            title="everest minds"
            rel="noopener noreferrer">
            everestminds.com
          </a>
          <span style={{ display: 'inline' }}> {version} </span>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  footerShadow: state.theme.footerShadow,
  footerBgTransparent: state.theme.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);
