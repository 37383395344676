export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_PENDING = 'UPLOAD_FILE_PENDING';
export const UPLOAD_FILE_FULFILLED = 'UPLOAD_FILE_FULFILLED';
export const UPLOAD_FILE_REJECTED = 'UPLOAD_FILE_REJECTED';

export const UPLOAD_Stamp_FILE = 'UPLOAD_Stamp_FILE';
export const UPLOAD_Stamp_FILE_PENDING = 'UPLOAD_Stamp_FILE_PENDING';
export const UPLOAD_Stamp_FILE_FULFILLED = 'UPLOAD_Stamp_FILE_FULFILLED';
export const UPLOAD_Stamp_FILE_REJECTED = 'UPLOAD_Stamp_FILE_REJECTED';

export const SAVE_VALUES = 'SAVE_VALUES';

export const START_ENGINE = 'START_ENGINE'; //create submission and start its worklow
export const START_ENGINE_PENDING = 'START_ENGINE_PENDING';
export const START_ENGINE_FULFILLED = 'START_ENGINE_FULFILLED';
export const START_ENGINE_REJECTED = 'START_ENGINE_REJECTED';

export const ENABLE_FORM_SUBMIT_BUTTON = 'ENABLE_FORM_SUBMIT_BUTTON';
export const SAVE_FILES = 'SAVE_FILES';

export const GET_FORM_LAYOUT = 'GET_FORM_LAYOUT';
export const GET_FORM_LAYOUT_PENDING = 'GET_FORM_LAYOUT_PENDING';
export const GET_FORM_LAYOUT_FULFILLED = 'GET_FORM_LAYOUT_FULFILLED';
export const GET_FORM_LAYOUT_REJECTED = 'GET_FORM_LAYOUT_REJECTED';

export const SET_FORM_NAME = 'SET_FORM_NAME';
export const CLEAR_SUBMISSION = 'CLEAR_SUBMISSION';
export const SET_REQUIRED_DOCUMENTS = 'SET_REQUIRED_DOCUMENTS';

export const SAVE_DRAFT = 'SAVE_DRAFT';
export const SAVE_DRAFT_PENDING = 'SAVE_DRAFT_PENDING';
export const SAVE_DRAFT_FULFILLED = 'SAVE_DRAFT_FULFILLED';
export const SAVE_DRAFT_REJECTED = 'SAVE_DRAFT_REJECTED';
export const SET_SUBMISSION = 'SET_SUBMISSION';

export const GET_DRAFT = 'GET_DRAFT';
export const GET_DRAFT_PENDING = 'GET_DRAFT_PENDING';
export const GET_DRAFT_FULFILLED = 'GET_DRAFT_FULFILLED';
export const GET_DRAFT_REJECTED = 'GET_DRAFT_REJECTED';

export const UPDATE_SUBMISSION = 'UPDATE_SUBMISSION';
export const UPDATE_SUBMISSION_PENDING = 'UPDATE_SUBMISSION_PENDING';
export const UPDATE_SUBMISSION_FULFILLED = 'UPDATE_SUBMISSION_FULFILLED';
export const UPDATE_SUBMISSION_REJECTED = 'UPDATE_SUBMISSION_REJECTED';

export const HANDLE_DRAFT_ASSIGNMENT = 'HANDLE_DRAFT_ASSIGNMENT';
export const HANDLE_DRAFT_ASSIGNMENT_PENDING =
  'HANDLE_DRAFT_ASSIGNMENT_PENDING';
export const HANDLE_DRAFT_ASSIGNMENT_FULFILLED =
  'HANDLE_DRAFT_ASSIGNMENT_FULFILLED';
export const HANDLE_DRAFT_ASSIGNMENT_REJECTED =
  'HANDLE_DRAFT_ASSIGNMENT_REJECTED';

export const SET_NATIONALIDS = 'SET_NATIONALIDS';

export const SET_BENEFICIARY_ID = 'SET_BENEFICIARY_ID';

