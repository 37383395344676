import React, { useEffect, useState } from 'react';

import {
  Grid,
  Card,
  Button,
  Avatar,
  Input,
  FormControl,
  makeStyles
} from '@material-ui/core';
import './style.css';
import zakat from '../../assets/images/zakat.png';
import { connect } from 'react-redux';
// import { setUser } from 'reducers/user';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import { login, setIsLoggedIn } from '../../reducers/auth';
import BlockUi from 'react-block-ui';
import { SyncLoader } from 'react-spinners';
import { useLocation } from 'react-router';
import browserUpdate from 'browser-update';
const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: 'white',
    borderRadius: '5px',
    width: '100%',
    height: '10%',
    textIndent: '10px'
  },
  whiteText: { color: 'white' },
  sizeAvatar: {
    width: '120px',
    borderRadius: 0,
    height: 'auto',
    margin: 'auto'
  },
  txtFont: {
    fontFamily: 'ArabicKufiSSk'
  }
}));

function Login({ history, redirectUrl, login, loading }) {
  let [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const afterLoginRedirect = location.state?.from;
  useEffect(() => {
    if (redirectUrl) history.push(redirectUrl);
  }, [redirectUrl]);

    const clearCacheData = () => {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(' البريد الإلكتروني غير صالح')
        .required('هذا الحقل مطلوب'),
      password: Yup.string()
        .min(1, 'يجب أن تكون كلمة المرور أكثر من 1 أحرف')
        .required('هذا الحقل مطلوب')
    }),
    onSubmit(values) {
      login(values)
        .then((response) => {
          if (afterLoginRedirect) history.push(afterLoginRedirect);
        if(navigator.userAgent.indexOf("Chrome") != -1 )  //if current browser is Chrome, check version 
        {
            browserUpdate({
              required: { c:"109.0.0.0" },
              reminder: 5,
              reminderClosed: 5,
              l: false,
              insecure:true,
              newwindow: true,
              url: null,
              noclose:false,
              no_permanent_hide: true,
              api:2023.04,
              url: `https://www.google.com/chrome/`,
              text: {
                'msg':'Your web browser ({brow_name}) is out of date.',
                'msgmore': 'Update your browser for more security, speed and the best experience on the portal.', 
                'bupdate': 'Update browser', 
                'remind': 'You will be reminded in {days} days.',
             }
          })
        } else //if current browser not Chrome, switch to Chrome
        {
            browserUpdate({
              reminder: 5,
              reminderClosed: 5,
              l: false,
              insecure:true,
              newwindow: true,
              url: null,
              noclose:false,
              no_permanent_hide: true,
              api:2023.04,
              test: true,
              url: `https://www.google.com/chrome/`,
              text: {
                'msg':'Your browser ({brow_name}) is not supported',
                'msgmore': 'Use Chrome for more security, speed and the best experience on the portal.', 
                'bupdate': 'Update browser', 
                'remind': 'You will be reminded in {days} days.',
            }
          })
        }
          clearCacheData();
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(error.response && error.response.data.message);
        });
    }
  });
  const classes = useStyles();
  return (
    <div className="bg-img mt-0 container">
      <Grid
        container
        direction="row"
        justify="center"
        align="center"
        className="grid-height">
        <Grid
          container
          item
          xs={12}
          spacing={0}
          direction="row"
          alignItems="center"
          justify="center">
          <Grid
            item
            xs={11}
            lg={4}
            container
            alignItems="center"
            justify="center">
            <BlockUi
              className="block-loading-overlay-light"
              tag="div"
              blocking={loading}
              loader={<SyncLoader color="#4f55a28f" loading={loading} />}>
              <Card className="card-width">
                <Grid
                  container
                  item
                  spacing={0}
                  alignItems="center"
                  justify="center"
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="form-bg">
                  <Grid item className="rounded" lg={12}>
                    <Avatar
                      className={`${classes.sizeAvatar} mt-2`}
                      src={zakat}
                    />
                  </Grid>
                  <Grid item lg={12} container className="mt-4">
                    <Grid className="text-center" item lg={12} md={12} xs={12}>

                      <h3
                        className={` ${classes.whiteText} ${classes.txtFont} mt-4`}
                        align="center">
                        <b>تسجيل الدخول</b>
                      </h3>
                      <br></br>
                    </Grid>
                    <form onSubmit={formik.handleSubmit} className="form">
                      <p className="text-danger ">
                        {errorMessage ? errorMessage : ''}
                      </p>
                      <Grid item lg={9} md={9} xs={9} className="mx-auto">
                        <div
                          align="right"
                          className={`${classes.whiteText} ${classes.txtFont} p-1 heading-4 `}>
                          البريد الإلكترونى
                        </div>
                        <FormControl fullWidth className="mt-2 mb-3">
                          <Input
                            name="email"
                            id="email"
                            // disableUnderline
                            type="email"
                            autoFocus={true}
                            error={
                              formik.touched.email && formik.errors.email
                                ? true
                                : false
                            }
                            // className="email formField"
                            inputProps={{
                              className: classes.input
                            }}
                            {...formik.getFieldProps('email')}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger mt-1" align="right">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </FormControl>
                        <div
                          align="right"
                          className={`${classes.whiteText} ${classes.txtFont} p-1 heading-4 `}>
                          كلمة السر
                        </div>

                        <FormControl fullWidth className="mt-2">
                          <Input
                            name="password"
                            id="password"
                            type="password"
                            autoFocus={true}
                            // className="password formField"
                            error={
                              formik.touched.password && formik.errors.password
                                ? true
                                : false
                            }
                            inputProps={{
                              className: classes.input
                            }}
                            {...formik.getFieldProps('password')}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger mt-1" align="right">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid
                        className="text-center py-4"
                        item
                        lg={12}
                        md={12}
                        xs={9}>
                        <Button
                          className={`loginBtn font-weight-bold w-50 my-2 ${classes.txtFont}`}
                          type="submit">
                          {' دخول الان '}
                        </Button>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </Card>
            </BlockUi>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    redirectUrl: state.auth.redirectUrl,
    loading: state.auth.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    login: (data) => dispatch(login(data)),
    // setUser: (data) => dispatch(setUser(data)),
    setIsLoggedIn: (data) => dispatch(setIsLoggedIn(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
