const tabs = {
  worklist: {
    shared: [],
    admin: [
      {
        id: 2,
        name: ' الطلبات '
      },
      {
        id: 3,
        name: 'الطلبات عند الامين العام '
      }
    ],
    requester: [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 4,
        name: 'الطلبات الغير مكتملة'
      }
    ],
    researcher: [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    'administration office employee': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 20,
        name: 'الطلبات المحفوظة'
      },
      {
        id: 31,
        name: 'طلبات الموقع'
      }
    ],
    'administration office manager': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 20,
        name: 'الطلبات المحفوظة'
      },
      {
        id: 28,
        name: 'الطلبات المتوقفة للمراجعة'
      }
    ],
    'research manager': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 20,
        name: 'الطلبات المحفوظة'
      },
      {
        id: 22,
        name: 'الطلبات المتوقفة عند الباحثين'
      },
      {
        id: 21,
        name: 'الطلبات المتوقفة عند مشرف البحث'
      }
    ],
    'research supervisor': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 22,
        name: 'الطلبات المتوقفة عند الباحثين'
      }
    ],
    'general secertary assistant medical': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    'research general manager': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 20,
        name: 'الطلبات المحفوظة'
      },
      {
        id: 22,
        name: 'الطلبات المتوقفة عند المراجعين'
      }
    ],
    'super admin': [
      {
        id: 2,
        name: ' الطلبات'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 7,
        name: 'الطلبات المعطلة للمراجعة '
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    'general secertary assistant': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    committee: [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 20,
        name: 'الطلبات المحفوظة'
      }
    ],
    'medical division manager': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 26,
        name: 'الطلبات المتوقفة عند مستخدمي القسم الطبي'
      }
    ],
    'medical division user': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    'legal manager': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 24,
        name: 'الطلبات المتوقفة عند الموظفين'
      },
      {
        id: 27,
        name: 'غارمون و غارمات'
      }
    ],
    employee: [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    'financial manager': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 25,
        name: 'الطلبات المتوقفة عند المراجعين '
      }
    ],
    checker: [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    'financial general secretary': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    'general secertary assistant social': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    'social division manager': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 23,
        name: 'الطلبات المتوقفة عند مستخدمي القسم الاجتماعي'
      }
    ],
    'social division user': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      }
    ],
    'general secertary': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 20,
        name: 'الطلبات المحفوظة'
      }
    ],
    'excutive director': [
      {
        id: 0,
        name: 'قائمة أعمالك'
      },
      {
        id: 1,
        name: 'الغير مكلفة'
      },
      {
        id: 2,
        name: ' الطلبات السابقه'
      },
      {
        id: 3,
        name: 'الطلبات المنتهية'
      },
      {
        id: 20,
        name: 'الطلبات المحفوظة'
      }
    ],
    'web entry': [
      {
        id: 4,
        name: 'طلبات الموقع'
      }
    ],
    'architect': [
      {
        id: 32,
        name: 'اسكان و وصلات مياه'
      }
    ],
    'assistant secretary general for medical supply': [
      {
        id: 33,
        name: 'قائمة أعمالك'
      },
      {
        id: 34,
        name: ' الطلبات السابقه'
      }
    ]
  },
  archivedSubmissions: {
    shared: [
      {
        id: 30,
        name: 'الطلبات المؤرشفة'
      }
    ],
    'super admin': []
  }
};
export default tabs;
